import {inject, Injectable} from "@angular/core";
import {Action, Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {Router} from "@angular/router";
import {DonationsService} from "../donations.service";
import {FileService} from "../../../shared/services/file.service";
import {
  createDonation,
  createDonationError,
  createDonationSuccess, inviteTrustee, inviteTrusteeError, inviteTrusteeSuccess
} from "./donations.actions";
import {catchError, map, Observable, of, switchMap, tap} from "rxjs";
import {getMyProfile, updateMyProfileError, updateMyProfileSuccess} from "../../profile/store/profile.actions";

@Injectable()
export class DonationsEffects {
  readonly layoutStore = inject(LayoutStore);

  constructor(private actions$: Actions,
              private store: Store,
              private router: Router,
              private donationsService: DonationsService,
              private fileService: FileService) {
  }

  public createDonation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createDonation),
      switchMap(({ request, otherInfoImageFile, paymentImageFile }) => {
        // Check if the paymentImageFile is provided
        if (!paymentImageFile) {
          return of(createDonationError({ error: 'Payment image is required' }));
        }

        const uploadOtherInfoImage$ = otherInfoImageFile
          ? this.fileService.uploadFile(otherInfoImageFile).pipe(
            catchError(error => {
              console.error('Error uploading other info file:', error);
              return of(null);
            })
          )
          : of(null);

        return uploadOtherInfoImage$.pipe(
          switchMap(otherInfoImage => {
            const uploadPaymentImage$ = this.fileService.uploadFile(paymentImageFile).pipe(
              catchError(error => {
                console.error('Error uploading payment file:', error);
                return of(null);
              })
            );

            return uploadPaymentImage$.pipe(
              switchMap(paymentImage => {
                if (!paymentImage) {
                  return of(createDonationError({ error: 'Error uploading payment file' }));
                }

                const requestDuplicate = {
                  ...request,
                  otherInfoImageId: otherInfoImage?.id || null,
                  paymentImageId: paymentImage?.id || null
                };

                return this.donationsService.createDonation(requestDuplicate).pipe(
                  map(response => createDonationSuccess({ response })),
                  tap(() => {
                    this.store.dispatch(getMyProfile());
                  }),
                  tap(() => {
                    this.router.navigate(['/']);
                  }),
                  catchError(error => {
                    console.log(error);
                    return of(createDonationError({ error }))
                  })
                );
              })
            );
          })
        );
      })
    )
  );

  public inviteTrustee = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteTrustee),
      switchMap(({request}) =>
        this.donationsService.inviteTrustee(request).pipe(
          map(response => inviteTrusteeSuccess({response})),
          tap(( response ) => {
            this.store.dispatch(getMyProfile())
          }),
          tap(() => {
            this.router.navigate(['/']);
          }),
          catchError(error => {
            const errorMessage = error.error || 'Възникна проблем';
            this.store.dispatch(updateMyProfileError({ error: errorMessage }));
            return of(inviteTrusteeError({ error: errorMessage }));
          })
        )
      )
    )
  );


}
