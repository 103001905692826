import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileResponse} from "../models/file.response";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class FileService {
  constructor(private http: HttpClient) {
  }

  uploadFile(file: File, id: number = -1): Observable<FileResponse> {
    const formData = new FormData();
    formData.append('request', file);

    if (id > -1) {
      formData.append('typeId', id + '');
    }

    return this.http.post<FileResponse>('api/files', formData);
  }

  uploadLocationFile(file: File, token: string): Observable<FileResponse> {
    const formData = new FormData();
    formData.append('request', file);

    let params = new HttpParams();
    params = params.set('token', token);

    return this.http.post<FileResponse>('api/locations/images', formData, {params});
  }

  saveAs(blobResponse: HttpResponse<Blob>) {
    const header = blobResponse.headers.get('Content-Disposition');
    if (!header) {
      return;
    }

    const parts = header.split(';');
    const filename = parts[1].split('=')[1].replaceAll("\"", "");

    const blob = blobResponse.body!;

    if (blob != null) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
}
