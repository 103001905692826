import {inject, Injectable} from "@angular/core";
import {catchError, map, mergeMap, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {Router} from "@angular/router";
import {ProfileService} from "../profile.service";
import {
  finishInvitedProfile,
  finishInvitedProfileError,
  finishInvitedProfileSuccess,
  getInvitedProfile,
  getInvitedProfileError,
  getInvitedProfileSuccess,
  getMyProfile,
  getMyProfileSuccess,
  getTrusteeTransfer,
  getTrusteeTransferError,
  getTrusteeTransferSuccess,
  updateMyProfile,
  updateMyProfileError,
  updateMyProfileSuccess
} from "./profile.actions";
import {StatisticsResponse} from "../../../shared/models/statistics.response";
import {logout} from "../../../public/authentication/store/authentication.actions";

@Injectable()
export class ProfileEffects {
  readonly layoutStore = inject(LayoutStore);

  constructor(private actions$: Actions,
              private store: Store,
              private router: Router,
              private profileService: ProfileService) {
  }

  public geyMyProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(getMyProfile),
      switchMap(() =>
        this.profileService.getMyProfile().pipe(
          map(response => getMyProfileSuccess({response})),
          tap((response) => {
            const fullStatistics = Object.assign(new StatisticsResponse(), response.response.statistics);
            fullStatistics.name = response.response.firstName;
            fullStatistics.rating = response.response.rating || 0;
            this.layoutStore.setProfileMeta(fullStatistics);
          }),
          catchError(() => {
            this.store.dispatch(logout())
            return of();
          })
        )
      )
    )
  );

  public getInvitedProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvitedProfile),
      switchMap(({token}) =>
        this.profileService.getInvitedUser(token).pipe(
          map(response => getInvitedProfileSuccess({response})),
          tap((response) => {
            const fullStatistics = Object.assign(new StatisticsResponse(), response.response.statistics);
            fullStatistics.name = response.response.firstName;
            fullStatistics.rating = response.response.rating || 0;
            this.layoutStore.setProfileMeta(fullStatistics);
          }),
          catchError((error) => {
            this.store.dispatch(getInvitedProfileError({error}))
            this.store.dispatch(logout())
            return of();
          })
        )
      )
    )
  );

  public updateMyProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMyProfile),
      switchMap(({request}) =>
        this.profileService.updateMyProfile(request).pipe(
          map(response => updateMyProfileSuccess({response})),
          tap((response) => {
            this.store.dispatch(getMyProfile());
            this.router.navigate(['/']);
          }),
          catchError(error => {
            this.store.dispatch(updateMyProfileError(error))
            return of();
          })
        )
      )
    )
  );

  public finishInvitedProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(finishInvitedProfile),
      switchMap(({request, token}) =>
        this.profileService.finishUser(token, request).pipe(
          map(response => finishInvitedProfileSuccess({response})),
          tap((response) => {
            this.store.dispatch(logout())
          }),
          catchError(error => {
            this.store.dispatch(finishInvitedProfileError(error));
            return of();
          })
        )
      )
    )
  );

  public loadTransferStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTrusteeTransfer),
      mergeMap(() =>
        this.profileService.getTrusteeTransfer().pipe(
          map(response => getTrusteeTransferSuccess({response})),
          catchError(error => of(getTrusteeTransferError({error})))
        )
      )
    )
  );

}
