import {Component} from '@angular/core';
import {AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Store} from "@ngrx/store";
import {selectMunicipalities, selectSettlements} from "../../../../../shared/store/location/location.reducer";
import {distinctUntilChanged, filter, map, Observable} from "rxjs";
import {
  getMunicipalities,
  getSettlements,
  getSettlementsSuccess
} from "../../../../../shared/store/location/location.actions";
import {LocationState} from "../../../../../shared/store/location/location.state";
import {AsyncPipe} from "@angular/common";
import {PageableResponse} from "../../../../../shared/models/pageable/pageable.response";
import {MunicipalityResponse} from "../../../../../shared/models/municipality.response";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {PasswordModule} from "primeng/password";
import {SettlementResponse} from "../../../../../shared/models/settlement.response";
import {DistrictResponse} from "../../../../../shared/models/district.response";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {ProfileState} from "../../../store/profile.state";
import {selectSelectedRoles} from "../../../store/profile.reducer";
import {Role} from "../../../profile.response";
import {availableRoles} from "../../../profile.config";
import {CheckboxModule} from "primeng/checkbox";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {LocationResponse} from "../../../../../shared/models/location/location.response";
import {
  SettlementSelectionComponent
} from "../../../../../shared/components/settlement-selection/settlement-selection.component";
import {RoleRequest} from "../../../profile.request";
import {PhotoChoiceComponent} from "../../../../../shared/components/photo-choice/photo-choice.component";
import {FileService} from "../../../../../shared/services/file.service";
import {TooltipModule} from "primeng/tooltip";
import {LocationService} from "../../../../../shared/services/location.service";
import {environment} from "../../../../../../environments/environment";
import {MessagesModule} from "primeng/messages";
import {Message} from "primeng/api";
import {FileUrlPipe} from "../../../../../shared/pipes/file-url.pipe";

@Component({
  selector: 'bop-address-form',
  standalone: true,
  imports: [
    AutoCompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    InputGroupAddonModule,
    InputGroupModule,
    PasswordModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    SettlementSelectionComponent,
    PhotoChoiceComponent,
    TooltipModule,
    MessagesModule,
    FileUrlPipe
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent {
  public initialMunicipalityName = '';
  public initialSettlementName = '';
  public file: File | null = null;
  public oldImageSrc: string = '';
  public link = '';
  public token = '';
  public copied = false;

  public addressForm = this.formBuilder.group({
      id: new FormControl(0),
      settlement: new FormControl(new SettlementResponse()),
      address: new FormControl(''),
      comment: new FormControl(''),
      coordinates: new FormControl(''),
      locationRoles: new FormControl(),
      locationImageId: new FormControl<number | null>(null)
    }
  );

  public messages: Message[] = [{severity: 'success', summary: 'Линкът е успешно копиран!'}];

  public availableRoles$: Observable<Role[]> = this.profileStore.select(selectSelectedRoles);
  public selectedRoleIds: number[] = [];

  public selectedSettlement: SettlementResponse | undefined;

  constructor(private profileStore: Store<ProfileState>,
              private fileService: FileService,
              private locationService: LocationService,
              private dialogRef: DynamicDialogRef<AddressFormComponent>,
              private dialogConfig: DynamicDialogConfig,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (!this.dialogConfig.data) {
      return;
    }

    this.getLocationToken(this.dialogConfig.data.id)
    this.populateFormValues(this.dialogConfig.data);
  }

  getLocationToken(id: number) {
    this.locationService.getLocationToken(id)
      .subscribe(r => this.link = environment.platformUrl + '/guest/addresses/images/' + r.token);
  }

  onSettlementSelect($event: SettlementResponse) {
    this.selectedSettlement = $event;
  }

  saveAddress() {
    this.addressForm.get('settlement')?.setValue(this.selectedSettlement || this.dialogConfig.data.settlement);
    this.addressForm.get('locationRoles')?.setValue(this.selectedRoleIds.map(r => new RoleRequest(r, '')))

    if (!this.file) {
      this.dialogRef.close(this.addressForm.value);
      return;
    }

    this.fileService.uploadFile(this.file)
      .subscribe(fileResponse => {
        this.addressForm.get('locationImageId')?.setValue(fileResponse.id);
        this.dialogRef.close(this.addressForm.value);
      })
  }

  populateFormValues(address: LocationResponse) {
    this.initialMunicipalityName = address.settlement.name;
    this.initialSettlementName = address.settlement.municipality.name;
    this.selectedRoleIds = address.locationRoles.map(r => r.roleId);
    this.oldImageSrc = address.locationImage?.url || '';

    this.addressForm.patchValue({
      id: address.id,
      settlement: address.settlement,
      comment: address.comment,
      address: address.address,
      coordinates: address.coordinates,
    })
  }

  setFile($event: File) {
    this.file = $event;
  }

  copyLink() {
    navigator.clipboard.writeText(this.link);
    this.copied = true;
  }
}
