import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CheckboxChangeEvent, CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {availableRoles} from "../../profile.config";
import {Role, RoleResponse} from "../../profile.response";
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RoleRequest} from "../../profile.request";
import {InputOtpChangeEvent} from "primeng/inputotp";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../store/profile.state";
import {removeSelectedRole, setSelectedRole} from "../../store/profile.actions";

@Component({
  selector: 'bop-profile-edit-roles',
  standalone: true,
  imports: [
    CheckboxModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './profile-edit-roles.component.html',
  styleUrl: './profile-edit-roles.component.scss'
})
export class ProfileEditRolesComponent {
  @Input() public dataForm!: FormGroup;
  @Input() public initiallySelectedRoles: any;
  protected readonly availableRoles = availableRoles;

  constructor(private store: Store<ProfileState>) {
  }


  onRoleChanged($event: CheckboxChangeEvent, role: Role) {
    const roleIndex = this.getRoleIndex(role);

    if (roleIndex > -1) {
      this.removeRoleSelection(role, roleIndex);
      return;
    }
    this.addRoleSelection(role);
  }

  getRoleIndex(checkedRole: Role) {
    return this.getRolesFormArray().controls.findIndex(c => c.value.roleId == checkedRole.id);
  }

  isRoleSelected(role: Role) {
    return this.getRoleIndex(role) > -1;
  }

  addRoleSelection(role: Role) {
    this.getRolesFormArray().push(new FormControl({
      roleId: role.id,
      reason: ''
    }));

    this.store.dispatch(setSelectedRole({role: new Role(role.id, role.name)}))

    this.initiallySelectedRoles[role.id] = true;
  }

  removeRoleSelection(role: Role, index: number) {
    this.getRolesFormArray().removeAt(index);

    this.store.dispatch(removeSelectedRole({role}))
  }

  getRolesFormArray(): FormArray {
    return this.dataForm.get('roles') as FormArray;
  }

  onRoleReasonAdded($event: any, role: Role) {
    this.getRolesFormArray().at(this.getRoleIndex(role)).value.reason = $event.target.value;
  }

  getInitialRoleValue(role: Role) {
    return this.getRolesFormArray().at(this.getRoleIndex(role)).value.reason;
  }
}
