import {Injectable} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {PageableRequest} from "../pageable/pageable.request";
import {FiltrationRequestParam, InvokableFiltration} from "./filtration";

const MAX_SIZE = 2147483647;

@Injectable({
  providedIn: 'root',
})
export class QueryFormattingUtility {
  formatQuery(
    pageableRequest: PageableRequest | null | undefined = null,
    filter: InvokableFiltration | null | undefined = null
  ): HttpParams {
    let params = new HttpParams();

    if (!pageableRequest) {
      params = params
        .set('page', '0')
        .set('size', `${MAX_SIZE}`);
    }

    if (filter) {
      const param: FiltrationRequestParam = filter.do();
      params = params.set(param.key, param.value);
    }

    if (pageableRequest?.currentPage || pageableRequest?.pageSize) {
      params = params
        .set('page', `${pageableRequest.currentPage || 0}`)
        .set('size', `${pageableRequest.pageSize || MAX_SIZE}`);
    }


    if (pageableRequest?.sort) {
      const sortValue = `${pageableRequest.sort.column},${pageableRequest.sort.isAscending ? 'asc' : 'desc'}`;
      params = params.set('sort', sortValue);
    }

    return params;
  }
}
