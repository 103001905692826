import { Component } from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {DataGridTrait} from "../../../shared/services/grid.service";
import {DonationResponse} from "../donation.response";
import {DonationsService} from "../donations.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
    DonationStatusLabelComponent
} from "../../../shared/components/donation-status-label/donation-status-label.component";
import {
  PaymentMethodLabelComponent
} from "../../../shared/components/payment-method-label/payment-method-label.component";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";
import {DatePipe, NgIf} from "@angular/common";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {DonationInterpolatedResponse} from "../donation-interpolated.response";
import {DonationTypePipe} from "../../../shared/pipes/donation-type.pipe";
import {donationOtherTypes} from "../donation-other.types";
import {AvatarModule} from "primeng/avatar";
import {
  AcceptRejectActionsComponent
} from "../../../shared/components/accept-reject-actions/accept-reject-actions.component";

@Component({
  selector: 'bop-donations-sent',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    DonationStatusLabelComponent,
    PaymentMethodLabelComponent,
    FileUrlPipe,
    DatePipe,
    SpinnerOverlay,
    DonationTypePipe,
    AvatarModule,
    AcceptRejectActionsComponent,
    NgIf
  ],
  templateUrl: './donations-sent.component.html',
  styleUrl: './donations-sent.component.scss'
})
export class DonationsSentComponent {
  grid: DataGridTrait<DonationInterpolatedResponse> = new DataGridTrait<DonationInterpolatedResponse>(
    this.donationsService.getAllSent.bind(this.donationsService),
    this.route,
    this.router
  );

  loading = false;

  otherInfoTypes = donationOtherTypes;

  constructor(
    private donationsService: DonationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  includesDonationType(donation: DonationInterpolatedResponse, id: number): boolean {
    return donation.otherInfoTypeIds ? donation.otherInfoTypeIds.includes(id) : false;
  }
}
