import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SystemMessage} from "../models/system-message";
import {Injectable} from "@angular/core";
import {HomeCarousel} from "../../private/home/home-carousel";

@Injectable({providedIn: 'root'})
export class CarouselService {
  constructor(private http: HttpClient) {
  }

  public getCarouselImages(): Observable<HomeCarousel> {
    return this.http.get<HomeCarousel>(
      'api/files/carousel'
    );
  }

  public deleteCarouselImages(imageIds: number[]): Observable<any> {
    return this.http.delete<any>(
      'api/files/carousel', {headers: {}, body: {imageIds: imageIds}}
    );
  }
}
