import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {distinctUntilChanged, filter, map, Observable, tap} from "rxjs";
import {MunicipalityResponse} from "../../models/municipality.response";
import {SettlementResponse} from "../../models/settlement.response";
import {Store} from "@ngrx/store";
import {LocationState} from "../../store/location/location.state";
import {getMunicipalities, getSettlements} from "../../store/location/location.actions";
import {DistrictResponse} from "../../models/district.response";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {selectMunicipalities, selectSettlements} from "../../store/location/location.reducer";
import {lat2cyr} from "../../services/transliteration.service";

@Component({
  selector: 'bop-settlement-selection',
  standalone: true,
  imports: [
    AsyncPipe,
    AutoCompleteModule,
    InputGroupAddonModule,
    InputGroupModule,
    ReactiveFormsModule
  ],
  templateUrl: './settlement-selection.component.html',
  styleUrl: './settlement-selection.component.scss'
})
export class SettlementSelectionComponent {
  @Output()
  public onSettlementSelected: EventEmitter<SettlementResponse> = new EventEmitter<SettlementResponse>();

  @Input()
  public municipalityName: string = '';

  @Input()
  public settlementName: string = '';

  public municipalitySearchControl = new FormControl(this.municipalityName);
  public settlementSearchControl = new FormControl(this.settlementName);

  public municipalities$: Observable<MunicipalityResponse[]> =
    this.locationStore.select(selectMunicipalities).pipe(map(page => page.content!));

  public settlements$: Observable<SettlementResponse[]> =
    this.locationStore.select(selectSettlements).pipe(map(page => page.content!), tap(() => {
      this.municipalityField?.inputEL?.nativeElement.blur();
      this.settlementField?.inputEL?.nativeElement.focus();
    }));

  public selectedMunicipality: MunicipalityResponse | undefined;
  public selectedSettlement: SettlementResponse | undefined;
  private municipalityField: AutoComplete | undefined;
  private settlementField: AutoComplete | undefined;

  constructor(
    private locationStore: Store<LocationState>
  ) {
  }

  ngOnChanges() {
    this.municipalitySearchControl.setValue(lat2cyr(this.municipalityName));
    this.settlementSearchControl.setValue(lat2cyr(this.settlementName));
  }

  ngOnInit() {
    this.initMunicipalities('');
    this.initSettlements('')

    this.settlementSearchControl.disable();

    this.municipalitySearchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => !!value),
      map(value => lat2cyr(value!)),
    ).subscribe(request => this.locationStore.dispatch(getMunicipalities({request})));

    this.settlementSearchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => !!value),
      map(value => lat2cyr(value!)),
    ).subscribe(namePrefix => this.initSettlements(namePrefix));
  }

  onMunicipalitySelect($event: AutoCompleteSelectEvent) {
    this.selectedMunicipality = $event.value;
    this.settlementSearchControl.enable();
    this.initSettlements('');
  }

  onSettlementSelect($event: AutoCompleteSelectEvent) {
    this.selectedSettlement = $event.value;
    this.onSettlementSelected.emit(this.selectedSettlement);
  }

  private initMunicipalities(request: string) {
    this.locationStore.dispatch(getMunicipalities({request}))
  }

  private initSettlements(namePrefix: string) {
    this.locationStore.dispatch(
      getSettlements(
        {
          request: {
            municipality: this.selectedMunicipality || new MunicipalityResponse(1, '', new DistrictResponse(1, '')),
            namePrefix
          }
        })
    )
  }

  assignAutocompleteFields(municipalityField: AutoComplete, settlementField: AutoComplete) {
    this.municipalityField = municipalityField;
    this.settlementField = settlementField;
  }
}
