import {Component, Input, OnInit} from '@angular/core';
import {StatisticsResponse} from "../../../shared/models/statistics.response";
import {RouterLink} from "@angular/router";
import {DatePipe} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'bop-user-statistics',
  standalone: true,
  imports: [
    RouterLink,
    DatePipe,
    TooltipModule
  ],
  templateUrl: './user-statistics.component.html',
  styleUrl: './user-statistics.component.scss'
})
export class UserStatisticsComponent {
  @Input() public statistics: StatisticsResponse | undefined;
}
