<div class="flex justify-content-center">
  <img class="m-auto w-3" src="../../../../assets/img/logo/logo-colorful.png" alt="logo">
</div>
<!--<p><strong>Здравейте{{ firstName ? ', ' + firstName : '' }}!</strong></p>-->
<p>Благодарим за всеки Ваш принос и ДАРение с труд и/или финансов ресурс.</p>
<p>
  1. Народна кооперация Български Общ Пазар (БОП) връща жеста за всеки Ваш принос чрез
  добавяне на един кооперативен дял за всеки един от следните приноси:<br>
  • За всеки 12 месеца предплатен членски внос,<br>
  • За всеки регистриран Ваш Довереник, предплатил членски внос за 12 месеца: Член се става и
  с 1 лев, но ДАРителят и Довереникът не получават кооперативен дял,<br>
  • За всеки ДАРен час труд, съгласуван с БОП по тел. 0878 19 10 28,<br>
  • За всеки принос в 10 БОП доставки на удовлетворени Клиенти,<br>
  • За всеки 1 000 лева оборот като Клиент,<br>
  • За всеки 30 дни закъснение при отваряне на БОП след 1 Март 2024 г.,<br>
  • За всяко внедрено Ваше предложение за подобряване на процесите в БОП.<br>
  Всеки предплатен членски внос от 1 лев Ви дава право да поканите 5 Ваши Довереника.
</p>
<p>
  2. Оценяваме и уважаваме доверието Ви в каузата на БОП:
</p>
<p>
  Ето защо за навременната подкрепа и доверието си в БОП получават бонус всички
  кооператори, които са внесли встъпителна вноска от 12+ лева по-рано от 30 дни преди
  официалното отваряне на БОП с над 1 000 оферти за български продукти.
</p>
<p>
  Бонус „Доверие” се предоставя чрез удвояване на дяловете в началото на всяка от следващите
  5 /пет/ години,
</p>
<p>
  т.е. 1 дял получен над 30+ дни преди откриване на БОП, след 5 години става 32 дяла, което
  увеличава дадените навреме 12 лева на дялове за 384 лева.
</p>
<p>
  Това е и една от превенциите в БОП за враждебен финансов инженеринг.
</p>
<p>
  3. Решенията по Устава в БОП се взимат от всички с право на 1 глас.
</p>
<p>
  Стимулираме доверието и приносите в БОП като офертите, реда за доставките и взимането на
  инвестиционните решения се взимат по ред и процедури, в които са водещи са Вашите дялове
  и рейтинг.
</p>

<div class="mt-5"></div>

<strong class="text-danger-color"><u>ВАЖНО!</u></strong>
<p class="text-danger-color">
  Членският Ви внос от 1 лев месечно ще започне да се приспада, когато официално отворим
  БОП с предлагане на над 1 000 оферти за български продукти, вкл. храни, стоки, услуги и
  технологии. Предвиждаме това да стане за 2 до 3 месеца, през което време набираме,
  тестваме и проверяваме качеството на предложенията и надеждността на доставките.
</p>
<p class="text-danger-color">
  За всеки 30 дни закъснение при отваряне на БОП
</p>
<p class="text-danger-color">
  след 1 Март 2024 г.,
</p>
<p class="text-danger-color">
  БОП Ви компенсира с още 1 дял.
</p>

<p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>

<bop-accept-reject-buttons (onAccept)="onConfirm()" (onReject)="onDismiss()"></bop-accept-reject-buttons>

