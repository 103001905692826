import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {
  FormRowWrapperComponent
} from "../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {
  SettlementSelectionComponent
} from "../../../shared/components/settlement-selection/settlement-selection.component";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {availableRoles} from "../../profile/profile.config";
import {AsyncPipe} from "@angular/common";
import {CheckboxModule} from "primeng/checkbox";
import {ButtonModule} from "primeng/button";
import {SettlementResponse} from "../../../shared/models/settlement.response";
import {PhoneNumberRequest, RoleRequest} from "../../profile/profile.request";
import {LocationRequest} from "../../../shared/models/location/location.request";
import {Store} from "@ngrx/store";
import {inviteTrustee, inviteTrusteeSuccess} from "../store/donations.actions";
import {InviteTrusteeRequest} from "../invite-trustee.request";
import {normalizePhoneControl, PhoneNumberValidator} from "../../../shared/validators/phone-number.validator";
import {selectPendingInvitationLoading} from "../store/donations.reducer";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import { noCyrillicValidator } from '../../../shared/validators/email.validator';

@Component({
  selector: 'bop-donations-invite',
  standalone: true,
  imports: [
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    FormRowWrapperComponent,
    SettlementSelectionComponent,
    FormWrapperComponent,
    AsyncPipe,
    CheckboxModule,
    ButtonModule,
    SpinnerOverlay
  ],
  templateUrl: './donations-invite.component.html',
  styleUrl: './donations-invite.component.scss'
})
export class DonationsInviteComponent implements OnInit {
  public selectedRoles: {roleId: number, name: string, selected: boolean}[] = [];
  public selectedSettlement!: SettlementResponse;
  public phoneNumber = '';
  public otherRole = false;
  public notifyByEmail = false;
  public notifyBySms = false;
  public dontNotify: boolean = false;
  public otherRoleReason = '';

  public loading = false;

  public trusteeForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern('^[^\\d]*$')]),
    lastName: new FormControl('', [Validators.pattern('^[^\\d]*$')]),
    phoneNumber: new FormControl(new PhoneNumberRequest()),
    phone: new FormControl('', [Validators.required, PhoneNumberValidator('BG')]),
    email: new FormControl('', [Validators.required, noCyrillicValidator()]),
    otherInfo: new FormControl(''),
    district: new FormControl(''),
    notifyBySMS: new FormControl(false),
    notifyByEmail: new FormControl(false),
    roles: new FormArray([],),
    locations: new FormArray([]),
  });

  constructor(private store: Store) {
  }

  ngOnInit () {
    availableRoles.filter(r => r.id !== 6).forEach(role => {
      this.selectedRoles.push({roleId: role.id, name: role.name, selected: false});
    })

    this.store.select(selectPendingInvitationLoading).subscribe(loading => this.loading = loading);
  }

  setSettlement($event: SettlementResponse) {
    this.selectedSettlement = $event;
  }

  saveTrustee() {
    this.normalizePhoneControl();
    this.selectedRoles.forEach(r => {
      if (!r.selected) {
        return;
      }
      this.getRolesFormArray().push(new FormControl(new RoleRequest(r.roleId, '')))
    })

    if (this.otherRole) {
      this.getRolesFormArray().push(new FormControl(new RoleRequest(6, this.otherRoleReason)));
    }

    this.getLocationsFormArray().push(new FormControl(new LocationRequest(null,'','', '', this.selectedSettlement.id)));

    this.trusteeForm.patchValue({
      notifyByEmail: this.notifyByEmail,
      notifyBySMS: this.notifyBySms,
      phoneNumber: new PhoneNumberRequest(null, this.trusteeForm.get('phone')?.value || '')
    })

    const request: InviteTrusteeRequest = Object.assign(new InviteTrusteeRequest(), this.trusteeForm.value)

    this.store.dispatch(inviteTrustee({request}));
  }

  getRolesFormArray(): FormArray {
    return this.trusteeForm.get('roles') as FormArray;
  }

  getLocationsFormArray(): FormArray {
    return this.trusteeForm.get('locations') as FormArray;
  }

  normalizePhoneControl() {
    normalizePhoneControl(this.trusteeForm.get('phone')!)
  }
}
