import {PhoneNumberResponse} from "../profile/profile.response";

export class UserFlatResponse {
  constructor(
    public id: number = 0,
    public password: string = '',
    public firstName: string = '',
    public middleName: string = '',
    public lastName: string = '',
    public email: string = '',
    public address: string = '',
    public addressComment: string = '',
    public registrationDate: Date = new Date(),
    public bopDream: string  = '',
    public admin: boolean = false,
    public status: number = 1,
    public cityId: number = 1,
    public invitedById: number = 0,
    public villageId: number = 1,
    public invitedBy: number  = 1,
    public phoneNumbers: PhoneNumberResponse[] = []
  ) {

  }
}
