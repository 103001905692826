import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'bop-market-create',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './market-create.component.html',
  styleUrl: './market-create.component.scss'
})
export class MarketCreateComponent {

}
