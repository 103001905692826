import { Component } from '@angular/core';
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {ChipModule} from "primeng/chip";

@Component({
  selector: 'bop-address-card',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    ChipModule
  ],
  templateUrl: './address-card.component.html',
  styleUrl: './address-card.component.scss'
})
export class AddressCardComponent {

}
