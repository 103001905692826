import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ProfileResponse} from "./profile.response";
import {ProfileRequest} from "./profile.request";
import {ChangeInvitedByRequest} from "./change-invited-by.request";
import {TrusteeTransferResponse} from "./trustee.transfer.response";

@Injectable({providedIn: 'root'})
export class ProfileService {

  constructor(
    private http: HttpClient,
  ) { }

  public getMyProfile(): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>(
      'api/users/me'
    );
  }

  public updateMyProfile(request: ProfileRequest): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(
      'api/users/me',
      request
    );
  }

  public getInvitedUser(token: string): Observable<ProfileResponse> {
    let params = new HttpParams();
    params = params.set('token', token);
    return this.http.get<ProfileResponse>(
      'api/users/invited',
      {params}
    );
  }

  public finishUser(token: string, request: ProfileRequest): Observable<ProfileResponse> {
    let params = new HttpParams();
    params = params.set('token', token)

    return this.http.patch<ProfileResponse>(
      'api/users',
      request,
      {params}
    );
  }

  public approveTrustee(): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/transfers/approve-trustee', {}
    );
  }

  public approveInviter(): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/transfers/approve-inviter', {}
    );
  }

  public rejectTrustee(): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/transfers/reject-trustee', {}
    );
  }

  public rejectInviter(): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/transfers/reject-inviter', {}
    );
  }

  public getTrusteeTransfer(): Observable<TrusteeTransferResponse> {
    return this.http.get<TrusteeTransferResponse>(
      'api/users/transfers'
    );
  }
}
