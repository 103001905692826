<section class="w-full h-full bg-no-repeat bg-cover bg-center border-round-md relative">
  <div class="current-background absolute inset-0 z-0 bg-no-repeat bg-cover bg-center w-full h-full border-round-md"
       [ngStyle]="{'background-image': 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(' + currentBackgroundImage + ')'}">
  </div>
  <div class="next-background absolute inset-0 z-0 bg-no-repeat bg-cover bg-center w-full h-full border-round-md"
       [ngStyle]="{'background-image': 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(' + nextBackgroundImage + ')', 'opacity': nextImageVisible ? 1 : 0}">
  </div>
  <section class="custom-layout-background relative z-1 h-full w-full">
    <ng-content></ng-content>
  </section>
</section>
