import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthenticationRequest} from "./authentication.request";
import {AuthenticationResponse} from "./authentication.response";
import {Observable, of, tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
  ) { }

  public login(request: AuthenticationRequest): Observable<AuthenticationResponse> {
    return this.http.post<AuthenticationResponse>(
      '/api/token', request
    ).pipe(tap(response => this.setToken(response.jwt)));
  }

  public logout() {
    this.removeToken();

    return of([]);
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  private setToken(token: string) {
    return localStorage.setItem('token', token);
  }

  private removeToken() {
    return localStorage.removeItem('token');
  }

}
