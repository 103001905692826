import {createAction, props} from "@ngrx/store";
import {DonationCreateRequest} from "../donation-create.request";
import {DonationResponse} from "../donation.response";
import {InviteTrusteeRequest} from "../invite-trustee.request";
import {UserFlatResponse} from "../user-flat.response";
import {ProfileResponse} from "../../profile/profile.response";

export const createDonation = createAction(
  "[Donation_Create] Create donation",
  props<{ request: DonationCreateRequest, otherInfoImageFile: File, paymentImageFile: File }>()
)

export const createDonationSuccess = createAction(
  "[Donation_Create_Success] Create donation success",
  props<{ response: DonationResponse }>()
)

export const createDonationError = createAction(
  "[Donation_Create_Error] Create donation error",
  props<{ error: any }>()
)

export const inviteTrustee = createAction(
  "[Donation_InviteTrustee] Invite trustee",
  props<{ request: InviteTrusteeRequest }>()
)

export const inviteTrusteeSuccess = createAction(
  "[Donation_InviteTrustee_Success] Invite trustee success",
  props<{ response: UserFlatResponse }>()
)

export const inviteTrusteeError = createAction(
  "[Donation_InviteTrustee_Error] Invite trustee error",
  props<{ error: any }>()
)

export const donationUpdateSuccess = createAction(
  "[Donation_Update_Success] Update donation success",
  props<{ response: DonationResponse }>()
)

export const donationUpdateError = createAction(
  "[Donation_Update_Error] Update donation error",
  props<{ error: any }>()
)

export const trusteeDeleteSuccess = createAction(
  "[Donation_Trustee_Delete_Success] Delete trustee success",
  props<{ response: DonationResponse }>()
)

export const trusteeDeleteError = createAction(
  "[Donation_Trustee_Delete_Error] Update donation error",
  props<{ error: any }>()
)

export const trusteeEditSuccess = createAction(
  "[Donation_Trustee_Edit_Success] Edit trustee success"
)

export const trusteeReinviteSuccess = createAction(
  "[Donation_Trustee_Delete_Reinvite] Reinvite trustee success",
  props<{ response: ProfileResponse }>()
)

export const trusteeReinviteError = createAction(
  "[Donation_Trustee_Reinvite_Error] Reinvite trustee error",
  props<{ error: any }>()
)

export const trusteeEditError = createAction(
  "[Donation_Trustee_Edit_Error] Edit trustee error"
)
