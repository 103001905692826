import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {ProfileResponse} from "../profile/profile.response";
import {InvokableFiltration} from "../../shared/models/filtration/filtration";
import {PageableRequest} from "../../shared/models/pageable/pageable.request";
import {PageableResponse} from "../../shared/models/pageable/pageable.response";
import {QueryFormattingUtility} from "../../shared/models/filtration/query-formatting.utility";
import {DonationResponse} from "../donations/donation.response";
import {UpdateDonationRequest} from "../donations/donation-update.request";
import {FileService} from "../../shared/services/file.service";
import {UpdateAdminTrusteeRequest} from "./update-admin-trustee.request";
import {DonationInterpolatedResponse} from "../donations/donation-interpolated.response";
import {CooperatorsInfo} from "./cooperators-info";

@Injectable({providedIn: 'root'})
export class AdminService {

  constructor(
    private http: HttpClient,
    private queryFormattingUtility: QueryFormattingUtility,
    private fileService: FileService
  ) {
  }

  public getAllTrustees(filtration: InvokableFiltration, page: PageableRequest): Observable<PageableResponse<ProfileResponse>> {
    return this.http.get<PageableResponse<ProfileResponse>>(
      'api/users', {params: this.queryFormattingUtility.formatQuery(page, filtration)}
    );
  }

  public updateTrustee(id: number, request: UpdateAdminTrusteeRequest): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(
      'api/users/' + id, request
    );
  }

  public getAllDonations(filtration: InvokableFiltration, page: PageableRequest): Observable<PageableResponse<DonationInterpolatedResponse>> {
    return this.http.get<PageableResponse<DonationInterpolatedResponse>>(
      'api/donations', {params: this.queryFormattingUtility.formatQuery(page, filtration)}
    );
  }

  public approveDonation(id: number) {
    const request = new UpdateDonationRequest(id, 1);

    return this.updateDonation(request);
  }

  public rejectDonation(id: number) {
    const request = new UpdateDonationRequest(id, 2);

    return this.updateDonation(request);
  }

  public saveCooperators() {
    return this.http.get('api/files/cooperators-book',
      {
        observe: 'response',
        responseType: 'blob'
      }).pipe(
      tap(response => this.fileService.saveAs(response))
    );
  }

  public saveDonations(): Observable<any> {
    return this.http.get('api/files/donations?action=download',
      {
        observe: 'response',
        responseType: 'blob'
      }).pipe(
      tap(response => this.fileService.saveAs(response))
    );
  }

  private updateDonation(request: UpdateDonationRequest) {
    return this.http.patch<DonationResponse>(
      'api/donations/' + request.id, request
    );
  }

  public getCooperatorsInfo(): Observable<CooperatorsInfo> {
    return this.http.get<CooperatorsInfo>('/api/cooperators');
  }

  public updateCooperatorsInfo(info: CooperatorsInfo): Observable<any> {
    return this.http.put('/api/cooperators', info);
  }

}
