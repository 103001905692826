import {Component, inject} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {LayoutStore} from "../shared/store/layout/layout.store";

@Component({
  selector: 'bop-guest',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './guest.component.html',
  styleUrl: './guest.component.scss'
})
export class GuestComponent {
  readonly layoutStore = inject(LayoutStore);

  ngOnInit() {
    this.layoutStore.setSidebarExpanded(false);
  }
}
