import {Component, Input} from '@angular/core';
import {donationStatusConfig, DonationStatusConfigField} from "./donation-status.config";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'bop-donation-status-label',
  standalone: true,
  imports: [
    TagModule
  ],
  templateUrl: './donation-status-label.component.html',
  styleUrl: './donation-status-label.component.scss'
})
export class DonationStatusLabelComponent {
  @Input() public id!: number;

  public donationStatusConfig: {[id: number]: DonationStatusConfigField} = donationStatusConfig;
}
