import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'bop-accept-reject-buttons',
  standalone: true,
    imports: [
        ButtonModule,
        TooltipModule
    ],
  templateUrl: './accept-reject-buttons.component.html',
  styleUrl: './accept-reject-buttons.component.scss'
})
export class AcceptRejectButtonsComponent {
  @Input()
  public acceptText = 'Приеми';

  @Input()
  public rejectText = 'Откажи';

  @Output()
  public onAccept = new EventEmitter();

  @Output()
  public onReject = new EventEmitter();
}
