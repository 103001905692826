import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {EditorModule} from "primeng/editor";
import {RichEditorModel} from "./rich-editor-model";

@Component({
  selector: 'bop-rich-editor',
  standalone: true,
  imports: [
    FormsModule,
    EditorModule
  ],
  templateUrl: './rich-editor.component.html',
  styleUrl: './rich-editor.component.scss'
})
export class RichEditorComponent {
  @Input() public model: RichEditorModel = new RichEditorModel();
}
