import {Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {CarouselModule} from "primeng/carousel";
import {SharesService} from "../../shared/services/shares.service";
import {Observable, tap} from "rxjs";
import {SharesResponse} from "../../shared/models/shares.response";
import {PanelModule} from "primeng/panel";
import {ProfileState} from "../profile/store/profile.state";
import {Store} from "@ngrx/store";
import {ProfileResponse} from "../profile/profile.response";
import {selectCurrentUser, selectGetProfileLoading, selectTransferStatuses} from "../profile/store/profile.reducer";
import {DialogService} from "primeng/dynamicdialog";
import {TermsOfServiceComponent} from "../../shared/components/terms-of-service/terms-of-service.component";
import {ButtonModule} from "primeng/button";
import {ProfileService} from "../profile/profile.service";
import {ChangeInvitedByRequest} from "../profile/change-invited-by.request";
import {MessageService} from "primeng/api";
import {getMyProfile, getTrusteeTransfer} from "../profile/store/profile.actions";
import {
  AcceptRejectButtonsComponent
} from "../../shared/components/accept-reject-buttons/accept-reject-buttons.component";
import {SystemService} from "../../shared/services/system.service";
import {SystemMessage} from "../../shared/models/system-message";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CarouselService} from "../../shared/services/carousel.service";
import {HomeCarousel} from "./home-carousel";
import {FileUrlPipe} from "../../shared/pipes/file-url.pipe";
import {RouterLink} from "@angular/router";
import {SpinnerOverlay} from "../../shared/components/spinner/spinner-overlay";
import {
  ApprovableRejectableWidgetComponent
} from "../../shared/components/approvable-rejectable-widget/approvable-rejectable-widget.component";

@Component({
  selector: 'bop-home',
  standalone: true,
  imports: [
    CarouselModule,
    PanelModule,
    ButtonModule,
    AcceptRejectButtonsComponent,
    FileUrlPipe,
    RouterLink,
    SpinnerOverlay,
    ApprovableRejectableWidgetComponent
  ],
  providers: [DialogService],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  public shares$: Observable<SharesResponse> = this.sharesService.getAllShares();
  public currentUser$: Observable<ProfileResponse | null> = this.store.select(selectCurrentUser);
  public systemMessage$: Observable<SystemMessage> = this.systemService.getSystemMessage();
  public sanitizedSystemMessage: SafeHtml = '';
  public carouselImages: HomeCarousel = new HomeCarousel();
  public transferStatuses$: Observable<any> = this.store.select(selectTransferStatuses);

  readonly layoutStore = inject(LayoutStore);

  public loading = false;

  constructor(public sanitizer: DomSanitizer,
              private sharesService: SharesService,
              private store: Store<ProfileState>,
              private dialogService: DialogService,
              private messageService: MessageService,
              private systemService: SystemService,
              private carouselService: CarouselService,
              private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.layoutStore.setSidebarExpanded(false);
    this.systemMessage$.subscribe({
      next: systemMessage => this.sanitizedSystemMessage = this.sanitizer.bypassSecurityTrustHtml(systemMessage.message)
    })
    this.carouselService.getCarouselImages()
      .subscribe({
        next: images => this.carouselImages = images
      })
    this.store.select(selectGetProfileLoading).subscribe(loading => this.loading = loading);

    this.store.select(selectCurrentUser).pipe(
      tap(currentUser => {
        if (currentUser?.pendingInvitedBy || currentUser?.pendingTrustee) {
          this.store.dispatch(getTrusteeTransfer())
        }
      })
    ).subscribe();
  }

  public openTermsOfServiceDialog() {
    this.dialogService.open(TermsOfServiceComponent, {header: 'Общи условия на БОП', closable: false, width: '90vw'})
  }

  public onChangeTrusteeDismiss() {
    this.profileService.rejectTrustee()
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешен отказ.',
            detail: 'Лицето няма да бъде добавено къв Вашите довереници.'
          });

          this.store.dispatch(getMyProfile());
          this.store.dispatch(getTrusteeTransfer());
        }
      })
  }

  public onChangeTrusteeConfirm() {
    this.profileService.approveTrustee()
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешен прием.',
            detail: 'При потвърждение от довереника, той официално ще се превърне във Ваш довереник.'
          });

          this.store.dispatch(getMyProfile());
          this.store.dispatch(getTrusteeTransfer());
        }
      })
  }

  public onChangeInvitedByDismiss() {
    this.profileService.rejectInviter()
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешен отказ.',
            detail: 'Вашият дарител няма да бъде променен.'
          });

          this.store.dispatch(getMyProfile());
          this.store.dispatch(getTrusteeTransfer());
        }
      })
  }

  public onChangeInvitedByConfirm() {
    this.profileService.approveInviter()
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешен прием.',
            detail: 'При потвърждение от ДАРителя, той официално ще се превърне във Ваш ДАРител.'
          });

          this.store.dispatch(getMyProfile());
          this.store.dispatch(getTrusteeTransfer());
        }
      })
  }
}
