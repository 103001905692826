import {Component, Input} from '@angular/core';
import {paymentMethods} from "../../config/payment-method.config";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'bop-payment-method-label',
  standalone: true,
  imports: [
    TagModule
  ],
  templateUrl: './payment-method-label.component.html',
  styleUrl: './payment-method-label.component.scss'
})
export class PaymentMethodLabelComponent {
  @Input() public id: number | undefined;

  public methods = paymentMethods;

  // public colorSeverity = '';

  ngOnInit() {
    // switch (this.id) {
    //   case 1:
    //     this.colorSeverity = ''
    //     return;
    //   case 2:
    //     this.colorSeverity = 'success'
    //     return;
    //   case 3:
    //     this.colorSeverity = 'info'
    //     return;
    //   case 4:
    //     this.colorSeverity = 'warning'
    //     return;
    //   case 5:
    //     this.colorSeverity = 'danger'
    //     return;
    // }
  }

  protected readonly paymentMethods = paymentMethods;
}
