import {Component, EventEmitter, HostListener, inject, Input, Output} from '@angular/core';
import {MenuItem, MenuItemCommandEvent, PrimeIcons} from "primeng/api";
import {SidebarModule} from "primeng/sidebar";
import {MenubarModule} from "primeng/menubar";
import {PanelMenuModule} from "primeng/panelmenu";
import {AsyncPipe} from "@angular/common";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {StatisticsResponse} from "../../models/statistics.response";

@Component({
  selector: 'bop-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    MenubarModule,
    PanelMenuModule,
    AsyncPipe,
    MenuModule,
    ButtonModule
  ],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  @Output()
  public sidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public items: MenuItem[] | undefined;

  @Input()
  public sidebarVisible = false;

  @Input()
  public profileMeta: StatisticsResponse | undefined;

  isMobile = false;

  constructor() {
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }

  closeSidebar() {
    if (this.isMobile) {
      this.sidebarVisibility.emit(false);
    }
  }

  public onMenuItemClick(event: MenuItemCommandEvent): void {
    if (event.item && (!event.item.items || event.item.items.length === 0)) {
      this.closeSidebar();
    }
  }

  public attachCommand(items: MenuItem[] | undefined): void {
    if (items) {
      items.forEach(item => {
        item.command = (event: MenuItemCommandEvent) => this.onMenuItemClick(event);
        if (item.items) {
          this.attachCommand(item.items);
        }
      });
    }
  }

  ngOnChanges(): void {
    this.attachCommand(this.items);
  }

}
