<section id="layout" class="min-h-screen flex relative surface-ground">
  <p-toast></p-toast>

  <bop-sidebar (sidebarVisibility)="setSidebarExpanded($event)"
               [sidebarVisible]="layoutStore.sidebarExpanded()"
               [profileMeta]="layoutStore.profileMeta()"
               [items]="layoutStore.sidebarItems()"></bop-sidebar>

  <section id="content" class="min-h-screen flex flex-column flex-auto">
    <bop-header (sidebarVisibility)="setSidebarExpanded($event)"
                (themeChange)="switchTheme($event)"
                [menuItems]="layoutStore.headerItems()"
                [profileItems]="layoutStore.profileItems()"
                [profileMeta]="layoutStore.profileMeta()"
                [theme]="theme" ></bop-header>

    <section id="main-content" class="p-2 flex flex-column flex-auto">
      <article id="dashed-content-wrapper" class="border-2 border-dashed surface-border border-round surface-section flex-auto">
        <router-outlet></router-outlet>
      </article>

      <div class="text-sm w-full text-center my-2 font-italic text-primary-400">Версия: {{ (version$ | async)?.version }}::{{ (version$ | async)?.date }} </div>
    </section>

  </section>
</section>
