import {AuthenticationState} from "./authentication.state";
import {createFeature, createReducer, on} from "@ngrx/store";
import {login, loginError, loginSuccess, logout, logoutSuccess} from "./authentication.actions";

const initialState: AuthenticationState = {
  loggedIn: false,
  successMessage: '',
  errorMessage: ''
}

const authenticationFeature = createFeature({
  name: "Authentication",

  reducer: createReducer(
    initialState,

    on(loginSuccess, (state, {response, message}) => ({
      ...state,
      successMessage: message,
      loggedIn: true
    })),


    on(loginError, (state, {error}) => ({
      ...state,
      errorMessage: error
    })),

    on(logoutSuccess, (state) => ({
      ...state,
      loggedIn: false
    }))
  )
})


export const {
  name: authenticationFeatureKey,
  reducer: authenticationReducer,
  selectLoggedIn,
  selectSuccessMessage,
  selectErrorMessage
} = authenticationFeature;

