import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {map, switchMap} from "rxjs";
import {LocationService} from "../../services/location.service";
import {Filtration} from "../../models/filtration/filtration";
import {getMunicipalities, getMunicipalitiesSuccess, getSettlements, getSettlementsSuccess} from "./location.actions";
import {PageableRequest} from "../../models/pageable/pageable.request";
import {reindexPageable} from "../../models/pageable/pageable.operators";


@Injectable()
export class LocationEffects {
  constructor(
    private locationService: LocationService,
    private filtration: Filtration,
    private actions$: Actions,
  ) {
  }

  municipalitiesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMunicipalities),
      switchMap(action => this.locationService.getMunicipalities(
        PageableRequest.all(),
        this.filtration.field("name").startsWith(action.request)
      ).pipe(
        reindexPageable(),
        map(response => {
          return getMunicipalitiesSuccess({response}
        )})
      ))
    )
  );

  settlementsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlements),
      switchMap(action => this.locationService.getSettlements(
        PageableRequest.all(),
        this.filtration
          .field("municipality.id").equals(action.request.municipality.id)
          .and('name').contains(action.request.namePrefix)
      ).pipe(
        reindexPageable(),
        map(response => getSettlementsSuccess({response}))
      ))
    )
  )

}
