<form [formGroup]="dataForm" class="flex flex-column gap-3">
  <h1 class="text-2xl">Допълване на данните</h1>

  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input pInputText formControlName="firstName" placeholder="Име"/>
    </p-inputGroup>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input type="text" pInputText formControlName="middleName" placeholder="Презиме"/>
    </p-inputGroup>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input type="text" pInputText formControlName="lastName" placeholder="Фамилия"/>
    </p-inputGroup>
  </bop-form-row-wrapper>

  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-send"></i>
      </p-inputGroupAddon>
      <input pInputText formControlName="email" placeholder="Имейл адрес"/>
    </p-inputGroup>

    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-phone"></i>
      </p-inputGroupAddon>
      <input pInputText [formControl]="getPhoneNumberControl(0)" placeholder="Телефон 1" (focusout)="normalizePhoneControl(getPhoneNumberControl(0))"/>
    </p-inputGroup>

    @if (getPhoneNumberControl(0).invalid && getPhoneNumberControl(0).touched) {
      <small class="text-red-600">Моля, въведете валиден телефонен номер</small>
    }

    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-phone"></i>
      </p-inputGroupAddon>
      <input pInputText [formControl]="getPhoneNumberControl(1)" placeholder="Телефон 2" (focusout)="normalizePhoneControl(getPhoneNumberControl(1), true)"/>
    </p-inputGroup>
    @if (getPhoneNumberControl(1).invalid && getPhoneNumberControl(1).touched) {
      <small class="text-red-600">Моля, въведете валиден телефонен номер</small>
    }

  </bop-form-row-wrapper>

  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <p-password placeholder="Парола"
                  formControlName="password"
                  [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                  [toggleMask]="true"
                  [feedback]="false"></p-password>
    </p-inputGroup>
    @if (dataForm.get('password')?.invalid && dataForm.get('password')?.touched) {
      <small class="text-red-600">Паролите не съвпадат</small>
    }

    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <p-password placeholder="Повторете паролата"
                  formControlName="confirmPassword"
                  [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                  [toggleMask]="true"
                  [feedback]="false"></p-password>
    </p-inputGroup>
    @if (dataForm.get('confirmPassword')?.invalid && dataForm.get('confirmPassword')?.touched) {
      <small class="text-red-600">Паролите не съвпадат</small>
    }
  </bop-form-row-wrapper>

</form>
