<p-menu class="w-12" #menu [model]="profileItems" [popup]="true">
  <ng-template pTemplate="start">
    <section class="p-1">
      <p>Здравейте, {{ profileMeta?.name }}!</p>
      <p-divider></p-divider>
      <div class="stats cursor-pointer" routerLink="/private/donations/sent">
        <bop-user-statistics [statistics]="profileMeta || undefined"></bop-user-statistics>
      </div>
      <p-divider></p-divider>
    </section>
  </ng-template>
</p-menu>

<p-button [icon]="theme.icon" [rounded]="true" class="mr-2" (click)="themeChange.emit(theme.switchTo())"></p-button>
<p-button icon="pi pi-user" [rounded]="true" class="mr-2"  severity="info" (click)="menu.toggle($event)"></p-button>
