import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map, Observable, tap} from "rxjs";
import {ProfileResponse} from "../profile/profile.response";
import {InvokableFiltration} from "../../shared/models/filtration/filtration";
import {PageableRequest} from "../../shared/models/pageable/pageable.request";
import {PageableResponse} from "../../shared/models/pageable/pageable.response";
import {QueryFormattingUtility} from "../../shared/models/filtration/query-formatting.utility";
import {DonationCreateRequest} from "./donation-create.request";
import {DonationResponse} from "./donation.response";
import {InviteTrusteeRequest} from "./invite-trustee.request";
import {UserFlatResponse} from "./user-flat.response";
import {FileService} from "../../shared/services/file.service";
import {UpdateMyTrusteeRequest} from "./update-my-trustee.request";
import {DonationIdResponse} from "./donation-id.response";
import {ChangeInvitedByRequest} from "../profile/change-invited-by.request";
import {DonationInterpolatedResponse} from "./donation-interpolated.response";

@Injectable({providedIn: 'root'})
export class DonationsService {

  constructor(
    private http: HttpClient,
    private queryFormattingUtility: QueryFormattingUtility,
    private fileService: FileService
  ) {
  }

  public inviteTrustee(request: InviteTrusteeRequest) {
    return this.http.post<UserFlatResponse>(
      'api/users/invite', request
    );
  }

  public getMyTrustees(filtration: InvokableFiltration, page: PageableRequest): Observable<PageableResponse<ProfileResponse>> {
    return this.http.get<PageableResponse<ProfileResponse>>(
      'api/users/me/trustees', {params: this.queryFormattingUtility.formatQuery(page, filtration)}
    );
  }

  public getAllTrustees(filtration: InvokableFiltration, page: PageableRequest): Observable<PageableResponse<ProfileResponse>> {
    return this.http.get<PageableResponse<ProfileResponse>>(
      'api/users', {params: this.queryFormattingUtility.formatQuery(page, filtration)}
    );
  }

  public updateMyTrustee(id: number, request: UpdateMyTrusteeRequest): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(
      'api/users/' + id, request
    );
  }

  public deleteTrustee(id: number): Observable<any> {
    return this.http.delete<PageableResponse<any>>(
      'api/users/' + id
    );
  }

  public reinviteTrustee(id: number): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/invite/' + id,
      {notifyBySMS: false, notifyByEmail: true}
    );
  }


  public changeInvitedBy(request: ChangeInvitedByRequest): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(
      'api/users/transfers',
      request
    );
  }

  public createDonation(request: DonationCreateRequest): Observable<DonationResponse> {
    return this.http.post<DonationResponse>('api/donations', request);
  }

  public getPending(): Observable<DonationResponse> {
    return this.http.get<DonationResponse>('api/donations/me/pending');
  }

  public getReservedDonationId(): Observable<DonationIdResponse> {
    return this.http.get<DonationIdResponse>('api/donations/reserved');
  }

  public getAllReceived(filtration: InvokableFiltration, pageable: PageableRequest): Observable<PageableResponse<DonationInterpolatedResponse>> {
    return this.http.get<PageableResponse<DonationInterpolatedResponse>>(
      'api/donations/me/received',
      {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  public getAllSent(filtration: InvokableFiltration, pageable: PageableRequest): Observable<PageableResponse<DonationInterpolatedResponse>> {
    return this.http.get<PageableResponse<DonationInterpolatedResponse>>(
      'api/donations/me/sent'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  public saveWishes(action: 'download' | 'email' = 'download'): Observable<any> {
    let params = new HttpParams();
    params = params.set('action', action);

    return this.http.get('api/files/dreams', {observe: 'response', responseType: 'blob', params})
      .pipe(
        tap(result => {
          if (action === 'email') {
            return;
          }

          this.fileService.saveAs(result);
        })
      );
  }
}
