<div class="w-full flex justify-content-center gap-2">
  @if (showCamera) {
    <p-button [label]="photoButtonText" icon="pi pi-camera" class="w-14" (click)="openCameraPopup()"></p-button>
  }
  <p-button [label]="galleryButtonText" icon="pi pi-plus" class="w-14r" (click)="fileInput.click()"></p-button>

  <input #fileInput type="file" accept="image/*" hidden (change)="onGalleryPhotoUploaded($event)">
</div>

@if (showPreview) {
  <div class="text-center my-3">
    <img class="w-20rem" [src]="previewSrc" alt="">
  </div>
}
