import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../../public/authentication/authentication.service";

export const AuthenticationGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  if (inject(AuthenticationService).getToken() !== null) {
    return true;
  }

  if (state.url.includes('profile/edit')) {
    return true;
  }

  inject(Router).navigate(['public', 'authentication']);

  return true;
}

