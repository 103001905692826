import { Component } from '@angular/core';
import {
  AcceptRejectButtonsComponent
} from "../../../shared/components/accept-reject-buttons/accept-reject-buttons.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'bop-market-missing-role-dialog',
  standalone: true,
  imports: [
    AcceptRejectButtonsComponent
  ],
  templateUrl: './market-missing-role-dialog.component.html',
  styleUrl: './market-missing-role-dialog.component.scss'
})
export class MarketMissingRoleDialogComponent {
  constructor(private dialogRef: DynamicDialogRef<MarketMissingRoleDialogComponent>) {
  }

  public accept() {
    this.dialogRef.close(true);
  }

  public reject() {
    this.dialogRef.close(false);
  }
}
