import {HttpInterceptorFn} from "@angular/common/http";
import {inject} from "@angular/core";
import {AuthenticationService} from "../../public/authentication/authentication.service";

export const authenticationHeadersInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(AuthenticationService).getToken();

  if (!token) {
    return next(req);
  }

  const request = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  });

  return next(request);
};
