<bop-form-wrapper>
  <h1 class="text-2xl">Промяна на данни за връзка с кооператор</h1>
  <p class="font-italic">Моля, въведете телефонния номер и имейл адреса за връзка с кооператор.</p>
  <div class="w-full text-left flex flex-column gap-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
      <input pInputText [(ngModel)]="phoneNumber" placeholder="Телефонен номер">
    </div>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
      <input pInputText [(ngModel)]="supportEmail" placeholder="Имейл адрес">
    </div>
    <div class="flex justify-content-center">
      <p-button (onClick)="updateSettings()" icon="pi pi-check" label="Запази информация за кооператора"></p-button>
    </div>
  </div>
</bop-form-wrapper>
