<section class="text-center">
  <div class="flex flex-column md:flex-row gap-2 justify-content-center align-items-center">
    <h1 class="text-2xl">Добре дошли в</h1>
    <img class="w-5 md:w-2" src="../../../assets/img/logo/logo-colorful.png"/>
  </div>

  <div class="flex flex-column md:flex-row gap-2 justify-content-center align-items-center">
    <p>Ако имате този линк, значи ваш близък ви е помолил да качите снимка, асоциирана с негов адрес. Моля, качете снимката чрез някоя от опциите по-долу. След като го направите, този линк повече няма да бъде достъпен.</p>
  </div>

  <bop-photo-choice (fileSelectedEmitter)="setSelectedImage($event)"></bop-photo-choice>

  <p-button icon="pi pi-save" label="Запази" [disabled]="!selectedImage" (click)="saveImage()"></p-button>
</section>
