<section class="text-sm">
  <div [pTooltip]="reputationTooltip" class="flex justify-content-between align-items-center">
    <p>Репутация:</p>
    <p>{{ statistics?.rating }}</p>
  </div>

  <ng-template #reputationTooltip>
    <div class="text-xs">
      <p>Репутацията Ви се формира, както следва:</p>
      <ul>
        <li>50 % от удовлетворените клиенти</li>
        <li>30 % от оборота ви като Производител, Транспортьор и Клиент</li>
        <li>15 % от гаранции за качество, дарения и инвестиран ресурс</li>
        <li>5 % от обективността на Вашите оценки за качество на доставките</li>
      </ul>
    </div>
  </ng-template>

  <div [pTooltip]="cooperativeSharesTooltip" class="flex justify-content-between align-items-center">
    <p>Кооперативни дялове:</p>
    <p>{{ statistics?.cooperativeShares }}</p>
  </div>

  <ng-template #cooperativeSharesTooltip>
    <div class="text-xs">
      <p>Вашите кооперативни дялове са определящи при:</p>
      <ol>
        <li>Разпределяне на годишната печалба след първите 5 години дейност</li>
        <li>При взимане на решения в ИНВЕСТИЦИОНЕН И ИНОВАЦИОНЕН ФОНД</li>
        <li>При формиране на Вашата репутация</li>
        <li>При пазарна реализация на Вашите оферти и заявки за продукти</li>
        <li>При определяне на приоритета на предложенията Ви за оптимизиране на софтуера</li>
      </ol>
    </div>
  </ng-template>

  <div pTooltip="Вашите приноси и ДАРове, внесени лично и чрез ДАРителя Ви"
       class="flex justify-content-between align-items-center">
    <p>Дарени от Вас:</p>
    <p>{{ statistics?.cooperativeShares }}</p>
  </div>

  <div pTooltip="Неотчетени приноси и дарения от Доверениците Ви"
       class="flex justify-content-between align-items-center">
    <p>За внасяне:</p>
    <p>{{ statistics?.pendingDonations }}</p>
  </div>

  @if ((statistics?.pendingDonations || 0) >= 120) {
    <div class="flex justify-content-between align-items-center">
      <p>Срок за плащане:</p>
      <p>{{ statistics?.deactivateDate | date:'dd.MM.yy' }}</p>
    </div>
  }
</section>
