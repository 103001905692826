import { Component } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {WebcamImage, WebcamModule} from "ngx-webcam";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'bop-photo-choice-camera',
  standalone: true,
  imports: [
    ButtonModule,
    WebcamModule
  ],
  templateUrl: './photo-choice-camera.component.html',
  styleUrl: './photo-choice-camera.component.scss'
})
export class PhotoChoiceCameraComponent {
  private trigger: Subject<any> = new Subject();
  public webcamImage: WebcamImage | undefined;
  public fileToUpload!: File | null;
  public inCaptureMode = true;
  public inPreviewMode = false;
  public previewSrc = '';

  constructor(private dialogRef: DynamicDialogRef<PhotoChoiceCameraComponent>) {
  }

  captureImage($event: WebcamImage) {
    this.inCaptureMode = false;
    this.inPreviewMode = true;
    this.webcamImage = $event;

    const binaryData = atob(this.webcamImage.imageAsBase64);
    this.previewSrc = this.webcamImage.imageAsDataUrl;

    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array]) as BlobPart;

    this.fileToUpload = new File([blob], "Upload" + new Date().toISOString() + '.jpg', {type: 'image/jpeg'});
  }

  public get triggerAsObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  initCapture($event: MouseEvent) {
    this.trigger.next(0);
  }

  saveImage($event: MouseEvent) {
    this.dialogRef.close(this.fileToUpload);
  }

  cancelImage() {
    this.inPreviewMode = false;
    this.inCaptureMode = true;
    this.fileToUpload = null;
    this.previewSrc = '';
  }
}
