import {Component} from '@angular/core';
import {PhotoChoiceComponent} from "../../../shared/components/photo-choice/photo-choice.component";
import {HomeCarousel} from "../../home/home-carousel";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {FileService} from "../../../shared/services/file.service";
import {CarouselService} from "../../../shared/services/carousel.service";
import {MessageService} from "primeng/api";
import {ImageModule} from "primeng/image";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";
import {
  AdminHomePageCarouselImagePreviewComponent
} from "./admin-home-page-carousel-image-preview/admin-home-page-carousel-image-preview.component";

@Component({
  selector: 'bop-admin-home-page-carousel',
  standalone: true,
  imports: [
    PhotoChoiceComponent,
    FormWrapperComponent,
    ImageModule,
    FileUrlPipe,
    AdminHomePageCarouselImagePreviewComponent
  ],
  templateUrl: './admin-home-page-carousel.component.html',
  styleUrl: './admin-home-page-carousel.component.scss'
})
export class AdminHomePageCarouselComponent {
  public carouselImages: HomeCarousel = new HomeCarousel();

  constructor(private fileService: FileService,
              private carouselService: CarouselService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.getImages();
  }

  getImages() {
    this.carouselService.getCarouselImages()
      .subscribe({
        next: images => this.carouselImages = images
      })
  }

  saveImage(file: File) {
    // the id should be 8 so the image is associated with the carousel
    this.fileService.uploadFile(file, 9)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешно добавяне на системно съобщение!',
            detail: 'Можете да го видите в началната страница.'
          })

          this.getImages();
        },
        error: () => this.messageService.add({
          severity: 'error',
          summary: 'Възникна грешка!',
          detail: 'За съжаление възникна грешка. Моля, опитайте отново.'
        })
      })
  }

  deleteImage(id: number) {
    this.carouselService.deleteCarouselImages([id])
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешно изтриване на снимката!',
            detail: 'Тя няма да бъде показвана повече.'
          })

          this.getImages();
        },
        error: () => this.messageService.add({
          severity: 'error',
          summary: 'Възникна грешка!',
          detail: 'За съжаление възникна грешка. Моля, опитайте отново.'
        })
      })
  }
}
