import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {VersionResponse} from "../models/version.response";

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(
    private http: HttpClient
  ) {
  }

  getVersion(): Observable<VersionResponse> {
    return this.http.get<VersionResponse>("/assets/version.json");
  }
}
