import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ThemeSwitcher} from "./shared/services/theme-switcher";
import {Theme} from "./shared/models/theme.type";
import {MenuItem, MenuItemCommandEvent, MessageService, PrimeIcons} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {SidebarComponent} from "./shared/components/sidebar/sidebar.component";
import {HeaderComponent} from "./shared/components/header/header.component";

import {AsyncPipe} from "@angular/common";
import {LayoutStore} from "./shared/store/layout/layout.store";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "./public/authentication/store/authentication.state";
import {selectLoggedIn} from "./public/authentication/store/authentication.reducer";
import {logout} from "./public/authentication/store/authentication.actions";
import {VersionService} from "./shared/services/version.service";
import {DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry, NgIdleModule} from "@ng-idle/core";
import {ProfileState} from "./private/profile/store/profile.state";
import {DialogService} from "primeng/dynamicdialog";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {getMyProfile} from "./private/profile/store/profile.actions";
import {selectCurrentUser} from "./private/profile/store/profile.reducer";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, SidebarComponent, HeaderComponent, AsyncPipe, NgIdleModule, NgIdleKeepaliveModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  readonly themes = inject(ThemeSwitcher);
  readonly layoutStore = inject(LayoutStore);

  public theme = this.themes.fromLastChoice();

  public version$ = this.versionService.getVersion();

  constructor(private idle: Idle,
              private store: Store<ProfileState>,
              private versionService: VersionService) {
  }

  ngOnInit(): void {
    this.setDeviceType();
    this.setSidebarExpanded(true);
    this.setLogoutPeriod();
  }

  public setDeviceType() {
    if (window.innerWidth < 776) {
      this.setSidebarExpanded(false);
      sessionStorage.setItem('isMobile', 'true');
      return;
    }
  }

  public setSidebarExpanded($event: boolean) {
    this.layoutStore.setSidebarExpanded($event);
  }

  public setLogoutPeriod() {
    this.store.select(selectCurrentUser)
      .subscribe(user => {
        if (!user?.logoutPeriod) {
          this.idle.setIdle(600);
          return;
        }

        this.idle.setIdle(user?.logoutPeriod * 60);
      })

    this.idle.setIdle(600);
    this.idle.setTimeout(30);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    this.idle.onTimeout.subscribe(() => {
      this.store.dispatch(logout())
    });


    this.idle.onTimeoutWarning.subscribe((countdown) => {
      // Maybe add a warning popup here someday
    });

    this.idle.watch();
  }

  switchTheme(theme: Theme) {
    this.themes.switchTo(theme);
    this.theme = theme;
  }
}
