import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {WishDto} from "../wish.dto";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../../store/profile.state";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'bop-profile-edit-wishes-table',
  standalone: true,
  imports: [
    ButtonModule,
    SharedModule,
    TableModule,
    InputTextModule,
    FormsModule,
    TooltipModule
  ],
  templateUrl: './profile-edit-wishes-table.component.html',
  styleUrl: './profile-edit-wishes-table.component.scss'
})
export class ProfileEditWishesTableComponent implements OnChanges {
  @Input() public header: string | undefined;
  @Input() public data: WishDto[] = [];

  @Output() public wishSaved = new EventEmitter<WishDto[]>();

  wishes: WishDto[] = [];
  editableWishes: { [id: number]: string } = {};

  constructor(private store: Store<ProfileState>) {
  }

  ngOnChanges() {
    this.wishes = this.data.map(x => Object.assign({}, x)).sort((a, b) => a.id - b.id);

    if (Object.values(this.editableWishes).filter(v => v !== '').length > 0) {
      return;
    }

    this.wishes.forEach(w => this.editableWishes[w.id] = w.name);
  }

  onWishSaved(id: number) {
    this.wishes = this.wishes.filter(w => w.id !== id);
    this.wishes.push({id: id, name: this.editableWishes[id], isDraft: true});

    this.wishSaved.emit(this.wishes);
  }
}
