import {BehaviorSubject, Observable} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {FilterMetadata} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {PageableResponse} from "../models/pageable/pageable.response";
import {
  filterFromRequest,
  Filtration,
  FiltrationRequestParam,
  InvokableFiltration
} from "../models/filtration/filtration";
import {PageableRequest} from "../models/pageable/pageable.request";
import {FilterRequest} from "../models/filtration/filter.request";

export class DataGridTrait<T> {
  _loading = true;
  pagedData: PageableResponse<T> = PageableResponse.empty()
  filters: { [key: string]: FilterMetadata } = {}

  private loading$ = new BehaviorSubject(this._loading);

  private filter: InvokableFiltration = {
    do(): FiltrationRequestParam {
      return new FiltrationRequestParam('filter', '[]')
    }
  };
  private page: PageableRequest = new PageableRequest();

  constructor(
    private fetchFunction: (filter: InvokableFiltration, page: PageableRequest) => Observable<PageableResponse<T>>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      const filter = parseFilter(params);
      const page = params['page'] || 0;
      const size = params['size'] || 500;
      const sort = params['sort'] || '';
      const direction = +params['direction'] || 0;
      this.filter = filterFromRequest(filter);
      this.page = new PageableRequest(page, size, sort ? {column: sort, isAscending: direction === 1} : undefined)

      filter.forEach(req =>
        this.filters[req.field] = {value: req.value, matchMode: req.operation.toString(), operator: 'and'}
      );

      this.refetch();
    })
  }

  public loadData($event: TableLazyLoadEvent) {
    let computed = new Filtration().field('').equals('');
    let hasFilter = false;

    const filters = $event.filters;

    Object.keys(filters as {}).forEach(column => {
      hasFilter = true;

      const value = (filters![column]! as FilterMetadata).value;
      const beDoing = (filters![column]! as FilterMetadata).matchMode;

      computed = computed.and(column).should(beDoing || 'CONTAINS', value);
    });

    const page = $event.first! / $event.rows!;
    const size = $event.rows!;

    const params: { [key: string]: any } = {};
    params['page'] = page === undefined ? this.route.snapshot.queryParams['page'] : page;
    params['size'] = size === undefined ? this.route.snapshot.queryParams['size'] : size;
    params['sort'] = $event.sortField || this.route.snapshot.queryParams['sort'];
    params['direction'] = $event.sortOrder || this.route.snapshot.queryParams['direction'];
    params['filter'] = hasFilter ? JSON.stringify(computed.do().value) : this.route.snapshot.queryParams['filter']

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
    });
  }

  public refetch() {
    this.loading = true;
    this.fetchFunction(this.filter, this.page).subscribe({
      next: data => {
        this.pagedData = data;
        this.loading = false;
      },
      error: err => {
        console.error('Error while fetching data for table', err);
        this.loading = false;
      }
    });
  }

  private set loading(loading: boolean) {
    this._loading = loading;
    this.loading$.next(this._loading);
  }

  onLoadingChange(): Observable<boolean> {
    return this.loading$;
  }

  public addFilter(params: Filtration) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge'
    })
  }

  firstPageElement(): number {
    return (this.page.currentPage || 0) * (this.page.pageSize || 0);
  }

  sortField(): string {
    return this.page.sort?.column || '';
  }

  sortDirection(): number {
    return this.page.sort?.isAscending ? 1 : -1;
  }

  pageSize(): number {
    return this.page.pageSize || 25;
  }

  filterFor(field: string) {
    const filter: FilterRequest[] = parseFilter(this.route.snapshot.queryParams)
    return filter.find(f => f.field === field)?.value;
  }

}

function parseFilter(params: { [key: string]: string }): FilterRequest[] {
  return JSON.parse(JSON.parse(params['filter'] || '"[]"'));
}
