import {createAction, props} from "@ngrx/store";
import {AuthenticationRequest} from "../authentication.request";
import {AuthenticationResponse} from "../authentication.response";

export const login = createAction(
  "[Login] Send login request",
  props<{ request: AuthenticationRequest }>()
)

export const loginSuccess = createAction(
  "[Login_Success] Received login success response",
  props<{ response: AuthenticationResponse, message: string }>()
);

export const loginError = createAction(
  "[Login_Error] Received login error response",
  props<{ error: string }>()
)

export const logout = createAction(
  "[Logout] Logout",
  props
)

export const logoutSuccess = createAction(
  "[Logout_Success] Logout",
  props
)

