import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ProfileResponse} from "../../../profile/profile.response";
import {TrusteeSelectionComponent} from "../../../../shared/components/trustee-selection/trustee-selection.component";
import {
  AcceptRejectButtonsComponent
} from "../../../../shared/components/accept-reject-buttons/accept-reject-buttons.component";

@Component({
  selector: 'bop-donation-trustees-change-trustee',
  standalone: true,
  imports: [
    ButtonModule,
    TrusteeSelectionComponent,
    AcceptRejectButtonsComponent
  ],
  templateUrl: './donation-trustees-change-trustee.component.html',
  styleUrl: './donation-trustees-change-trustee.component.scss'
})
export class DonationTrusteesChangeTrusteeComponent {
  public selectedTrusteeToDonateTo: ProfileResponse | null = null;

  constructor(private dialogRef: DynamicDialogRef<DonationTrusteesChangeTrusteeComponent>) {
  }


  onConfirm() {
    this.dialogRef.close(this.selectedTrusteeToDonateTo);
  }

  onDismiss() {
    this.dialogRef.close();
  }

  selectTrustee($event: ProfileResponse) {
    this.selectedTrusteeToDonateTo = $event;
  }
}
