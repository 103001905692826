import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noCyrillicValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cyrillicPattern = /[\u0400-\u04FF]/;
    if (control.value && cyrillicPattern.test(control.value)) {
      return { 'cyrillicNotAllowed': true };
    }
    return null;
  };
}
