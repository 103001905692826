import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuModule} from "primeng/menu";
import {DividerModule} from "primeng/divider";
import {UserStatisticsComponent} from "../../../../private/profile/user-statistics/user-statistics.component";
import {MenuItem} from "primeng/api";
import {StatisticsResponse} from "../../../models/statistics.response";
import {ButtonModule} from "primeng/button";
import {Theme, ThemeType} from "../../../models/theme.type";

@Component({
  selector: 'bop-header-profile-statistics',
  standalone: true,
  imports: [
    MenuModule,
    DividerModule,
    UserStatisticsComponent,
    ButtonModule
  ],
  templateUrl: './header-profile-statistics.component.html',
  styleUrl: './header-profile-statistics.component.scss'
})
export class HeaderProfileStatisticsComponent {
  @Input() profileItems: MenuItem[] | undefined;
  @Input() profileMeta: StatisticsResponse | undefined;
  @Input() theme: Theme = ThemeType.LIGHT();
  @Output() themeChange: EventEmitter<Theme> = new EventEmitter();
}
