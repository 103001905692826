<bop-form-wrapper>
  <form [formGroup]="donationForm" class="flex flex-column gap-3">
    <h1 class="text-2xl">Създаване на дарение</h1>

    <p-button icon="pi pi-receipt" label="Правила на дарение" (onClick)="openDonationTermsDialog()"></p-button>

    @if (!donationForBop) {
      <bop-form-row-wrapper>
        <div class="w-full md:w-4"></div>
        <bop-donations-create-number-control icon="pi pi-user" class="w-full md:w-4">
          <bop-trustee-selection class="w-full"
                                 (profileSelectedEmitter)="onReceiverSelect($event)"></bop-trustee-selection>
        </bop-donations-create-number-control>
        <div class="w-full md:w-4"></div>
      </bop-form-row-wrapper>

      @if (!receiverSelected) {
        <small class="text-red-600">Полето за получател е задължително.</small>
      }
    }

    <bop-form-row-wrapper>
      <div class="w-full md:w-4"></div>
      <div class="w-full md:w-4 text-left">
        @if (donationForBop && hasBopObligations) {
          <p class="text-yellow-400">Погасявате задълженията си към БОП</p>
        } @else if (donationForBop && !hasBopObligations) {
          <p class="text-yellow-400">Доброволно дарение към БОП</p>
        }

        <p-checkbox label="ДАРът е към БОП"
                    [binary]="true"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="toggleDonationsForBop()"
                    [(ngModel)]="checkboxTracker">
        </p-checkbox>

      </div>
      <div class="w-full md:w-4"></div>
    </bop-form-row-wrapper>

    <bop-form-row-wrapper>
      <bop-donations-create-number-control label="Членска встъпителна вноска (12+ лв., 1 лв./месец)" icon="pi pi-money-bill
"
                                           class="w-full md:w-4">
        <p-inputNumber formControlName="membershipFee" [showButtons]="true" mode="currency" currency="BGN"
                       min="0"></p-inputNumber>
      </bop-donations-create-number-control>

      <bop-donations-create-number-control label="Гаранция БГ произход" icon="pi pi-money-bill" class="w-full md:w-4">
        <p-inputNumber formControlName="originGuaranty" [showButtons]="true" mode="currency" currency="BGN"
                       min="0"></p-inputNumber>
      </bop-donations-create-number-control>

      <bop-donations-create-number-control label="Гаранция БГ качество" icon="pi pi-money-bill" class="w-full md:w-4">
        <p-inputNumber formControlName="qualityGuaranty" [showButtons]="true" mode="currency" currency="BGN"
                       min="0"></p-inputNumber>
      </bop-donations-create-number-control>
    </bop-form-row-wrapper>

    <bop-form-row-wrapper>
      <bop-donations-create-number-control label="Инвестиционен дар"
                                           pTooltip="Закупуването на 10 инвестиционни Дара Ви включва в екипа на ИИФ: Инвестиционен и иновационен фонд на БОП.
                                                     ИИФ взима решенията за придобиване на материални активи в БОП (ДМА).
                                                     Вие предлагате и гласувате в ИИФ с броя на дяловете си.
                                                     За всеки 10 лв ДАР в това поле Вие получавате
                                                     1 кооперативен дял и 10 ДАРа: БОП бонове за вътрешно-кооператвни покупки равностойни на 10 лева."
                                           icon="pi pi-money-bill" class="w-full md:w-4">
        <p-inputNumber formControlName="investment" [showButtons]="true" mode="currency" currency="BGN"
                       min="0"></p-inputNumber>
      </bop-donations-create-number-control>

      <bop-donations-create-number-control label="Дялова вноска (10 лв.)"
                                           pTooltip="Талоните са вътрешно кооперативно средство за обмен на продукти (стоки, услуги и технологии)."
                                           icon="pi pi-money-bill" class="w-full md:w-4">
        <p-inputNumber formControlName="buyBop" [showButtons]="true" mode="currency" currency="BGN"
                       min="0"></p-inputNumber>
      </bop-donations-create-number-control>

      <div class="flex flex-column w-full h-full justify-content-between w-full md:w-4">
        <span class="text-xs text-primary-600 mb-2 text-left">Дар</span>
        <div class="flex justify-content-between">
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-money-bill"></i>
            </p-inputGroupAddon>
            <p-inputNumber formControlName="other" [showButtons]="true" min="0"></p-inputNumber>
          </p-inputGroup>
          <p-dropdown [formControl]="donationForm.controls.otherDonationType"
                      [options]="otherDonationTypes"
                      optionLabel="type"
                      optionValue="id"></p-dropdown>
        </div>
      </div>
    </bop-form-row-wrapper>

    <bop-form-row-wrapper>
      <div class="spacer hidden md:block md:w-4"></div>

      <div class="flex flex-column w-full h-full justify-content-between w-full md:w-4">
        <span class="text-xs text-primary-600 mb-2 text-left">Друг дар</span>
        <div class="flex justify-content-between">
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-pencil"></i>
            </p-inputGroupAddon>
            <p-inputNumber formControlName="otherInfo" [showButtons]="true" min="0"></p-inputNumber>
          </p-inputGroup>
          <p-dropdown [formControl]="donationForm.controls.otherDonationInfoType"
                      [options]="otherDonationTypes"
                      optionLabel="type"
                      optionValue="id"></p-dropdown>
        </div>

        <div class="grid mt-2">
          @for (type of otherInfoTypes; track type) {
            <div class="field-checkbox col-6">
              <p-checkbox
                [formControl]="donationForm.controls['otherInfoType']"
                [label]="type.type"
                name="group"
                [value]="type.id"/>
            </div>
          }
        </div>
      </div>

      <div class="spacer hidden md:block md:w-4"></div>

    </bop-form-row-wrapper>

    <bop-form-row-wrapper>
      <div class="spacer hidden md:block md:w-4"></div>
      <bop-donations-create-number-control label="Пояснение към дарението" icon="pi pi-pencil" class="w-full md:w-4">
        <input pInputText [formControl]="donationForm.controls.donationInfo"/>
      </bop-donations-create-number-control>
      <div class="spacer hidden md:block md:w-4"></div>
    </bop-form-row-wrapper>

    <bop-photo-choice photoButtonText="Снимай пояснение"
                      (fileSelectedEmitter)="setOtherInfoImageFile($event)"></bop-photo-choice>

    <p-panel header="За ваше улеснение можете да платите чрез:">
      <div class="flex flex-column md:flex-row justify-content-between gap-3 mb-3">
        <div class="flex flex-column w-full md:w-6 align-items-start gap-0 p-2 border-1 border-round-md">
          <div><span class="text-primary-400 font-bold">Easy Pay</span></div>
          <div>IBAN: BG16 ESPY 4004 0040 6258 81</div>
          <div>BIC: ESPYBGS1</div>
        </div>

        <div class="flex flex-column w-full md:w-6 align-items-start gap-0 p-2 border-1 border-round-md">
          <div><span class="text-primary-400 font-bold">Банков път</span></div>
          <div>IBAN: BG10 BPBI 7940 1093 5393 01</div>
          <div>BIC: BPBIBGSF</div>
        </div>
      </div>

      <span class="font-italic">Или да използвате някоя от другите налични опции</span>
    </p-panel>

    <bop-form-row-wrapper>
      <div class="w-full md:w-4"></div>
      <bop-donations-create-number-control label="Начин на плащане" icon="pi pi-credit-card" class="w-full md:w-4">
        <p-dropdown [style]="{width: '100%'}"
                    [class.ng-invalid]="donationForm.get('paymentMethod')?.invalid"
                    [class.ng-dirty]="donationForm.get('paymentMethod')?.invalid"
                    formControlName="paymentMethod"
                    placeholder="Начин на плащане"
                    [options]="paymentMethods"
                    optionLabel="name"
                    optionValue="id"
                    align="left">
        </p-dropdown>
      </bop-donations-create-number-control>
      <div class="w-full md:w-4"></div>
    </bop-form-row-wrapper>
    @if (donationForm.get('paymentMethod')?.invalid) {
      <small class="text-red-600">Изборът на метод за плащане е задължителен.</small>
    }

    <p class="text-primary-400 font-bold">Обща сума на дарението: <span class="text-2xl">{{ calculateSumAll() }}
      лв.</span></p>

    @if (donationIdResponse?.annualStringId) {
      <p class="text-yellow-500 font-bold text-2xl">Служебен номер на дарение: <span
        class="text-3xl">{{ donationIdResponse?.annualStringId }}</span></p>
    }

    <bop-form-row-wrapper>
      <div class="spacer hidden md:block md:w-4"></div>
      <bop-donations-create-number-control label="Потвърждаване на сумата" icon="pi pi-money-bill"
                                           class="w-full md:w-4">
        <p-inputNumber
          formControlName="sumAllConfirm"
          [class.ng-invalid]="donationForm.get('sumAllConfirm')?.invalid"
          [class.ng-dirty]="donationForm.get('sumAllConfirm')?.invalid"
          [showButtons]="true"
          min="0"></p-inputNumber>
      </bop-donations-create-number-control>
      <div class="spacer hidden md:block md:w-4"></div>
    </bop-form-row-wrapper>
    @if (donationForm.get('sumAllConfirm')?.invalid) {
      <small class="text-red-600">Потвърдената сума трябва да бъде равна на общата заявена сума.</small>
    }

    <bop-photo-choice photoButtonText="Снимай платежно"
                      (fileSelectedEmitter)="setPaymentImageFile($event)"
                      pTooltip="Моля при възможност да запишете на документа служебният номер на дарението, даден по-горе в жълт цвят."
                      tooltipPosition="top"
    ></bop-photo-choice>

    <p-button (click)="saveDonation()" [disabled]="donationForm.invalid" icon="pi pi-save"
              label="Изпращане на дарение"></p-button>
  </form>
</bop-form-wrapper>

<bop-spinner-overlay [loading]="loading">
  Работим за финализиране на Вашето ДАРение
</bop-spinner-overlay>
