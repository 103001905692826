import { Component } from '@angular/core';

@Component({
  selector: 'bop-market-search-transportations',
  standalone: true,
  imports: [],
  templateUrl: './market-search-transportations.component.html',
  styleUrl: './market-search-transportations.component.scss'
})
export class MarketSearchTransportationsComponent {

}
