import {Pipe, PipeTransform} from '@angular/core';
import {DonationResponse} from "../../private/donations/donation.response";

@Pipe({
  name: 'donationType',
  standalone: true
})
export class DonationTypePipe implements PipeTransform {

  types: any =
    {
      membershipFee: 'Членски внос',
      originGuaranty: 'Гаранция БГ произход',
      qualityGuaranty: 'Гаранция за качество',
      investment: 'Инвестиционен дар',
      buyBop: 'Талони',
      other: 'Друго'
    }

  transform(value: string): string {
    return this.types[value] || 'Друго';
  }
}
