<div class="flex flex-column justify-content-center align-items-between gap-2">
  <p-image
    class="w-full"
    [src]="url | fileUrl"
    [preview]="true"
    height="250"
    alt="Image">
  </p-image>
  <p-button icon="pi pi-times" severity="danger" label="Изтриване" (onClick)="onDelete.emit()"></p-button>
</div>
