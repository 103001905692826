import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {Theme, ThemeType} from "../models/theme.type";

const THEME_KEY = '__THEME__';

@Injectable({providedIn: 'root'})
export class ThemeSwitcher {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  switchTo(theme: Theme) {
    const themeLink = this.document.querySelector("#app-theme") as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = theme.file + '.css';
      localStorage.setItem(THEME_KEY, theme.key);
    }
  }

  fromLastChoice(): Theme {
    const theme = localStorage.getItem(THEME_KEY)
      ? (ThemeType as any)[localStorage.getItem(THEME_KEY)!]()
      : ThemeType.LIGHT();

    this.switchTo(theme);

    return theme;
  }
}
