@if (profileItems?.length) {
  <p-menubar [model]="menuItems" class="fixed z-5 w-full">
    <ng-template pTemplate="start">
      <p-button class="md:hidden" icon="pi pi-angle-right" [rounded]="true" [text]="true"
                (click)="this.sidebarVisibility.emit(true)"></p-button>

      <bop-header-profile-statistics
        [profileMeta]="profileMeta"
        [profileItems]="profileItems"
        [theme]="theme"
        (themeChange)="themeChange.emit($event)">
      </bop-header-profile-statistics>
    </ng-template>
  </p-menubar>

  <section class="spacer w-full h-4rem"></section>
}

