import {Component, Input} from '@angular/core';
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {ProfileEditWishesTableComponent} from "./profile-edit-wishes-table/profile-edit-wishes-table.component";
import {FormGroup} from "@angular/forms";
import {WishPackage} from "./wish.package";
import {WishDto} from "./wish.dto";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../store/profile.state";
import {
  setSelectedWishes, setSelectedWishesBopSoftware,
  setSelectedWishesWantToBuy,
  setSelectedWishesWantToExchange,
  setSelectedWishesWantToSell
} from "../../store/profile.actions";

@Component({
  selector: 'bop-profile-edit-wishes',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    ProfileEditWishesTableComponent
  ],
  templateUrl: './profile-edit-wishes.component.html',
  styleUrl: './profile-edit-wishes.component.scss'
})
export class ProfileEditWishesComponent {
  @Input() public wishPackage!: WishPackage;

  constructor(private store: Store<ProfileState>) {
  }

  updateWantToBuy($event: WishDto[]) {
    this.store.dispatch(setSelectedWishesWantToBuy({wishes: $event}))
  }

  updateWantToSell($event: WishDto[]) {
    this.store.dispatch(setSelectedWishesWantToSell({wishes: $event}))
  }

  updateWantToExchange($event: WishDto[]) {
    this.store.dispatch(setSelectedWishesWantToExchange({wishes: $event}))
  }

  updateBopSoftware($event: WishDto[]) {
    this.store.dispatch(setSelectedWishesBopSoftware({wishes: $event}))
  }
}
