import {MunicipalityResponse} from "./municipality.response";

export class SettlementResponse {
  constructor(
    public id: number = 0,
    public name: string = '',
    public municipality: MunicipalityResponse = new MunicipalityResponse()
  ) {

  }
}

