import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {PaginatorModule} from "primeng/paginator";

@Component({
  selector: 'bop-profile-edit-notification-period',
  standalone: true,
  imports: [
    InputNumberModule,
    PaginatorModule,
    ReactiveFormsModule
  ],
  templateUrl: './profile-edit-notification-period.component.html',
  styleUrl: './profile-edit-notification-period.component.scss'
})
export class ProfileEditNotificationPeriodComponent {
  @Input() public dataForm!: FormGroup;

  public notificationOptions = [
    {id: 1, name: 'ежеседмично'},
    {id: 2, name: 'ежемесечно'},
    {id: 3, name: 'при всяка промяна'}
  ];

  ngOnInit() {
    console.log(this.dataForm);
  }
}
