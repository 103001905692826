<form [formGroup]="addressForm" (ngSubmit)="saveAddress()" class="flex flex-column gap-3 align-items-center">
  <bop-settlement-selection [municipalityName]="initialMunicipalityName" [settlementName]="initialSettlementName"
                            (onSettlementSelected)="onSettlementSelect($event)"></bop-settlement-selection>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <input pInputText placeholder="Адрес" formControlName="address">
  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <input pInputText placeholder="Допълнение към адреса" formControlName="comment">
  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map-marker"></i>
    </p-inputGroupAddon>
    <input pInputText placeholder="Координати" formControlName="coordinates">
  </p-inputGroup>

  <section class="w-full flex flex-column justify-content-start gap-3">
    <p>За роля:</p>
    @for (role of availableRoles$  | async; track role; let i = $index) {
      <p-checkbox
        [ngModelOptions]="{standalone: true}"
        [label]="role.name"
        [value]="role.id"
        [(ngModel)]="selectedRoleIds"
      ></p-checkbox>
    }
  </section>

  <section
    pTooltip="Ако в момента нямате възможност да качите снимка на адреса, след запазване, ще може автоматично да ви бъде генериран уникален линк, чрез който ваш близък да качи снимката."
    class="w-full flex flex-column justify-content-start gap-3">
    <p>Актуална снимка на адреса:</p>
  </section>

  <bop-photo-choice photoButtonText="Снимка на локация" [previewSrc]="oldImageSrc | fileUrl"
                    (fileSelectedEmitter)="setFile($event)"></bop-photo-choice>

  @if (link) {
    <section
      pTooltip="Ако в момента не се намирате на адреса, можете да споделите с трети човек този линк за еднократно заснемане и качване. Линкът не дава достъп до профила Ви в БОП."
      class="w-full flex flex-column justify-content-start gap-3">
      <p>Линк за качване на снимка:</p>
      <p-button icon="pi pi-clipboard" iconPos="right" [label]="link" [raised]="true" (onClick)="copyLink()"
                severity="secondary"></p-button>
    </section>

    @if (copied) {
      <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
    }

  }
  <p-button type="submit" label="Запази адрес" icon="pi pi-save"></p-button>
</form>
