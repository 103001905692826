import {SortEventRequest} from "../filtration/sort-event.request";

export class PageableRequest {
  constructor(
    public currentPage?: number,
    public pageSize?: number,
    public sort?: SortEventRequest
  ) {
  }

  static first(): PageableRequest {
    return new PageableRequest(0, 100);
  }

  static all() {
    return new PageableRequest(0, 2000)
  }

  withSort(sort: SortEventRequest) {
    this.sort = sort;

    return this;
  }
}
