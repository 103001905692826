import {PageableResponse} from "./pageable.response";
import {map, Observable} from "rxjs";

export function reindexPageable<T>(field: string = 'id'): (source: Observable<PageableResponse<T>>) => Observable<PageableResponse<T>> {
  return function (source: Observable<PageableResponse<T>>): Observable<PageableResponse<T>> {
    return source.pipe(
      map(pageable => new PageableResponse<T>(
        pageable.number,
        pageable.size,
        pageable.totalElements,
        pageable.content,
        (pageable.content || []).reduce((acc, obj: any) => acc.set(obj[field], obj), new Map())
      ))
    );
  };
}
