import { Component, OnInit } from '@angular/core';
import {AdminService} from "../admin.service";
import { MessageService } from 'primeng/api';
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {InputGroupModule} from "primeng/inputgroup";

@Component({
  selector: 'cooperators-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    InputGroupModule,
    FormWrapperComponent
  ],
  templateUrl: './admin-cooperators-info.component.html'
})
export class AdminCooperatorsInfoComponent implements OnInit {
  phoneNumber: string = '';
  supportEmail: string = '';

  constructor(private adminService: AdminService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.loadCooperatorsInfo();
  }

  loadCooperatorsInfo(): void {
    this.adminService.getCooperatorsInfo().subscribe({
      next: data => {
        this.phoneNumber = data.phoneNumber;
        this.supportEmail = data.email;
      },
      error: error => {
        console.error('Error loading cooperator info', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Грешка',
          detail: 'Грешка при зареждане на контактната информация.'
        });
      }
    });
  }

  updateSettings(): void {
    const updatedInfo = {
      phoneNumber: this.phoneNumber,
      email: this.supportEmail
    };

    this.adminService.updateCooperatorsInfo(updatedInfo).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Успешно запазване',
          detail: 'Контактната информация беше успешно запазена.'
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Грешка',
          detail: 'Грешка при запазване на контактната информация.'
        });
      }
    });
  }
}
