<p-editor [(ngModel)]="model.text" [style]="{ height: '400px' }">
  <p-header>
          <span class="ql-formats">
            <select class="ql-size">
              <option value="small">Small</option>
              <option selected></option>
              <option value="large">Large</option>
              <option value="huge">Huge</option>
            </select>
          </span>
    <span class="ql-formats">
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-italic" aria-label="Italic"></button>
            <button class="ql-underline" aria-label="Underline"></button>
            <button class="ql-strike" aria-label="Strike"></button>
          </span>
    <span class="ql-formats">
            <select title="Text Color" class="ql-color">
              <option value="#10b981" label="#10b981"></option>
              <option value="#eab308" label="#eab308"></option>
              <option value="#ff3d32" label="#ff3d32"></option>
            </select>
            <span class="ql-format-separator"></span>
            <select title="Background Color" class="ql-background">
              <option value="#10b981" label="#10b981"></option>
              <option value="#eab308" label="#eab308"></option>
              <option value="#ff3d32" label="#ff3d32"></option>
            </select>
        </span>
    <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select title="Text Alignment" class="ql-align">
              <option selected>Gauche</option>
              <option value="center" label="Центрирано"></option>
              <option value="right" label="Изравнено в дясно"></option>
              <option value="justify" label="Уеднаквено"></option>
            </select>
          </span>
  </p-header>
</p-editor>
