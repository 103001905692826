import {Component} from '@angular/core';
import {CarouselService} from "../../services/carousel.service";
import {HomeCarousel} from "../../../private/home/home-carousel";
import {interval, Subscription} from "rxjs";
import {FileUrlPipe} from "../../pipes/file-url.pipe";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'bop-dynamic-bg-image-wrapper',
  standalone: true,
  imports: [
    NgStyle
  ],
  providers: [FileUrlPipe],
  templateUrl: './dynamic-bg-image-wrapper.component.html',
  styleUrl: './dynamic-bg-image-wrapper.component.scss'
})
export class DynamicBgImageWrapperComponent {
  public carouselImages: string[] = [];
  currentImageIndex: number = 0;
  nextImageIndex: number = 1;
  nextImageVisible: boolean = false;
  intervalSubscription!: Subscription;

  constructor(
    private carouselService: CarouselService,
    private fileUrlPipe: FileUrlPipe
  ) {
  }

  public ngOnInit(): void {
    this.carouselService.getCarouselImages()
      .subscribe({
        next: (images) => {
          this.carouselImages = images.carouselImages.map(i => this.fileUrlPipe.transform(i.url));
          this.startImageRotation();
        }
      })
  }

  startImageRotation(): void {
    this.intervalSubscription = interval(5000).subscribe(() => {
      this.nextImageIndex = (this.currentImageIndex + 1) % this.carouselImages.length;
      this.nextImageVisible = true;
      setTimeout(() => {
        this.currentImageIndex = this.nextImageIndex;
        this.nextImageVisible = false;
      }, 1000);
    });
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  get currentBackgroundImage(): string {
    return this.carouselImages[this.currentImageIndex];
  }

  get nextBackgroundImage(): string {
    return this.carouselImages[this.nextImageIndex];
  }
}
