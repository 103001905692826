import {Component, inject} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {MenuItem, PrimeIcons} from "primeng/api";

@Component({
  selector: 'bop-admin',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent {
  readonly layoutStore = inject(LayoutStore);

  private adminItems: MenuItem[] = [
    {
      icon: PrimeIcons.HOME,
      label: 'Начална страница',
      items: [
        {
          icon: PrimeIcons.ENVELOPE,
          label: 'Системно съобщение',
          routerLink: ['private', 'admin' ,'home-page', 'system-message']
        },
        {
          icon: PrimeIcons.IMAGES,
          label: 'Изображения',
          routerLink: ['private', 'admin' ,'home-page', 'carousel']
        },
      ]
    },
    {
      icon: PrimeIcons.USERS,
      label: 'ДАРители',
      routerLink: ['private', 'admin', 'users']
    },
    {
      icon: PrimeIcons.GIFT,
      label: 'ДАРeния',
      routerLink: ['private', 'admin' ,'donations'],
    },
    {
      icon: PrimeIcons.ENVELOPE,
      label: 'Мейли',
      routerLink: ['private', 'admin', 'mails'],
    },
    {
      icon: PrimeIcons.BRIEFCASE,
      label: 'Връзка с кооператор',
      routerLink: ['private', 'admin', 'contact-info']
    }
  ]

  ngOnInit(): void {
    this.layoutStore.setSidebarItems(this.adminItems);
    this.layoutStore.setSidebarExpanded(true);
  }

}
