import { Component } from '@angular/core';

@Component({
  selector: 'bop-market-search-service',
  standalone: true,
  imports: [],
  templateUrl: './market-search-service.component.html',
  styleUrl: './market-search-service.component.scss'
})
export class MarketSearchServiceComponent {

}
