import {Component, Input} from "@angular/core";
import {ProgressSpinnerModule} from "primeng/progressspinner";

@Component({
  selector: 'bop-spinner-overlay',
  standalone: true,
  imports: [
    ProgressSpinnerModule
  ],
  templateUrl: './spinner-overlay.html',
  styleUrl: './spinner-overlay.scss'
})
export class SpinnerOverlay {
  @Input() loading: boolean = false;
}
