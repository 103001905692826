import { Component } from '@angular/core';

@Component({
  selector: 'bop-form-row-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './form-row-wrapper.component.html',
  styleUrl: './form-row-wrapper.component.scss'
})
export class FormRowWrapperComponent {

}
