import {Component, Input} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {FormGroup, FormsModule} from "@angular/forms";

@Component({
  selector: 'bop-profile-edit-privacy',
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule
  ],
  templateUrl: './profile-edit-privacy.component.html',
  styleUrl: './profile-edit-privacy.component.scss'
})
export class ProfileEditPrivacyComponent {
  @Input() public dataForm!: FormGroup;
  @Input() public privateProfile: boolean = false;

  populateFormValue() {
    this.dataForm.patchValue({
      'publicProfile': !this.privateProfile
    })
  }
}
