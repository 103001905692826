import {Component, EventEmitter, Output} from '@angular/core';
import {Filtration, InvokableFiltration} from "../../models/filtration/filtration";
import {PageableRequest} from "../../models/pageable/pageable.request";
import {filter, map, Observable} from "rxjs";
import {PageableResponse} from "../../models/pageable/pageable.response";
import {ProfileResponse} from "../../../private/profile/profile.response";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {Store} from "@ngrx/store";
import {DonationsService} from "../../../private/donations/donations.service";
import {selectCurrentUser} from "../../../private/profile/store/profile.reducer";
import {lat2cyr} from "../../services/transliteration.service";
import {AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {SharedModule} from "primeng/api";

@Component({
  selector: 'bop-trustee-selection',
  standalone: true,
  imports: [
    AutoCompleteModule,
    SharedModule,
    ReactiveFormsModule
  ],
  templateUrl: './trustee-selection.component.html',
  styleUrl: './trustee-selection.component.scss'
})
export class TrusteeSelectionComponent {
  @Output()
  public profileSelectedEmitter = new EventEmitter<ProfileResponse>();

  private usersFetchMethod!: (filtration: InvokableFiltration, page: PageableRequest) => Observable<PageableResponse<ProfileResponse>>;
  public userSearchControl = new FormControl('');
  public suggestions: ProfileResponse[] = [];
  public previousSuggestions: ProfileResponse[] = [];

  public selected = false;

  constructor(private store: Store, private donationsService: DonationsService) {
  }

  ngOnInit() {
    this.store.select(selectCurrentUser).subscribe(user => {
      if (user?.admin) {
        this.usersFetchMethod = this.donationsService.getAllTrustees.bind(this.donationsService);
      } else {
        this.usersFetchMethod = this.donationsService.getMyTrustees.bind(this.donationsService);
      }

      this.loadSuggestions();
    });

    this.userSearchControl.valueChanges.pipe(
      filter(value => !!value),
      map(value => value!),
    ).subscribe(value => {
      const searchTerm = this.filterOnClientSide(value);

      if (this.filterUnsuccessful()) {
        this.continueFilteringOnServerSide(searchTerm);
      }
    })
  }

  public loadSuggestions(firstName: string = '') {
    this.usersFetchMethod(new Filtration().field('firstName').contains(firstName), PageableRequest.first().withSort({
      column: 'firstName',
      isAscending: true
    }))
      .subscribe(suggestions => this.previousSuggestions = this.suggestions = suggestions.content.map(p => ({
        ...p,
        fullName: p.firstName + ' ' + p.lastName
      })));
  }

  private continueFilteringOnServerSide(searchTerm: string) {
    this.loadSuggestions(searchTerm);
  }

  private filterUnsuccessful() {
    return this.suggestions.length === 0;
  }

  private filterOnClientSide(value: string): string {
    const val = lat2cyr(value.hasOwnProperty('firstName') ? (value as any as ProfileResponse).firstName?.toLowerCase() : value.toLowerCase());
    if (this.previousSuggestions.length === 0) {
      this.previousSuggestions = this.suggestions.map(x => x);
    }

    this.suggestions = this.previousSuggestions.filter(p => p.firstName.toLowerCase().includes(val));

    return val;
  }

  onReceiverSelect($event: AutoCompleteSelectEvent) {
    this.selected = true;
    this.profileSelectedEmitter.emit($event.value)
  }

}
