import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {AddressCardComponent} from "./address-card/address-card.component";
import {TableModule} from "primeng/table";
import {LocationResponse} from "../../../../shared/models/location/location.response";
import {LocationRequest} from "../../../../shared/models/location/location.request";
import {TooltipModule} from "primeng/tooltip";
import {RolesPreviewComponent} from "../../../../shared/components/roles-preview/roles-preview.component";
import {RoleResponse} from "../../profile.response";

@Component({
  selector: 'bop-profile-edit-addresses',
  standalone: true,
  imports: [
    ButtonModule,
    AddressCardComponent,
    TableModule,
    TooltipModule,
    RolesPreviewComponent
  ],
  templateUrl: './profile-edit-addresses.component.html',
  styleUrl: './profile-edit-addresses.component.scss'
})
export class ProfileEditAddressesComponent {
  @Input() public locations!: LocationResponse[];

  @Output() public addressAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() public addressEdit: EventEmitter<LocationResponse> = new EventEmitter<LocationResponse>();

  getRolesIds(userRoles: RoleResponse[]): number[] {
    return userRoles.map(r => r.roleId);
  }
}


