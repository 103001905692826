import {inject, Injectable} from "@angular/core";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {AuthenticationService} from "../authentication.service";
import {Action, Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {login, loginError, loginSuccess, logout, logoutSuccess} from "./authentication.actions";
import {AuthenticationState} from "./authentication.state";
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {Router} from "@angular/router";
import {getMyProfile} from "../../../private/profile/store/profile.actions";
import {readableStreamLikeToAsyncGenerator} from "rxjs/internal/util/isReadableStreamLike";

@Injectable()
export class AuthenticationEffects {
  readonly layoutStore = inject(LayoutStore);

  constructor(private actions$: Actions,
              private store: Store,
              private router: Router,
              private authenticationService: AuthenticationService) {
  }

  public login = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(({request}) =>
        this.authenticationService.login(request).pipe(
          map(response => loginSuccess({response, message: 'Успешен вход!'})),
          tap(() => {
            localStorage.setItem('loginCredential', request.loginCredential);
            this.router.navigate(['private', 'home'])
          }),
          catchError(error => {
            this.store.dispatch(loginError({error: 'Невалиден телефонен номер или парола.'}));
            return of();
          })
        )
      )
    )
  );

  public logout = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap(() => this.authenticationService.logout().pipe(
          map(() => logoutSuccess()),
          tap(() => this.router.navigate(['public', 'authentication']))
        )
      ),
    )
  )

}
