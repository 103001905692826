<bop-form-wrapper>
  <bop-profile-edit-data [dataForm]="profileDataForm"></bop-profile-edit-data>
  <p-divider></p-divider>
  <bop-profile-edit-roles [dataForm]="profileDataForm"
                          [initiallySelectedRoles]="initiallySelectedRoles"></bop-profile-edit-roles>
  <p-divider></p-divider>
  <bop-profile-edit-addresses [locations]="(selectedAddresses$ | async) || []" (addressAdd)="openAddressFormModal()"
                              (addressEdit)="openAddressFormModal($event)"></bop-profile-edit-addresses>
  <p-divider></p-divider>
  <bop-profile-edit-wishes [wishPackage]="(selectedWishes$ | async)!"></bop-profile-edit-wishes>
  <p-divider></p-divider>
  <bop-profile-edit-privacy [dataForm]="profileDataForm"
                            [privateProfile]="!(profile$ | async)?.publicProfile"></bop-profile-edit-privacy>
  <bop-profile-edit-logout-time [dataForm]="profileDataForm"></bop-profile-edit-logout-time>
  <bop-profile-edit-notification-period [dataForm]="profileDataForm"></bop-profile-edit-notification-period>
  <p-button icon="pi pi-save" label="Запази" (click)="saveProfileData()"></p-button>
</bop-form-wrapper>

<bop-spinner-overlay [loading]="savedLoading">
  Работим по обновяването на профила ви
</bop-spinner-overlay>

<bop-spinner-overlay [loading]="initialDataLoading">
  Зареждане
</bop-spinner-overlay>
