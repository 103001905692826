<form [formGroup]="messageForm" (ngSubmit)="sendMessage()" class="flex flex-column gap-3 align-items-center">

  <bop-rich-editor [model]="richEditorModel"></bop-rich-editor>

  <section>
    <p-checkbox id="notifyByEmail" formControlName="notifyByEmail" [binary]="true"/>

    <label for="notifyByEmail">
      Уведоми по електронната поща (email)
    </label>
  </section>

  <p-button type="submit" label="Изпрати съобщението" icon="pi pi-save"></p-button>
</form>
