import {createAction, props} from "@ngrx/store";
import {ProfileResponse, Role, RoleResponse} from "../profile.response";
import {WishPackage} from "../profile-edit/profile-edit-wishes/wish.package";
import {WishDto} from "../profile-edit/profile-edit-wishes/wish.dto";
import {LocationResponse} from "../../../shared/models/location/location.response";
import {LocationRequest} from "../../../shared/models/location/location.request";
import {ProfileRequest} from "../profile.request";
import {TrusteeTransferResponse} from "../trustee.transfer.response";

export const getMyProfile = createAction(
  "[Profile_Get] Get logged in user's profile",
  props
)

export const getMyProfileSuccess = createAction(
  "[Profile_Get_Success] Get logged in user's profile success",
  props<{ response: ProfileResponse }>()
)

export const updateMyProfile = createAction(
  "[Profile_Update] Update logged in user's profile",
  props<{ request: ProfileRequest }>()
)


export const updateMyProfileSuccess = createAction(
  "[Profile_Update_Success] Update logged in user's profile success",
  props<{ response: ProfileResponse }>()
)

export const updateMyProfileError = createAction(
  "[Profile_Update_Error] Update logged in user's profile error",
  props<{ error: any }>()
)

export const getInvitedProfile = createAction(
  "[Profile_Get_Invited] Get invited user's profile",
  props<{ token: string }>()
)

export const getInvitedProfileSuccess = createAction(
  "[Profile_Get_Invited_Success] Get invited user's profile success",
  props<{ response: ProfileResponse }>()
)

export const getInvitedProfileError = createAction(
  "[Profile_Get_Invited_Error] Get invited user's profile error",
  props<{ error: any }>()
)

export const finishInvitedProfile = createAction(
  "[Profile_Finish_Invited] Finish invited user's profile",
  props<{ request: ProfileRequest, token: string }>()
)

export const finishInvitedProfileSuccess = createAction(
  "[Profile_Finish_Invited_Success] Finish invited user's profile success",
  props<{ response: ProfileResponse }>()
)

export const finishInvitedProfileError = createAction(
  "[Profile_Finish_Invited_Error] Get invited user's profile error",
  props<{ error: any }>()
)


export const setSelectedRole = createAction(
  "[Profile_Edit_SelectRoles_Add] Set currently selected role",
  props<{ role: Role }>()
)

export const removeSelectedRole = createAction(
  "[Profile_Edit_SelectRoles_Remove] Remove currently selected role",
  props<{ role: Role }>()
)

export const setSelectedAddress = createAction(
  "[Profile_Edit_SelectAddress_Add] Set currently selected address",
  props<{ address: LocationResponse }>()
)

export const removeSelectedAddress = createAction(
  "[Profile_Edit_SelectAddress_Remove] Remove currently selected address",
  props<{ address: LocationResponse }>()
)

export const setSelectedWishes = createAction(
  "[Profile_Edit_SelectWishes] Set currently selected wishes",
  props<{ wishes: WishPackage }>()
)

export const setSelectedWishesWantToBuy = createAction(
  "[Profile_Edit_SelectWishes_WantToBuy] Set currently selected wishes want to buy",
  props<{ wishes: WishDto[] }>()
)

export const setSelectedWishesWantToSell = createAction(
  "[Profile_Edit_SelectWishes_WantToSell] Set currently selected wishes want to sell",
  props<{ wishes: WishDto[] }>()
)

export const setSelectedWishesWantToExchange = createAction(
  "[Profile_Edit_SelectWishes_WantToExchange] Set currently selected wishes want to exchange",
  props<{ wishes: WishDto[] }>()
)

export const setSelectedWishesBopSoftware = createAction(
  "[Profile_Edit_SelectWishes_BopSoftware] Set currently selected wishes bop software",
  props<{ wishes: WishDto[] }>()
)

export const getTrusteeTransfer = createAction(
  "[Get_Trustee_Transfer] Get ongoing trustee transfer"
)

export const getTrusteeTransferSuccess = createAction(
  "[Profile_Get_Trustee_Transfer_Success] Get ongoing trustee transfer success",
  props<{ response: TrusteeTransferResponse }>()
);

export const getTrusteeTransferError = createAction(
  "[Profile_Get_Trustee_Transfer_Error] Get ongoing trustee transfer error",
  props<{ error: any }>()
);
