import { Component } from '@angular/core';

@Component({
  selector: 'bop-market-search-used',
  standalone: true,
  imports: [],
  templateUrl: './market-search-used.component.html',
  styleUrl: './market-search-used.component.scss'
})
export class MarketSearchUsedComponent {

}
