<h2>Роли</h2>

<section>
  <section class="flex flex-column justify-content-center gap-3 ">
    @for (role of availableRoles; track role; let i = $index) {
      <p-checkbox [label]="role.name"
                  [binary]="isRoleSelected(role)"
                  [trueValue]="isRoleSelected(role)"
                  [falseValue]="!isRoleSelected(role)"
                  [value]="role.id"
                  [(ngModel)]="initiallySelectedRoles[role.id]"
                  (onChange)="onRoleChanged($event, role)"></p-checkbox>
      @if (isRoleSelected(role)) {
        <input pInputText (keyup)="onRoleReasonAdded($event, role)" [value]="getInitialRoleValue(role)" placeholder="Мога да допринеса в тази роля чрез">
      }
    }
  </section>
</section>

