export const trusteeStatusConfigs: {[id: number]: TrusteeStatusConfig} = {
  1: {name: 'Член', severity: 'success'},
  2: {name: 'За плащане', severity: 'info'},
  3: {name: 'За регистрация', severity: 'primary'},
  4: {name: 'За нова покана', severity: 'danger'},
  5: {name: 'МОЛ', severity: 'warning'},
  6: {name: 'За покана', severity: 'warning'},
}

export interface TrusteeStatusConfig {
  name: string;
  severity: string;
}
