import { Component } from '@angular/core';

@Component({
  selector: 'bop-market-create-food',
  standalone: true,
  imports: [],
  templateUrl: './market-create-food.component.html',
  styleUrl: './market-create-food.component.scss'
})
export class MarketCreateFoodComponent {

}
