export class PageableResponse<T> {
  constructor(
    public number: number,
    public size: number,
    public totalElements: number,
    public content: T[],
    public indexed: Map<any, T> = new Map()
  ) {
  }

  static empty<T>(): PageableResponse<T> {
    return new PageableResponse<T>(0, 0, 0, [], new Map());
  }
}
