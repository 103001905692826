const latinMapping: {[key: string]: string} = {
  a: 'а',
  b: 'б',
  c: 'ц',
  d: 'д',
  e: 'е',
  f: 'ф',
  g: 'г',
  h: 'х',
  i: 'и',
  j: 'й',
  k: 'к',
  l: 'л',
  m: 'м',
  n: 'н',
  o: 'о',
  p: 'п',
  q: 'я',
  r: 'р',
  s: 'с',
  t: 'т',
  u: 'у',
  v: 'ж',
  w: 'в',
  x: 'ь',
  y: 'ъ',
  z: 'з',
  ch: 'ч',
  sh: 'ш',
  sht: 'щ',
  "`": 'ч',
  "|": 'ю',
  '[': 'ш',
  ']': 'щ',
  ya: 'я',
  ju: 'ю',
  yu: 'ю',
  zh: 'ж',

  A: 'А',
  B: 'Б',
  C: 'Ц',
  D: 'Д',
  E: 'Е',
  F: 'Ф',
  G: 'Г',
  H: 'Х',
  I: 'И',
  J: 'Й',
  K: 'К',
  L: 'Л',
  M: 'М',
  N: 'Н',
  O: 'О',
  P: 'П',
  Q: 'Я',
  R: 'Р',
  S: 'С',
  T: 'Т',
  U: 'У',
  V: 'Ж',
  W: 'В',
  X: 'Ь',
  Y: 'Ъ',
  Z: 'З',
  Ch: 'Ч',
  CH: 'Ч',
  Sh: 'Ш',
  SH: 'Ш',
  Sht: 'Щ',
  SHT: 'Щ',
  ShT: 'Щ',
  SHt: 'Щ',
  "~": 'Ч',
  "\\": 'Ю',
  '{': 'Ш',
  '}': 'Щ',
  Ya: 'Я',
  YA: 'Я',
  Ju: 'Ю',
  JU: 'Ю',
  Yu: 'Ю',
  YU: 'Ю',
  Zh: 'Ж',
  ZH: 'Ж',
};

export function lat2cyr(lat: string) {
  let cyr = '';
  for (let i = 0; i < lat.length; i++) {
    let currentChar = lat.charAt(i);
    let nextChar = lat.charAt(i + 1);
    let thirdChar = lat.charAt(i + 2);

    let result = latinMapping[currentChar + nextChar + thirdChar];
    if (result) {
      cyr += result;
      i+=2;
      continue;
    }

    result = latinMapping[currentChar + nextChar];
    if (result) {
      cyr += result;
      i++;
      continue;
    }

    result = latinMapping[currentChar];
    if (result) {
      cyr += result;
    } else {
      cyr += currentChar;
    }
  }

  return cyr;
}
