import {createFeature, createReducer, on} from "@ngrx/store";
import {PageableResponse} from "../../models/pageable/pageable.response";
import {MunicipalityResponse} from "../../models/municipality.response";
import {SettlementResponse} from "../../models/settlement.response";
import {getMunicipalitiesSuccess, getSettlementsSuccess} from "./location.actions";
import {ProfileState} from "../../../private/profile/store/profile.state";
import {getMyProfileSuccess} from "../../../private/profile/store/profile.actions";
import {LocationState} from "./location.state";

const initialState: LocationState = {
  municipalities: new PageableResponse<MunicipalityResponse>(0, 0, 0, []),
  settlements: new PageableResponse<SettlementResponse>(0, 0, 0, []),
}

const locationFeature = createFeature({
  name: "Location",

  reducer: createReducer(
    initialState,

    on(getMunicipalitiesSuccess, (state, {response}) => ({
      ...state,
      municipalities: response
    })),

    on(getSettlementsSuccess, (state, {response}) => ({
      ...state,
      settlements: response
    }))
  )
})


export const {
  name: locationFeatureKey,
  reducer: locationReducer,
  selectMunicipalities,
  selectSettlements
} = locationFeature;
