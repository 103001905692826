import {AbstractControl, ValidatorFn} from '@angular/forms';


export function MatchingFieldsValidator(...fields: string[]): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    let latest: string | null = null;
    const allControls = fields
      .map(f => group.get(f))
      .filter(x => !!x)

    for (const field of fields) {
      if (latest === null) {
        latest = group.get(field)?.value || '';
      } else if (latest !== (group.get(field)?.value || '')) {
        allControls.forEach(ctrl => ctrl?.setErrors({passwordMismatch: true}))
        return {passwordMismatch: true}
      }
    }

    allControls.forEach(ctrl => ctrl?.setErrors(null))
    return null;
  }
}
