import {Component} from '@angular/core';
import {DonationsService} from "../donations.service";
import {DataGridTrait} from "../../../shared/services/grid.service";
import {ProfileResponse, RoleResponse} from "../../profile/profile.response";
import {ActivatedRoute, Router} from "@angular/router";
import {TableModule} from "primeng/table";
import {MessageService} from "primeng/api";
import {PaginatorModule} from "primeng/paginator";
import {ChipModule} from "primeng/chip";
import {TooltipModule} from "primeng/tooltip";
import {AsyncPipe, DatePipe} from "@angular/common";
import {RolesPreviewComponent} from "../../../shared/components/roles-preview/roles-preview.component";
import {ButtonModule} from "primeng/button";
import {DialogService} from "primeng/dynamicdialog";
import {DonationTrusteesNotifyComponent} from "./donation-trustees-notify/donation-trustees-notify.component";
import {NotificationService} from "../../../shared/services/notification.service";
import {filter, mergeMap, tap} from "rxjs";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {MessageRequest} from "../../../shared/models/message/message.request";
import {ManageUserActionsComponent} from "../../../shared/components/manage-user-actions/manage-user-actions.component";
import {
  trusteeDeleteError,
  trusteeDeleteSuccess,
  trusteeEditError,
  trusteeEditSuccess,
  trusteeReinviteError,
  trusteeReinviteSuccess
} from "../store/donations.actions";
import {Store} from "@ngrx/store";
import {TrusteeStatusComponent} from "../../../shared/components/trustee-status/trustee-status.component";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {UserFlatResponse} from "../user-flat.response";
import {
  DonationTrusteesChangeTrusteeComponent
} from "./donation-trustees-change-trustee/donation-trustees-change-trustee.component";
import {selectCurrentUser} from "../../profile/store/profile.reducer";
import {ChangeInvitedByRequest} from "../../profile/change-invited-by.request";

@Component({
  selector: 'bop-donations-trustees',
  standalone: true,
  imports: [
    TableModule,
    PaginatorModule,
    ChipModule,
    TooltipModule,
    DatePipe,
    RolesPreviewComponent,
    ButtonModule,
    SpinnerOverlay,
    ManageUserActionsComponent,
    TrusteeStatusComponent,
    InputTextModule,
    RippleModule,
    AsyncPipe,
  ],
  providers: [DialogService],
  templateUrl: './donations-trustees.component.html',
  styleUrl: './donations-trustees.component.scss'
})
export class DonationsTrusteesComponent {
  profile$ = this.store.select(selectCurrentUser);
  grid: DataGridTrait<ProfileResponse> = new DataGridTrait<ProfileResponse>(
    this.donationsService.getMyTrustees.bind(this.donationsService),
    this.route,
    this.router
  );
  selectedTrustees: ProfileResponse[] = [];
  loading = false;


  constructor(
    private donationsService: DonationsService,
    private notificationService: NotificationService,
    private store: Store,
    private messageService: MessageService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  getRolesIds(userRoles: RoleResponse[]): number[] {
    return userRoles.map(r => r.roleId);
  }

  openNotifyTrusteesModal() {
    this.dialogService.open(DonationTrusteesNotifyComponent, {
      header: 'Изпращане на съобщение',
      data: {
        trustees: this.selectedTrustees
      }
    }).onClose.pipe(
      filter(x => x),
      tap(() => this.loading = true),
      mergeMap((msg: MessageRequest) => this.notificationService.sendMessage(msg)),
      tap(() => this.loading = false)
    ).subscribe(() => this.messageService.add({
      severity: 'success',
      summary: 'Уведомяване на довереници',
      detail: 'Доверениците бяха успешно уведомени.'
    }))
  }

  openNotifyMyDonorModal() {
    this.profile$.subscribe(profile => {

      let trustees = [];
      trustees.push(profile?.invitedBy);

      this.dialogService.open(DonationTrusteesNotifyComponent, {
        header: 'Изпращане на съобщение',
        data: {trustees}
      }).onClose.pipe(
        filter(x => x),
        tap(() => this.loading = true),
        mergeMap((msg: MessageRequest) => this.notificationService.sendMessage(msg)),
        tap(() => this.loading = false)
      ).subscribe(() => this.messageService.add({
        severity: 'success',
        summary: 'Уведомяване на моя ДАРител',
        detail: 'Вашият ДАРител беше успешно уведомен.'
      }))
    })
  }

  onEdit(trustee: ProfileResponse) {
    this.grid._loading = true;
    this.donationsService.updateMyTrustee(trustee.id, {
      email: trustee.email,
      phoneNumbers: trustee.phoneNumbers,
      pendingInvitedById: trustee.pendingInvitedBy?.id || null
    }).pipe(
      tap(() => this.grid._loading = false),
    ).subscribe({
      next: () => this.store.dispatch(trusteeEditSuccess()),
      error: () => this.store.dispatch(trusteeEditError())
    })
  }

  onReinvite(id: number) {
    this.donationsService.reinviteTrustee(id)
      .subscribe({
        next: response => {
          this.grid.refetch();
          this.store.dispatch(trusteeReinviteSuccess({response}))
        },
        error: error => this.store.dispatch(trusteeReinviteError({error}))
      });
  }

  onDelete(id: number) {
    this.donationsService.deleteTrustee(id)
      .subscribe({
        next: response => {
          this.grid.refetch();
          this.store.dispatch(trusteeDeleteSuccess({response}))
        },
        error: error => this.store.dispatch(trusteeDeleteError({error}))
      });
  }

  onChangeTrustee(trustee: ProfileResponse) {
    this.dialogService.open(DonationTrusteesChangeTrusteeComponent, {
      header: 'Даряване към друг довереник',
      closable: false,
      width: '70%'
    }).onClose.subscribe({
      next: (profile: ProfileResponse) => {
        if (!profile) {
          return;
        }

        trustee.pendingInvitedBy = new UserFlatResponse(profile.id);

        let changeInvitedByRequest: ChangeInvitedByRequest = new ChangeInvitedByRequest(trustee.id, profile.id);

        this.donationsService.changeInvitedBy(changeInvitedByRequest).pipe(
          tap(() => this.grid._loading = false),
          tap(() => this.grid.refetch()),
        ).subscribe({
          next: () => this.messageService.add({
            severity: 'success',
            summary: 'Поканата за замяна се изпраща.',
            detail: 'Ако довереникът ви се съгласи да има различен дарител, това ще се случи автоматично след потвърждението му.'
          }),
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Възникна грешка.',
              detail: error
            })
          }
        })
      },
    })
  }
}
