import {ProfileState} from "./profile.state";
import {createFeature, createReducer, on} from "@ngrx/store";
import {
  getInvitedProfileSuccess,
  getMyProfile,
  getMyProfileSuccess,
  getTrusteeTransfer,
  getTrusteeTransferError,
  getTrusteeTransferSuccess,
  removeSelectedAddress,
  removeSelectedRole,
  setSelectedAddress,
  setSelectedRole,
  setSelectedWishes,
  setSelectedWishesBopSoftware,
  setSelectedWishesWantToBuy,
  setSelectedWishesWantToExchange,
  setSelectedWishesWantToSell,
  updateMyProfile,
  updateMyProfileError,
  updateMyProfileSuccess
} from "./profile.actions";
import {WishPackage} from "../profile-edit/profile-edit-wishes/wish.package";
import {LocationRequest} from "../../../shared/models/location/location.request";

const initialState: ProfileState = {
  currentUser: null,
  selectedRoles: [],
  selectedWishes: new WishPackage(),
  selectedAddresses: [],
  lastAddressId: -1,
  updateProfileLoading: false,
  getProfileLoading: false,
  transferStatuses: null
}

const profileFeature = createFeature({
  name: "Profile",

  reducer: createReducer(
    initialState,

    on(getMyProfile, (state) => ({
      ...state,
      getProfileLoading: true
    })),

    on(getMyProfileSuccess, (state, {response}) => ({
      ...state,
      currentUser: response,
      selectedAddresses: response.locations,
      getProfileLoading: false
    })),

    on(updateMyProfile, (state) => ({
      ...state,
      updateProfileLoading: true,
    })),

    on(updateMyProfileSuccess, (state) => ({
      ...state,
      updateProfileLoading: false,
    })),

    on(updateMyProfileError, (state) => ({
      ...state,
      updateProfileLoading: false,
    })),

    on(getInvitedProfileSuccess, (state, {response}) => ({
      ...state,
      currentUser: response,
      selectedAddresses: response.locations
    })),

    on(setSelectedRole, (state, {role}) => {
      const roles = state.selectedRoles.map(r => r);

      if (roles.filter(r => r.id === role.id).length === 0) {
        roles.push(role);
      }

      return {
        ...state,
        selectedRoles: roles
      };
    }),


    on(removeSelectedRole, (state, {role}) => {
      let roles = state.selectedRoles.map(r => r);
      roles = roles.filter(r => r.id !== role.id);

      return {
        ...state,
        selectedRoles: roles
      };
    }),

    on(setSelectedAddress, (state, {address}) => {
      let addresses = state.selectedAddresses.map(a => a);
      let newLastAddressId = state.lastAddressId;
      let editableAddress = Object.assign(new LocationRequest(), address);

      if (editableAddress.id) {
        addresses = addresses.filter(a => a.id !== editableAddress.id);
      } else {
        editableAddress.id = newLastAddressId;
        newLastAddressId--;
      }

      editableAddress.isDraft = true;
      addresses.push(editableAddress);

      return {
        ...state,
        selectedAddresses: addresses,
        lastAddressId: newLastAddressId
      };
    }),


    on(removeSelectedAddress, (state, {address}) => {
      let addresses = state.selectedAddresses.map(r => r);
      addresses = addresses.filter(a => a.id !== address.id);

      return {
        ...state,
        selectedAddresses: addresses
      };
    }),

    on(setSelectedWishes, (state, {wishes}) => ({
      ...state,
      selectedWishes: wishes
    })),

    on(setSelectedWishesWantToBuy, (state, {wishes}) => ({
      ...state,
      selectedWishes: {
        ...state.selectedWishes,
        wantToBuy: wishes,
      }
    })),

    on(setSelectedWishesWantToSell, (state, {wishes}) => ({
      ...state,
      selectedWishes: {
        ...state.selectedWishes,
        wantToSell: wishes,
      }
    })),

    on(setSelectedWishesWantToExchange, (state, {wishes}) => ({
      ...state,
      selectedWishes: {
        ...state.selectedWishes,
        wantToExchange: wishes,
      }
    })),


    on(setSelectedWishesBopSoftware, (state, {wishes}) => ({
      ...state,
      selectedWishes: {
        ...state.selectedWishes,
        bopSoftware: wishes,
      }
    })),

    on(getTrusteeTransfer, state => ({
      ...state,
      loading: true
    })),

    on(getTrusteeTransferSuccess, (state, {response}) => ({
      ...state,
      transferStatuses: response,
      loading: false
    })),

    on(getTrusteeTransferError, (state, {error}) => ({
      ...state,
      error,
      loading: false
    }))
  )
})


export const {
  name: profileFeatureKey,
  reducer: profileReducer,
  selectCurrentUser,
  selectSelectedRoles,
  selectSelectedWishes,
  selectSelectedAddresses,
  selectUpdateProfileLoading,
  selectGetProfileLoading,
  selectTransferStatuses
} = profileFeature;

