<div class="text-center p-2">
  <p>На Етап 2 тук ще може да въвеждате своите услуги.</p>

  <p>Когато имате други специфични потребности и предложения за
    реализация на Вашия потенциал, може да ги предложите през опция
    "Профил/Допълване на данните" в полето "Искам БОП да може"</p>

  <img src="assets/img/sneak-peaks/upload-goods.png" alt="">

</div>
