import {Inject, inject, Injectable} from "@angular/core";
import {LayoutStore} from "../layout/layout.store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AuthenticationService} from "../../../public/authentication/authentication.service";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {loginError, loginSuccess} from "../../../public/authentication/store/authentication.actions";
import {MessageService} from "primeng/api";
import {MESSAGE_SERVICE} from "../../../app.config";
import {
  finishInvitedProfileError,
  finishInvitedProfileSuccess, getInvitedProfileError,
  updateMyProfileError,
  updateMyProfileSuccess
} from "../../../private/profile/store/profile.actions";
import {
  createDonationError,
  createDonationSuccess,
  donationUpdateError,
  donationUpdateSuccess, inviteTrusteeSuccess,
  trusteeDeleteError,
  trusteeDeleteSuccess, trusteeEditError, trusteeEditSuccess,
  trusteeReinviteError,
  trusteeReinviteSuccess
} from "../../../private/donations/store/donations.actions";
import {exportCooperatorsBookError, exportCooperatorsBookSuccess} from "../../../private/admin/store/admin.actions";

@Injectable()
export class MessageEffects {

  constructor(private actions$: Actions,
              private messageService: MessageService,
              private store: Store) {
  }

  public loginSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(({response, message}) =>
          this.displaySuccessMessage(message)
        )
      ),
    {dispatch: false}
  );


  public updateProfileSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(updateMyProfileSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешна редакция на профила!')
        )
      ),
    {dispatch: false}
  );

  public inviteTrusteeSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(inviteTrusteeSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешна покана на довереник!')
        )
      ),
    {dispatch: false}
  );

  public finishProfileSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(finishInvitedProfileSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешно завършена регистрация! Моля влезте, за да продължите')
        )
      ),
    {dispatch: false}
  );

  public donationSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(createDonationSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешно създадено дарение!')
        )
      ),
    {dispatch: false}
  );

  public donationUpdateSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(donationUpdateSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешна редакция на дарение.')
        )
      ),
    {dispatch: false}
  );

  public deleteTrusteeSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(trusteeDeleteSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешно изтрит довереник.')
        )
      ),
    {dispatch: false}
  );

  public editTrusteeSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(trusteeEditSuccess),
        tap(() =>
          this.displaySuccessMessage('Успешно редактиран довереник.')
        )
      ),
    {dispatch: false}
  );

  public reinviteTrusteeSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(trusteeReinviteSuccess),
        tap(({response}) =>
          this.displaySuccessMessage('Успешно повторно изпращане на покана.')
        )
      ),
    {dispatch: false}
  );

  public getInvitedError = createEffect(() =>
      this.actions$.pipe(
        ofType(getInvitedProfileError),
        tap(({error}) =>
          this.displayErrorMessage('Изтекъл линк за регистрация. Моля, влезте в профила си или се свържете с вашия довереник.')
        )
      ),
    {dispatch: false}
  );

  public editTrusteeError = createEffect(() =>
      this.actions$.pipe(
        ofType(trusteeEditError),
        tap(() =>
          this.displayErrorMessage('Грешка при редактиране на довереник.')
        )
      ),
    {dispatch: false}
  );

  public updateProfileError = createEffect(() =>
      this.actions$.pipe(
        ofType(updateMyProfileError, createDonationError, donationUpdateError, trusteeDeleteError, trusteeReinviteError, finishInvitedProfileError),
        tap(({error}) =>
          this.displayErrorMessage(error.message || 'Моля, опитайте отново!')
        )
      ),
    {dispatch: false}
  );

  public loginError = createEffect(() =>
      this.actions$.pipe(
        ofType(loginError),
        tap(({error}) =>
          this.displayErrorMessage(error)
        )
      ),
    {dispatch: false}
  );

  public exportCooperatorsSuccess = createEffect(() =>
      this.actions$.pipe(
        ofType(exportCooperatorsBookSuccess),
        tap(() =>
          this.displaySuccessMessage('Книгата с кооператорите беше успешно свалена.')
        )
      ),
    {dispatch: false}
  );

  public exportCooperatorsError = createEffect(() =>
      this.actions$.pipe(
        ofType(exportCooperatorsBookError),
        tap(() =>
          this.displayErrorMessage('Възникна грешка при свалянето на книгата с кооператори.')
        )
      ),
    {dispatch: false}
  );

  public displaySuccessMessage(message: string) {
    this.messageService.add({severity: 'success', summary: message});
  }

  public displayErrorMessage(message: string) {
    this.messageService.add({severity: 'error', summary: 'Възникна грешка!', detail: message});
  }
}
