import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {ButtonModule} from "primeng/button";
import {ProfileResponse} from "../../profile.response";
import {
  FormRowWrapperComponent
} from "../../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {normalizePhoneControl} from "../../../../shared/validators/phone-number.validator";
import {group} from "@angular/animations";

@Component({
  selector: 'bop-profile-edit-data',
  standalone: true,
  imports: [
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
    FormRowWrapperComponent
  ],
  templateUrl: './profile-edit-data.component.html',
  styleUrl: './profile-edit-data.component.scss'
})
export class ProfileEditDataComponent {
  readonly normalizePhoneControl = normalizePhoneControl;
  @Input() public dataForm!: FormGroup;

  public getPhoneNumberFormArray(): FormArray {
    return this.dataForm.get('phoneNumbers') as FormArray;
  }

  getPhoneNumberControl(index: number): FormControl {
    return this.getPhoneNumberFormArray().controls[index] as FormControl;
  }

  protected readonly group = group;
}
