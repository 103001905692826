import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {MenuItemContent, MenuModule} from "primeng/menu";
import {MenuItem, PrimeIcons} from "primeng/api";
import {TableModule} from "primeng/table";

@Component({
  selector: 'bop-manage-user-actions',
  standalone: true,
  imports: [
    ButtonModule,
    TooltipModule,
    MenuModule,
    TableModule
  ],
  templateUrl: './manage-user-actions.component.html',
  styleUrl: './manage-user-actions.component.scss'
})
export class ManageUserActionsComponent {
  @Input()
  public showEdit = true;

  @Input()
  public showReinvite = true;

  @Input()
  public showDelete = true;

  @Input()
  public showChangeTrustee = true;

  @Output()
  public onEdit = new EventEmitter();

  @Output()
  public onReinvite = new EventEmitter();

  @Output()
  public onDelete = new EventEmitter();

  @Output()
  public onChangeTrustee = new EventEmitter();

  @Input() editing = false;

}
