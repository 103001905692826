import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {MailTemplate} from "../models/mail-template";

@Injectable({providedIn: 'root'})
export class MailsService {
  constructor(private http: HttpClient) {
  }

  public getMailTemplateHTML(): Observable<MailTemplate> {
    return this.http.get<MailTemplate>(
      'api/files/templates'
    );
  }

  public setMailTemplateHTML(message: MailTemplate): Observable<MailTemplate> {
    return this.http.post<MailTemplate>(
      'api/files/templates', message
    );
  }
}
