import {UserFlatResponse} from "./user-flat.response";

export class DonationResponse {
  constructor(
    public id: number = 0,
    public annualStringId: string = '',
    public donor: UserFlatResponse = new UserFlatResponse(),
    public recipient: UserFlatResponse = new UserFlatResponse(),
    public membershipFee: number = 1,
    public originGuaranty: number = 1,
    public qualityGuaranty: number = 1,
    public investment: number = 1,
    public buyBop: number = 1,
    public other: number = 1,
    public otherInfo: number = 0,
    public otherInfoType: {id: number, type: string}[] = [],
    public otherDonationType: number = 1,
    public sumAll: number = 1,
    public paymentMethod: number = 1,
    public status: number = 1,
    public obligationId: number = 0
  ) {

  }

  public static getFullSum(donation: DonationResponse) {
    return donation.membershipFee + donation.originGuaranty + donation.qualityGuaranty + donation.investment + donation.buyBop + (donation.otherDonationType !== 2 ? donation.other : 0);
  }
}

