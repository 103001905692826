import {Component} from '@angular/core';
import {DataGridTrait} from "../../../shared/services/grid.service";
import {ProfileResponse} from "../../profile/profile.response";
import {DonationsService} from "../donations.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {MessageService, SharedModule} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {ActivatedRoute, Router} from "@angular/router";
import {DonationResponse} from "../donation.response";
import {ButtonModule} from "primeng/button";
import {DatePipe, NgIf} from "@angular/common";
import {ManageUserActionsComponent} from "../../../shared/components/manage-user-actions/manage-user-actions.component";
import {RolesPreviewComponent} from "../../../shared/components/roles-preview/roles-preview.component";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {TableModule} from "primeng/table";
import {
    DonationStatusLabelComponent
} from "../../../shared/components/donation-status-label/donation-status-label.component";
import {
  PaymentMethodLabelComponent
} from "../../../shared/components/payment-method-label/payment-method-label.component";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";
import {DonationInterpolatedResponse} from "../donation-interpolated.response";
import {DonationTypePipe} from "../../../shared/pipes/donation-type.pipe";
import {donationOtherTypes} from "../donation-other.types";
import {AvatarModule} from "primeng/avatar";
import {
  AcceptRejectActionsComponent
} from "../../../shared/components/accept-reject-actions/accept-reject-actions.component";

@Component({
  selector: 'bop-donations-received',
  standalone: true,
  imports: [
    ButtonModule,
    DatePipe,
    ManageUserActionsComponent,
    RolesPreviewComponent,
    SharedModule,
    SpinnerOverlay,
    TableModule,
    DonationStatusLabelComponent,
    PaymentMethodLabelComponent,
    FileUrlPipe,
    DonationTypePipe,
    AvatarModule,
    AcceptRejectActionsComponent,
    NgIf
  ],
  templateUrl: './donations-received.component.html',
  styleUrl: './donations-received.component.scss'
})
export class DonationsReceivedComponent {
  grid: DataGridTrait<DonationInterpolatedResponse> = new DataGridTrait<DonationInterpolatedResponse>(
    this.donationsService.getAllReceived.bind(this.donationsService),
    this.route,
    this.router
  );

  loading = false;

  otherInfoTypes = donationOtherTypes;

  constructor(
    private donationsService: DonationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  includesDonationType(donation: DonationInterpolatedResponse, id: number): boolean {
    return donation.otherInfoTypeIds ? donation.otherInfoTypeIds.includes(id) : false;
  }
}
