import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'bop-terms-of-service',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    FormsModule
  ],
  templateUrl: './terms-of-service.component.html',
  styleUrl: './terms-of-service.component.scss'
})
export class TermsOfServiceComponent {
  public isChecked = true;

  constructor(private dialogRef: DynamicDialogRef<TermsOfServiceComponent>) {
  }


  closeModal() {
    this.dialogRef.close();
  }
}
