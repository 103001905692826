<div class="table-container w-10">
  <p-table
    #table
    class="w-5rem"
    [value]="grid.pagedData.content"
    dataKey="id"
    [totalRecords]="grid.pagedData.totalElements"
    [scrollable]="true"
    scrollHeight="auto"
    [rows]="grid.pageSize()"
    [first]="grid.firstPageElement()"
    [filters]="grid.filters"
    [sortField]="grid.sortField()"
    [sortOrder]="grid.sortDirection()"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="grid.loadData($event)"
    [metaKeySelection]="false"
    [tableStyle]="{'max-width': '6rem'}"
    selectionMode="multiple"
    currentPageReportTemplate="Показани от {first} до {last} от общо {totalRecords} записа"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">
          <div class="flex align-items-center">
            Номер
            <p-sortIcon field="annualStringId"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="date">
          <div class="flex align-items-center">
            Дата
          </div>
        </th>

        <th pSortableColumn="status">
          <div class="flex align-items-center">
            Статус
            <p-sortIcon field="status"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="payment_method" style="min-width:15rem">
          <div class="flex align-items-center">
            Начин на плащане
            <p-sortIcon field="paymentMethod"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="recipient_id" style="min-width:20rem">
          <div class="flex align-items-center">
            Получател
            <p-sortIcon field="recipient_id"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="donor_id" style="min-width:20rem">
          <div class="flex align-items-center">
            Изпращач
            <p-sortIcon field="donor_id"></p-sortIcon>
          </div>
        </th>

        <th style="min-width:10rem">
          <div class="flex align-items-center">
            Тип
          </div>
        </th>

        <th pSortableColumn="donation_sum" style="min-width:20rem">
          <div class="flex align-items-center">
            Сума
            <p-sortIcon field="donation_sum"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="FOOD" style="min-width:20rem">
          <div class="flex align-items-center">
            Храна
            <p-sortIcon field="FOOD"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="DRINKS" style="min-width:20rem">
          <div class="flex align-items-center">
            Напитка
            <p-sortIcon field="DRINKS"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="GOODS" style="min-width:20rem">
          <div class="flex align-items-center">
            Стока
            <p-sortIcon field="GOODS"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="SERVICE" style="min-width:20rem">
          <div class="flex align-items-center">
            Услуга
            <p-sortIcon field="SERVICE"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="LABOR" style="min-width:20rem">
          <div class="flex align-items-center">
            Труд
            <p-sortIcon field="LABOR"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="CONSULTATION" style="min-width:20rem">
          <div class="flex align-items-center">
            Консултация
            <p-sortIcon field="CONSULTATION"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="TECHNOLOGY" style="min-width:20rem">
          <div class="flex align-items-center">
            Технология
            <p-sortIcon field="TECHNOLOGY"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="APPORTIONMENT" style="min-width:20rem">
          <div class="flex align-items-center">
            Апорт
            <p-sortIcon field="APPORTIONMENT"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="USED" style="min-width:20rem">
          <div class="flex align-items-center">
            Ползвано
            <p-sortIcon field="USED"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="GIFT_WITH_PURPOSE" style="min-width:20rem">
          <div class="flex align-items-center">
            Дар с цел
            <p-sortIcon field="GIFT_WITH_PURPOSE"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="GIFT" style="min-width:20rem">
          <div class="flex align-items-center">
            Вересия
            <p-sortIcon field="GIFT"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="JOINTLY" style="min-width:20rem">
          <div class="flex align-items-center">
            Здружно
            <p-sortIcon field="JOINTLY"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="BG" style="min-width:20rem">
          <div class="flex align-items-center">
            100% Българско
            <p-sortIcon field="BG"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="NATURAL" style="min-width:20rem">
          <div class="flex align-items-center">
            100% Натурално
            <p-sortIcon field="NATURAL"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="PURE" style="min-width:20rem">
          <div class="flex align-items-center">
            100% Чисто
            <p-sortIcon field="PURE"></p-sortIcon>
          </div>
        </th>

        <th style="min-width:15rem">
          <div class="flex align-items-center">
            Снимка на друг дар
          </div>
        </th>

        <th style="min-width:15rem">
          <div class="flex align-items-center">
            Снимка на платежното
          </div>
        </th>
      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-donation>
      <tr [pSelectableRow]="donation" [pEditableRow]="donation">
        <td>
          <span class="ml-1 vertical-align-middle">{{ donation.annualStringId }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle text-sm">{{ donation.date | date:'dd.MM.yy' }}</span>
        </td>
        <td>
          <bop-donation-status-label [id]="donation.status"></bop-donation-status-label>
        </td>
        <td>
          <bop-payment-method-label [id]="donation.paymentMethod"></bop-payment-method-label>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">
            {{ donation?.recipient ? (donation?.recipient?.firstName || '') + ' ' + (donation?.recipient?.lastName || '') : 'БОП' }}
          </span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">
            {{ donation?.donor ? (donation?.donor?.firstName || '') + ' ' + (donation?.donor?.lastName || '') : 'БОП' }}
          </span>
        </td>

        <td>
          {{donation.donationType | donationType}}
        </td>

        <td>
          {{donation.donationSum}} лв.
        </td>

        @for (type of otherInfoTypes; track type) {
          <td>
            @if (includesDonationType(donation, type.id)) {
              <p-avatar
                icon="pi pi-check"
                styleClass="mr-2"
                [style]="{ 'background-color': 'lightgreen', color: '#1a2551' }"
                shape="circle" />
            } @else {
              <p-avatar
                icon="pi pi-minus"
                styleClass="mr-2"
                [style]="{ 'background-color': '#dee9fc', color: '#1a2551' }"
                shape="circle" />
            }

          </td>
        }

        <td>
          <ng-container *ngIf="donation?.otherInfoImage?.url">
            <a [href]="donation?.otherInfoImage?.url | fileUrl" target="_blank">
              <img class="max-h-3rem" [src]="donation?.otherInfoImage?.url | fileUrl" alt="Other Info Image">
            </a>
          </ng-container>
        </td>

        <td>
          <a [href]="donation?.paymentImage?.url | fileUrl" target="_blank">
            <img class="max-h-3rem" [src]="donation?.paymentImage?.url | fileUrl" alt="Payment Image">
          </a>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Не бяха открити дарения.</td>
      </tr>
    </ng-template>
  </p-table>
  <bop-spinner-overlay [loading]="loading">
    Зареждане
  </bop-spinner-overlay>
</div>
