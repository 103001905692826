import {Component, EventEmitter, Output} from '@angular/core';
import {AcceptRejectButtonsComponent} from "../accept-reject-buttons/accept-reject-buttons.component";
import {PanelModule} from "primeng/panel";

@Component({
  selector: 'bop-approvable-rejectable-widget',
  standalone: true,
  imports: [
    AcceptRejectButtonsComponent,
    PanelModule
  ],
  templateUrl: './approvable-rejectable-widget.component.html',
  styleUrl: './approvable-rejectable-widget.component.scss'
})
export class ApprovableRejectableWidgetComponent {
  @Output()
  public onAccept = new EventEmitter();

  @Output()
  public onReject = new EventEmitter();
}
