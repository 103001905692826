export enum FilterOperation {
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  BEGINS_WITH = 'BEGINS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  BEGINS_WITH_CASEINS = 'BEGINS_WITH_CASEINS',
  ENDS_WITH_CASEINS = 'ENDS_WITH_CASEINS',
  CONTAINS_CASEINS = 'CONTAINS_CASEINS'
}
