import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SystemMessage} from "../models/system-message";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class SystemService {
  constructor(private http: HttpClient) {
  }

  public getSystemMessage(): Observable<SystemMessage> {
    return this.http.get<SystemMessage>(
      'api/messages/system'
    );
  }

  public setSystemMessage(message: SystemMessage): Observable<SystemMessage> {
    return this.http.post<SystemMessage>(
      'api/messages/system', message
    );
  }
}
