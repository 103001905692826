import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";
import {PageableRequest} from "../models/pageable/pageable.request";
import {InvokableFiltration} from "../models/filtration/filtration";
import {PageableResponse} from "../models/pageable/pageable.response";
import {DistrictResponse} from "../models/district.response";
import {MunicipalityResponse} from "../models/municipality.response";
import {SettlementResponse} from "../models/settlement.response";
import {SharesResponse} from "../models/shares.response";

@Injectable({
  providedIn: 'root'
})
export class SharesService {
  constructor(
    private http: HttpClient
  ) {
  }

  public getAllShares(): Observable<SharesResponse> {
    return this.http.get<SharesResponse>(
      'api/shares'
    );
  }

}
