<p-sidebar styleClass="md:static" [visible]="sidebarVisible" [showCloseIcon]="false" [modal]="false"
           [dismissible]="false">
  <ng-template pTemplate="header">
    <section class="w-full flex justify-content-between align-items-center">
      <a routerLink="/">
        <img alt="logo" class="w-7rem bop-logo" src="../../../../assets/img/logo/logo-colorful.png">
      </a>
      <span class="text-xs text-primary-500 font-italic">Здравейте, {{ profileMeta?.name }}!</span>
      <p-button class="md:hidden" icon="pi pi-times" [rounded]="true" [text]="true"
                (click)="this.sidebarVisibility.emit()"></p-button>
    </section>
  </ng-template>

  <ng-template pTemplate="content">
    <p-panelMenu [model]="items"></p-panelMenu>
  </ng-template>
</p-sidebar>
