import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {WebcamModule} from "ngx-webcam";
import {DialogService} from "primeng/dynamicdialog";
import {
  PhotoChoiceGalleryPreviewComponent
} from "./photo-choice-gallery-preview/photo-choice-gallery-preview.component";
import {PhotoChoiceCameraComponent} from "./photo-choice-camera/photo-choice-camera.component";

@Component({
  selector: 'bop-photo-choice',
  standalone: true,
    imports: [
        ButtonModule,
        WebcamModule
    ],
  providers: [DialogService],
  templateUrl: './photo-choice.component.html',
  styleUrl: './photo-choice.component.scss'
})
export class PhotoChoiceComponent {
  @Output() fileSelectedEmitter = new EventEmitter<File>();

  @Input() public photoButtonText = 'Снимай';
  @Input() public galleryButtonText = 'От галерията';
  @Input() public previewSrc = '';
  @Input() public showCamera = true;
  @Input() public showPreview = true;

  constructor(private dialogService: DialogService) {
  }

  openCameraPopup() {
    this.dialogService.open(PhotoChoiceCameraComponent, {header: "Снимане"})
      .onClose.subscribe(photo => {
      if (!photo) {
        return;
      }

      this.visualiseFilePreview(photo);
    })
  }

  onGalleryPhotoUploaded($event: any) {
    if ($event.target.files[0]) {
      this.dialogService.open(PhotoChoiceGalleryPreviewComponent, {
        header: 'Преглед на снимка',
        data: {
          photo: $event.target.files[0]
        }
      }).onClose.subscribe(confirmed => {
        if (!confirmed) {
          return;
        }

        this.visualiseFilePreview($event.target.files[0]);
      })
    }
  }

  visualiseFilePreview(file: any) {
    this.fileSelectedEmitter.emit(file)

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.previewSrc = reader.result as string;
    };
  }
}
