<p-card>
  <section class="flex justify-content-between align-items-center">
    <div class="text-left">
      <div>Община: София</div>
      <div>Населено място: гр. София</div>
      <div>Координати: 42.6543453, 23.534645645</div>
      <div>Уточнение: Третата пряка зад аптеката </div>
      <div>Роли: <p-chip label="Транспортьор"></p-chip> </div>
    </div>

    <p-button icon="pi pi-pencil" label="Редакция"></p-button>
  </section>
</p-card>
