<section class="w-full m-auto text-center flex flex-column gap-3">
  <p-table [value]="wishes" dataKey="id" editMode="row">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>{{ header || '' }}</th>
        <th style="width: 20%"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-wish let-editing="editing">
      <tr [pEditableRow]="wish" [class.text-red-400]="wish.isDraft" [pTooltip]="wish.isDraft ? 'Промяната е все още скица. Моля запишете промените по профила си от бутона за запазване в края на страницата.' : undefined">
        <td>{{ wish.id }}</td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="editableWishes[wish.id]">
            </ng-template>
            <ng-template pTemplate="output">
              {{ editableWishes[wish.id] }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <div class="flex gap-3">
            @if (!editing) {
              <p-button pInitEditableRow icon="pi pi-pencil"></p-button>
            } @else {
              <p-button pSaveEditableRow (click)="onWishSaved(wish.id)" icon="pi pi-save"></p-button>
<!--              <p-button pCancelEditableRow severity="danger" icon="pi pi-times"></p-button>-->
            }
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</section>
