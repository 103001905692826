import {Component} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {MessagesModule} from "primeng/messages";
import {Message} from "primeng/api";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {AcceptRejectButtonsComponent} from "../accept-reject-buttons/accept-reject-buttons.component";

@Component({
  selector: 'bop-donation-terms',
  standalone: true,
  imports: [
    ButtonModule,
    MessagesModule,
    AcceptRejectButtonsComponent
  ],
  templateUrl: './donation-terms.component.html',
  styleUrl: './donation-terms.component.scss'
})
export class DonationTermsComponent {
  public messages: Message[] = [];

  constructor(private dialogRef: DynamicDialogRef<DonationTermsComponent>) {
  }

  onDismiss() {
    this.messages = [{
      severity: 'error',
      summary: 'Не можете да дарите без да приемете.',
      detail: 'Моля, приемете общите условия.'
    }]
  }

  onConfirm() {
    this.dialogRef.close();
  }
}
