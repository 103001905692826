<section class="w-full">
  <p-autoComplete placeholder="Получател"
                  [class.ng-invalid]="!selected"
                  [class.ng-dirty]="!selected"
                  [formControl]="userSearchControl"
                  [suggestions]="suggestions"
                  #recipient
                  (onSelect)="onReceiverSelect($event); recipient.hide()"
                  [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                  optionLabel="fullName"
                  (onFocus)="recipient.show()"
                  (onKeyUp)="recipient.show()"
                  appendTo="body">
  </p-autoComplete>
</section>
