import {Component, OnInit} from '@angular/core';
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {FileSelectEvent, FileUploadEvent, FileUploadModule} from "primeng/fileupload";
import {FileService} from "../../../shared/services/file.service";
import {MessageService} from "primeng/api";
import {MailsService} from "../../../shared/services/mails.service";
import {MailTemplate} from "../../../shared/models/mail-template";
import {RichEditorComponent} from "../../../shared/components/rich-editor/rich-editor.component";
import {RichEditorModel} from "../../../shared/components/rich-editor/rich-editor-model";

@Component({
  selector: 'bop-admin-mails',
  standalone: true,
  imports: [
    FormWrapperComponent,
    FileUploadModule,
    RichEditorComponent
  ],
  templateUrl: './admin-mails.component.html',
  styleUrl: './admin-mails.component.scss'
})
export class AdminMailsComponent implements OnInit {
  public richTextModel = new RichEditorModel();

  constructor(private fileService: FileService,
              private mailsService: MailsService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.mailsService.getMailTemplateHTML().subscribe({
      next: template => {
        this.richTextModel.text = template.templateText;
      }
    })
  }

  saveMailTemplate() {
    this.mailsService.setMailTemplateHTML(new MailTemplate(this.richTextModel.text))
      .subscribe({
        next: () => this.messageService.add({
          severity: 'success',
          summary: 'Успешно добавяне на текст на мейла!',
          detail: 'Можете да го прочетете във всички бъдещи мейл съобщения.'
        }),
        error: () => this.messageService.add({
          severity: 'error',
          summary: 'Възникна грешка!',
          detail: 'За съжаление възникна грешка. Моля, опитайте отново.'
        })
      })
  }

  onReasonsAndPrinciplesUpload($event: FileSelectEvent) {
    this.saveFile(1, $event.currentFiles[0], 'Принципи на БОП')
  }

  onMembershipTermsUpload($event: FileSelectEvent) {
    this.saveFile(2, $event.currentFiles[0], 'Условия за членство')
  }

  onMembershipProcedureUpload($event: FileSelectEvent) {
    this.saveFile(3, $event.currentFiles[0], 'Процедура по членство')
  }

  onFirstAdditionalFileUpload($event: FileSelectEvent) {
    this.saveFile(2, $event.currentFiles[0], 'Първи допълнителен файл')
  }

  onSecondAdditionalFileUpload($event: FileSelectEvent) {
    this.saveFile(2, $event.currentFiles[0], 'Втори допълнителен файл')
  }

  saveFile(id: number, file: File, fileName: string) {
    this.fileService.uploadFile(file, id).subscribe({
      next: () => this.messageService.add({
        severity: 'success',
        summary: 'Успешно запазване',
        detail: `Успешно запазихте новия файл за "${fileName}"`
      }),
      error: () => this.messageService.add({
        severity: 'error',
        summary: 'Грешка при запазване',
        detail: `За съжаление възникна грешка при запазване на файла.`
      })
    })
  }
}
