import {AbstractControl, ValidatorFn} from '@angular/forms';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();
const disablePhoneNumberCheck = true;

export function PhoneNumberValidator(regionCode: string, optional: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (optional && !control.value) {
      return null;
    }

    if(disablePhoneNumberCheck) {
      return null;
    }

    try {
      return normalizePhoneNumber(control.value, regionCode).length === 13
        ? null
        : error(control);
    } catch (e) {
      return error(control)
    }
  }
}

export function normalizePhoneNumber(phoneNum: string, region: string): string {
  try {
    const num = phoneNumberUtil.parse(phoneNum, region)

    return `+${num.getCountryCodeOrDefault()}${num.getNationalNumber()}`;
  } catch (e) {
    return `+${phoneNumberUtil.getCountryCodeForRegion(region)}`;
  }
}


export function normalizePhoneControl(control: AbstractControl, optional: boolean = false, region: string = 'BG') {
  if (optional && !control.value) {
    return;
  }

  const normalized = normalizePhoneNumber(control.value, region)
  control.setValue(normalized);
}

function error(control: AbstractControl): { [key: string]: any } {
  return {'wrongNumber': {value: control.value}};
}
