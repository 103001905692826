import {Component, inject, OnInit} from '@angular/core';
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'bop-profile',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  readonly layoutStore = inject(LayoutStore);

  ngOnInit(): void {
    this.layoutStore.setSidebarExpanded(false);
    this.layoutStore.setSidebarItems([]);
  }

}
