<div class="table-container w-10">
  <p-table
    #table
    class="w-5rem"
    selectionMode="single"
    [value]="grid.pagedData.content"
    dataKey="id"
    [totalRecords]="grid.pagedData.totalElements"
    [scrollable]="true"
    scrollHeight="auto"
    [rows]="grid.pageSize()"
    [first]="grid.firstPageElement()"
    [filters]="grid.filters"
    [sortField]="grid.sortField()"
    [sortOrder]="grid.sortDirection()"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="grid.loadData($event)"
    selectionMode="multiple"
    [(selection)]="selectedTrustees"
    editMode="row"
    currentPageReportTemplate="Показани от {first} до {last} от общо {totalRecords} записа"
  >
    <ng-template pTemplate="caption">
      <section class="flex gap-2">
        <p-button
          [outlined]="false"
          label="Запази в &quot;Книга на кооператорите&quot;"
          icon="pi pi-file-excel"
          (click)="saveAs()"
          size="small">
        </p-button>
      </section>

      <section class="flex justify-content-between mt-3">
        <p-button
          [outlined]="false"
          label="Съобщение към избраните"
          icon="pi pi-envelope"
          (click)="openNotifyTrusteesModal()"
          size="small"
          [disabled]="!selectedTrustees.length">
        </p-button>
      </section>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="status" style="min-width:10rem">
          <div class="flex align-items-center">
            Статус
            <p-sortIcon field="status"></p-sortIcon>
          </div>
        </th>

        <th>
          <div class="flex align-items-center">
            Действия
          </div>
        </th>

        <th pSortableColumn="statistics.donations" >
          <div class="flex align-items-center">
            Отчетени
            <p-sortIcon field="statistics.donations"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="statistics.pendingDonations">
          <div class="flex align-items-center">
            Неотчетени
            <p-sortIcon field="statistics.pеndingDonations"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="firstName" style="min-width:15rem">
          <div class="flex align-items-center">
            Име
            <p-sortIcon field="firstName"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="lastName" style="min-width:15rem">
          <div class="flex align-items-center">
            Фамилия
            <p-sortIcon field="lastName"></p-sortIcon>
          </div>
        </th>


        <th pSortableColumn="email" style="min-width:15rem">
          <div class="flex align-items-center">
            Имейл
            <p-sortIcon field="email"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="locations.settlement.municipality" style="min-width:15rem">
          <div class="flex align-items-center">
            Община
            <p-sortIcon field="locations.settlements.municipality"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="locations.settlement" style="min-width:15rem">
          <div class="flex align-items-center">
            Населено място
            <p-sortIcon field="locations.settlements"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="phoneNumbers.number" style="min-width:15rem">
          <div class="flex align-items-center">
            Телефонен номер
            <p-sortIcon field="phoneNumber"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="invitedBy.firstName" style="min-width:15rem">
          <div class="flex align-items-center">
            ДАРител
            <p-sortIcon field="invitedBy"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="invitedBy.firstName" style="min-width:15rem">
          <div class="flex align-items-center">
            Телефон на ДАРител
            <p-sortIcon field="invitedBy"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="userRoles.role.name">
          <div class="flex align-items-center">
            Роли
            <p-sortIcon field="roles"></p-sortIcon>
          </div>
        </th>

<!--        {{ (element?.invitedBy?.firstName || '') + ' ' + (element?.invitedBy?.lastName || '') }}-->

        <th pSortableColumn="rating">
          <div class="flex align-items-center">
            Оценка
            <p-sortIcon field="rating"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="shares" style="min-width:15rem">
          <div class="flex align-items-center">
            Кооперативни дялове
            <p-sortIcon field="statistics.cooperativeShares"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="donations" style="min-width:18rem">
          <div class="flex align-items-center">
            Дарения на негово име
            <p-sortIcon field="statistics.donations"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="registrationDate" style="min-width:10rem">
          <div class="flex align-items-center">
            Член от
            <p-sortIcon field="registrationDate"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="memberUntil" style="min-width:10rem">
          <div class="flex align-items-center">
            Член до
            <p-sortIcon field="memberUntil"></p-sortIcon>
          </div>
        </th>

      </tr>
      <!--    <tr>-->
      <!--      <th></th>-->
      <!--      <th>-->
      <!--        <p-columnFilter type="text"-->
      <!--                        field="firstName"-->
      <!--                        [matchMode]="filterOperation.CONTAINS"-->
      <!--                        [matchModeOptions]="[{label: 'Contains', value: filterOperation.CONTAINS}]"-->
      <!--                        [hideOnClear]="true"-->
      <!--                        [showMatchModes]="false"-->
      <!--                        [showOperator]="false">-->
      <!--        </p-columnFilter>-->
      <!--      </th>-->
      <!--      <th>-->
      <!--        <p-columnFilter type="text"-->
      <!--                        field="lastName"-->
      <!--                        [matchMode]="filterOperation.CONTAINS"-->
      <!--                        [matchModeOptions]="[{label: 'Contains', value: filterOperation.CONTAINS}]"-->
      <!--                        [hideOnClear]="true"-->
      <!--                        [showMatchModes]="false"-->
      <!--                        [showOperator]="false">-->
      <!--        </p-columnFilter>-->
      <!--      </th>-->
      <!--      <th>-->
      <!--        <p-columnFilter type="text"-->
      <!--                        field="email"-->
      <!--                        [matchMode]="filterOperation.CONTAINS"-->
      <!--                        [matchModeOptions]="[{label: 'Contains', value: filterOperation.CONTAINS}]"-->
      <!--                        [hideOnClear]="true"-->
      <!--                        [showMatchModes]="false"-->
      <!--                        [showOperator]="false">-->
      <!--        </p-columnFilter>-->
      <!--      </th>-->
      <!--      <th></th>-->
      <!--      <th></th>-->
      <!--      <th></th>-->
      <!--      <th></th>-->
      <!--      <th></th>-->
      <!--      <th></th>-->
      <!--    </tr>-->
    </ng-template>
    <ng-template pTemplate="body" let-trustee let-editing="editing">
      <tr [pSelectableRow]="trustee" [pEditableRow]="trustee">
        <td [pSelectableRow]="trustee">
          <p-tableCheckbox [value]="trustee"></p-tableCheckbox>
        </td>
        <td>
          <bop-trustee-status [id]="trustee.status"></bop-trustee-status>
        </td>
        <td>
          <bop-manage-user-actions
            [showDelete]="trustee.status === 3 || trustee.status === 4"
            [editing]="editing"
            [showChangeTrustee]="false"
            (onEdit)="onEdit(trustee)"
            (onReinvite)="onReinvite(trustee.id)"
            (onDelete)="onDelete(trustee.id)"></bop-manage-user-actions>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.statistics?.donations.toFixed(2) || 0 }} лв.</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.statistics?.pendingDonations.toFixed(2) || 0 }} лв.</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.firstName }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.lastName }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.email }}</span>
        </td>
        <td>
          <span
            class="ml-1 vertical-align-middle">{{ trustee.locations[0]?.settlement?.municipality?.name || '' }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.locations[0]?.settlement?.name || '' }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">
            @for (phoneNumber of trustee.phoneNumbers; track phoneNumber) {
              <a href="tel:{{ phoneNumber.number }}">{{ phoneNumber.number }}</a>
              <br>
            }
          </span>
        </td>
        <td>
          <span
            class="ml-1 vertical-align-middle">{{ (trustee?.invitedBy?.firstName || '') + ' ' + (trustee?.invitedBy?.lastName || '') }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">
            @for (phoneNumber of trustee?.invitedBy?.phoneNumbers; track phoneNumber) {
              <a href="tel:{{ phoneNumber.number }}">{{ phoneNumber.number }}</a>
              <br>
            }
          </span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">
              <bop-roles-preview [roleIds]="getRolesIds(trustee.userRoles)"></bop-roles-preview>
          </span>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputNumber [showButtons]="true" [(ngModel)]="trustee.rating"></p-inputNumber>
            </ng-template>
            <ng-template pTemplate="output">
              {{ trustee.rating }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.statistics?.cooperativeShares || 0 }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ trustee.statistics?.donations.toFixed(2) || 0 }} лв.</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle text-sm">{{ trustee.registrationDate | date:'dd.MM.yy' }}</span>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-calendar [(ngModel)]="trustee.memberUntil"></p-calendar>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="ml-1 vertical-align-middle text-sm">{{ trustee.memberUntil | date:'dd.MM.yy' }}</span>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Не бяха открити довереници.</td>
      </tr>
    </ng-template>
  </p-table>
  <bop-spinner-overlay [loading]="loading">
    Зареждане
  </bop-spinner-overlay>
</div>
