import {Component} from '@angular/core';
import {PhotoChoiceComponent} from "../../shared/components/photo-choice/photo-choice.component";
import {ActivatedRoute, Router} from "@angular/router";
import {FileService} from "../../shared/services/file.service";
import {LocationService} from "../../shared/services/location.service";
import {TableHeaderCheckbox} from "primeng/table";
import {MessageService} from "primeng/api";
import {ButtonModule} from "primeng/button";
import {ValidLocationTokenResponse} from "../../shared/models/location/valid-location-token.response";

@Component({
  selector: 'bop-location-images',
  standalone: true,
  imports: [
    PhotoChoiceComponent,
    ButtonModule
  ],
  templateUrl: './location-images.component.html',
  styleUrl: './location-images.component.scss'
})
export class LocationImagesComponent {
  public selectedImage: File | null = null;
  public token: string = ''

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private fileService: FileService,
              private locationService: LocationService) {
  }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];

    this.locationService.checkLocationTokenValidity(this.token)
      .subscribe({
        next: (response: ValidLocationTokenResponse) => {
          if (response.validToken) {
            return;
          }

          this.messageService.add({
            severity: 'error',
            summary: 'Невалиден линк.',
            detail: 'Линкът, с който се опитате да направите редакция е вече използван или невалиден.'
          });

          this.router.navigate(['']);
        }
      })
  }

  setSelectedImage(file: File) {
    this.selectedImage = file;
  }

  saveImage() {
    this.fileService.uploadLocationFile(this.selectedImage!, this.token)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Успешна промяна!',
            detail: 'Благодарим ви за съдействието!'
          });

          this.router.navigate(['']);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Възникна грешка.',
            detail: 'За съжаление нещо се обърка. Моля, опитайте отново.'
          });
        }
      })
  }
}
