<section class="text-center">
  <div class="flex flex-column md:flex-row gap-1 justify-content-center align-items-center">
    <h1 class="text-2xl">{{ layoutStore.profileMeta().name }}, добре дошли в</h1>
    <img class="w-5 md:w-2" src="../../../assets/img/logo/logo-colorful.png"/>
  </div>

  <section class="py-2 px-6">
    <div [innerHtml]="sanitizedSystemMessage"></div>

    @if ((currentUser$ | async)?.status === 3) {
    <section class="payment-warning py-3">
      <p class="text-red-600 text-lg font-bold"><i class="pi pi-exclamation-circle"></i> Моля, довършете регистрацията си през меню Профил!</p>
      <p-button icon="pi pi-money-bill" label="Към даренията" routerLink="/private/donations/create"></p-button>
    </section>
    } @else if ((currentUser$ | async)?.status === 5) {
      <section class="payment-warning py-3">
        <p class="text-red-600 text-lg font-bold"><i class="pi pi-exclamation-circle"></i> Вашият профил е временно
          блокиран. Моля, платете задълженията си към БОП в размер на
          <b>{{ (currentUser$ | async)?.statistics?.pendingDonations }} лв.</b>, за да бъдете отблокирани.</p>
        <p-button icon="pi pi-money-bill" label="Към даренията" routerLink="/private/donations/create"></p-button>
      </section>
    } @else if ((currentUser$ | async)?.status === 2) {
      <section class="payment-warning py-3">
        <p class="text-red-600 text-lg font-bold"><i class="pi pi-exclamation-circle"></i> Моля, заплатете членския си внос!</p>
        <p-button icon="pi pi-money-bill" label="Към даренията" routerLink="/private/donations/create"></p-button>
      </section>
    }

    <ng-container *ngIf="transferStatuses$ | async as transferStatuses">
      @if ((currentUser$ | async)?.pendingInvitedBy && (currentUser$ | async)?.status !== 2 && (currentUser$ | async)?.status !== 5) {
        <ng-container *ngIf="!transferStatuses.trusteeApproval">
          <bop-approvable-rejectable-widget (onAccept)="onChangeInvitedByConfirm()"
                                            (onReject)="onChangeInvitedByDismiss()">
            Вашият Дарител предложи смяна на Дарителя ви с
            <b>{{ (currentUser$ | async)?.pendingInvitedBy?.firstName }} {{ (currentUser$ | async)?.pendingInvitedBy?.lastName }}</b>.
            Приемате ли това предложение?
          </bop-approvable-rejectable-widget>
        </ng-container>
        <div *ngIf="transferStatuses.inviterApproval">Промяната е одобрена от другата страна.</div>
        <div *ngIf="!transferStatuses.inviterApproval && transferStatuses.trusteeApproval">Предстои да станете довереник на
          <b>{{ (currentUser$ | async)?.pendingInvitedBy?.firstName }} {{ (currentUser$ | async)?.pendingInvitedBy?.lastName }}</b>.
          Очаква се одобрение от дарителя.
        </div>
      }

      @if ((currentUser$ | async)?.pendingTrustee && (currentUser$ | async)?.status !== 2 && (currentUser$ | async)?.status !== 5) {
        <ng-container *ngIf="!transferStatuses.inviterApproval">
          <bop-approvable-rejectable-widget (onAccept)="onChangeTrusteeConfirm()"
                                            (onReject)="onChangeTrusteeDismiss()">
            Вашият Дарител предложи да станете Дарител на
            <b>{{ (currentUser$ | async)?.pendingTrustee?.firstName }} {{ (currentUser$ | async)?.pendingTrustee?.lastName }} </b>.
            Приемате ли това предложение?
          </bop-approvable-rejectable-widget>
        </ng-container>
        <div *ngIf="transferStatuses.trusteeApproval">Промяната е одобрена от другата страна.</div>
        <div *ngIf="!transferStatuses.trusteeApproval && transferStatuses.inviterApproval">Предстои да станете дарител на
          <b>{{ (currentUser$ | async)?.pendingTrustee?.firstName }} {{ (currentUser$ | async)?.pendingTrustee?.lastName }} </b>.
          Очаква се одобрение от довереника.
        </div>
      }
    </ng-container>

    <p-panel class="mx-2 mb-2">
      <div class="flex flex-column md:flex-row justify-content-between gap-3 mb-3">
        @if (shares$ | async) {
          <div class="flex flex-column w-full md:w-6 align-items-start gap-0 p-2 border-1 border-round-md">
            <div><span class="text-primary-400 font-bold">Всички дялове в БОП:</span></div>
            <div class="w-full text-center text-3xl">{{ (shares$ | async)?.total }}</div>
          </div>
        }

        <div class="flex flex-column w-full md:w-6 align-items-start gap-0 p-2 border-1 border-round-md">
          <div><span class="text-primary-400 font-bold">Брой на моите довереници:</span></div>
          <div class="w-full text-center text-3xl">{{ (currentUser$ | async)?.trustees?.length }}</div>
        </div>

        @if ((currentUser$ | async)?.invitedBy) {

          <div class="flex flex-column w-full md:w-6 align-items-start gap-0 p-2 border-1 border-round-md">
            <div><span class="text-primary-400 font-bold">Връзка с моя ДАРител</span></div>
            <div><i class="pi pi-phone mr-2"></i><a
              [href]="'tel:' + ((currentUser$ | async)?.invitedBy?.phoneNumbers || [])[0]!.number || ''">{{ ((currentUser$ | async)?.invitedBy?.phoneNumbers || [])[0]!.number || '' }}</a>
            </div>
            <div><i class="pi pi-envelope mr-2"></i><a
              [href]="'tel:' + ((currentUser$ | async)?.invitedBy?.email)">{{ ((currentUser$ | async)?.invitedBy?.email) }}</a>
            </div>
          </div>
        }

      </div>
    </p-panel>
    <br>
    <p-button icon="pi pi-receipt" label="Правила и общи условия" (onClick)="openTermsOfServiceDialog()"></p-button>
    <br>
  </section>

  <p-carousel [value]="carouselImages.carouselImages" [numVisible]="1" [numScroll]="1" [autoplayInterval]="3000"
              [circular]="true">
    <ng-template let-image pTemplate="item">
      <div class="mb-3">
        <img [src]="image.url | fileUrl" [style]="{'object-fit': 'cover'}" class="w-full h-30rem shadow-2"/>
      </div>
    </ng-template>
  </p-carousel>

  <bop-spinner-overlay [loading]="loading">
    Зареждане
  </bop-spinner-overlay>
</section>
