import {Component, inject} from '@angular/core';
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {selectCurrentUser} from "../profile/store/profile.reducer";
import {MenuItem, MenuItemCommandEvent, PrimeIcons} from "primeng/api";
import {Store} from "@ngrx/store";
import {ProfileState} from "../profile/store/profile.state";
import {RoleResponse} from "../profile/profile.response";
import {AVAILABLE_ROLES} from "../profile/profile.config";
import {Router, RouterOutlet} from "@angular/router";
import {DialogService} from "primeng/dynamicdialog";
import {MarketMissingRoleDialogComponent} from "./market-missing-role-dialog/market-missing-role-dialog.component";

@Component({
  selector: 'bop-market',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent {
  readonly layoutStore = inject(LayoutStore);
  private currentUser$ = this.store.select(selectCurrentUser);
  private isManifacturer = false;
  private isTransporter = false;

  private marketItems: MenuItem[] = [
    {
      icon: PrimeIcons.UPLOAD,
      label: 'Предлагам',
      expanded: true,
      items: [
        {
          icon: PrimeIcons.SUN,
          label: 'Свои храни, напитки',
          command: event => {
            this.checkIsManifacturer();
          },
          routerLink: ['private', 'market', 'create', 'food']
        },
        {
          icon: PrimeIcons.SHOPPING_CART,
          label: 'Свои български стоки',
          command: event => {
            this.checkIsManifacturer();
          },
          routerLink: ['private', 'market', 'create', 'goods']
        },
        {
          icon: PrimeIcons.COG,
          label: 'Свои услуги и труд',
          command: event => {
            this.checkIsManifacturer();
          },
          routerLink: ['private', 'market', 'create', 'service']
        },
        {
          icon: 'pi pi-microchip',
          label: 'Ефикасни технологии',
          command: event => {
            this.checkIsManifacturer();
          },
          routerLink: ['private', 'market', 'create', 'technology']
        },
        {
          icon: PrimeIcons.TRUCK,
          label: 'Свои транспортни средства',
          command: event => {
            this.checkIsTransporter();
          },
          routerLink: ['private', 'market', 'create', 'transportations']
        },
        {
          icon: PrimeIcons.MONEY_BILL,
          label: 'Свои ползвани вещи и оборудване',
          routerLink: ['private', 'market', 'create', 'used']
        },
        {
          icon: PrimeIcons.USERS,
          label: 'За коопериране',
          routerLink: ['private', 'market', 'create', 'consultations']
        }]
    },
    {
      icon: PrimeIcons.MONEY_BILL,
      label: 'Моите сделки',
    },
    {
      icon: PrimeIcons.SHOPPING_CART,
      label: 'Налични продукти',
    },
    {
      icon: PrimeIcons.SEARCH,
      label: 'Търся',
      expanded: true,
      items: [
        {
          icon: PrimeIcons.SHOPPING_CART,
          label: 'Български храни, напитки',
          routerLink: ['private', 'market', 'search', 'food']
        },
        {
          icon: 'pi pi-warehouse',
          label: 'Български стоки',
          routerLink: ['private', 'market', 'search', 'goods']
        },
        {
          icon: PrimeIcons.COG,
          label: 'Услуги и труд',
          routerLink: ['private', 'market', 'search', 'service']
        },
        {
          icon: 'pi pi-microchip',
          label: 'Ефикасни технологии',
          routerLink: ['private', 'market', 'search', 'technology']
        },
        {
          icon: 'pi pi-hammer',
          label: 'Ползвани вещи и оборудване',
          routerLink: ['private', 'market', 'search', 'used']
        },
        {
          icon: PrimeIcons.TRUCK,
          label: 'Заявки за доставки',
          routerLink: ['private', 'market', 'search', 'transportations']
        },
        {
          icon: PrimeIcons.USERS,
          label: 'За коопериране',
          routerLink: ['private', 'market', 'search', 'consultations']
        }
      ]
    }
  ]

  constructor(private store: Store<ProfileState>,
              private dialogService: DialogService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.layoutStore.setSidebarItems(this.marketItems);
    this.layoutStore.setSidebarExpanded(true);

    this.currentUser$.subscribe(user => {
      let currentUserRoleIds = user?.userRoles.map(r => r.roleId) || [];

      if (currentUserRoleIds.includes(AVAILABLE_ROLES.Manifacturer)) {
        this.isManifacturer = true;
      }

      if (currentUserRoleIds.includes(AVAILABLE_ROLES.Transporter)) {
        this.isTransporter = true;
      }
    })
  }

  checkIsManifacturer() {
    if (this.isManifacturer) {
      return;
    }

    this.suggestAddRole();
  }

  checkIsTransporter() {
    if (this.isTransporter) {
      return;
    }

    this.suggestAddRole();
  }

  suggestAddRole() {
    this.dialogService.open(MarketMissingRoleDialogComponent, {header: 'Ролята липсва в профила.', width: '70%'})
      .onClose.subscribe(wantsToAddRole => {
      if (!wantsToAddRole) {
        return;
      }

      this.router.navigate(['private', 'profile', 'edit']);
    })
  }
}
