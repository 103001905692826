import { Component } from '@angular/core';

@Component({
  selector: 'bop-form-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './form-wrapper.component.html',
  styleUrl: './form-wrapper.component.scss'
})
export class FormWrapperComponent {

}
