import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ProfileResponse} from "../../../profile/profile.response";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {CheckboxModule} from "primeng/checkbox";
import {ButtonModule} from "primeng/button";
import {RichEditorComponent} from "../../../../shared/components/rich-editor/rich-editor.component";
import {RichEditorModel} from "../../../../shared/components/rich-editor/rich-editor-model";

@Component({
  selector: 'bop-donation-trustees-notify',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    CheckboxModule,
    ButtonModule,
    RichEditorComponent
  ],
  templateUrl: './donation-trustees-notify.component.html'
})
export class DonationTrusteesNotifyComponent {
  public richEditorModel = new RichEditorModel();

  public messageForm = this.formBuilder.group({
      message: new FormControl(''),
      notifyByEmail: new FormControl(true),
      recipients: new FormArray<FormControl>([])
    }
  );

  constructor(private dialogRef: DynamicDialogRef<DonationTrusteesNotifyComponent>,
              private dialogConfig: DynamicDialogConfig,
              private formBuilder: FormBuilder) {
    if (this.dialogConfig.data?.trustees) {
      this.dialogConfig.data.trustees.map((t: ProfileResponse) => new FormControl(t.id))
        .forEach((ctrl: FormControl) => this.messageForm.controls.recipients.push(ctrl))
    }
  }

  sendMessage() {
    this.messageForm.controls.message.setValue(this.richEditorModel.text);
    this.dialogRef.close(this.messageForm.value);
  }

}
