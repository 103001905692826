import {Component, Input} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputNumberModule} from "primeng/inputnumber";

@Component({
  selector: 'bop-profile-edit-logout-time',
  standalone: true,
  imports: [
    InputGroupModule,
    InputGroupAddonModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './profile-edit-logout-time.component.html',
  styleUrl: './profile-edit-logout-time.component.scss'
})
export class ProfileEditLogoutTimeComponent {
  @Input() public dataForm!: FormGroup;
}
