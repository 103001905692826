@if (inCaptureMode) {
  <div>
    <webcam
      mirrorImage="never"
      [trigger]="triggerAsObservable"
      (imageCapture)="captureImage($event)"
    ></webcam>
    <div class="flex justify-content-center mt-2">
      <p-button label="Снимай" icon="pi pi-camera" (onClick)="initCapture($event)"></p-button>
    </div>
  </div>
}

@if (inPreviewMode) {
  <div>
    <img [src]="previewSrc" alt="">
    <div class="flex justify-content-center gap-2 mt-2">
      <p-button label="Запази" icon="pi pi-save" (onClick)="saveImage($event)"></p-button>
      <p-button label="Откажи" icon="pi pi-times" severity="danger" (onClick)="cancelImage()"></p-button>
    </div>
  </div>
}

