import {Component, EventEmitter, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'bop-accept-reject-actions',
  standalone: true,
    imports: [
        ButtonModule,
        TooltipModule
    ],
  templateUrl: './accept-reject-actions.component.html',
  styleUrl: './accept-reject-actions.component.scss'
})
export class AcceptRejectActionsComponent {
  @Output()
  public onAccept = new EventEmitter();

  @Output()
  public onReject = new EventEmitter();
}
