import {Role} from "./profile.response";

export enum AVAILABLE_ROLES {
  Manifacturer = 1,
  Transporter = 2,
  Host = 3,
  Coordinator = 4,
  Client = 5,
  Other = 6
}

export const availableRoles: Role[] = [
  {
    id: AVAILABLE_ROLES.Manifacturer,
    name: 'Производител на здравословни продукти',
    alias: 'Производител'
  },
  {
    id: AVAILABLE_ROLES.Transporter,
    name: 'Транспортьор с грижа за клиентите и качеството',
    alias: 'Транспортьор'
  },
  {
    id: AVAILABLE_ROLES.Host,
    name: 'Домакин на товаро-разтоварен БОП пункт (БОПче)',
    alias: 'Домакин'
  },
  {
    id: AVAILABLE_ROLES.Coordinator,
    name: 'Координатор БОП център (търпение със стил и слух)',
    alias: 'Координатор'
  },
  {
    id: AVAILABLE_ROLES.Client,
    name: 'Коректен клиент',
    alias: 'Клиент'
  },
  {
    id: AVAILABLE_ROLES.Other,
    name: 'Друго',
    alias: 'Друго'
  }
];

