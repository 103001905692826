import { Component } from '@angular/core';
import {DatePipe, NgIf} from "@angular/common";
import {RolesPreviewComponent} from "../../../shared/components/roles-preview/roles-preview.component";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {DataGridTrait} from "../../../shared/services/grid.service";
import {DonationResponse} from "../../donations/donation.response";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../admin.service";
import {
  AcceptRejectActionsComponent
} from "../../../shared/components/accept-reject-actions/accept-reject-actions.component";
import {
  PaymentMethodLabelComponent
} from "../../../shared/components/payment-method-label/payment-method-label.component";
import {
  DonationStatusLabelComponent
} from "../../../shared/components/donation-status-label/donation-status-label.component";
import {Store} from "@ngrx/store";
import {donationUpdateError, donationUpdateSuccess} from "../../donations/store/donations.actions";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";
import {ButtonModule} from "primeng/button";
import {tap} from "rxjs";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {DonationInterpolatedResponse} from "../../donations/donation-interpolated.response";
import {donationOtherTypes} from "../../donations/donation-other.types";
import {AvatarModule} from "primeng/avatar";
import {DonationTypePipe} from "../../../shared/pipes/donation-type.pipe";

@Component({
  selector: 'bop-admin-donations',
  standalone: true,
  imports: [
    DatePipe,
    RolesPreviewComponent,
    SharedModule,
    TableModule,
    AcceptRejectActionsComponent,
    PaymentMethodLabelComponent,
    DonationStatusLabelComponent,
    FileUrlPipe,
    ButtonModule,
    SpinnerOverlay,
    AvatarModule,
    DonationTypePipe,
    NgIf
  ],
  templateUrl: './admin-donations.component.html',
  styleUrl: './admin-donations.component.scss'
})
export class AdminDonationsComponent {
  grid: DataGridTrait<DonationInterpolatedResponse> = new DataGridTrait<DonationInterpolatedResponse>(
    this.adminService.getAllDonations.bind(this.adminService),
    this.route,
    this.router
  );

  loading = false;

  otherInfoTypes = donationOtherTypes;

  constructor(
    private messageService: MessageService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  includesDonationType(donation: DonationInterpolatedResponse, id: number): boolean {
    return donation.otherInfoTypeIds ? donation.otherInfoTypeIds.includes(id) : false;
  }

  onAccept(id: number) {
    this.adminService.approveDonation(id)
      .subscribe(response => {
        this.store.dispatch(donationUpdateSuccess({response}));
        this.grid.refetch();
      }, error => this.store.dispatch(donationUpdateError({error})))
  }

  onReject(id: number) {
    this.adminService.rejectDonation(id)
      .subscribe(response => this.store.dispatch(donationUpdateSuccess({response})), error => this.store.dispatch(donationUpdateError({error})))
  }

  save() {
    // this.loading = true;
    const content = this.grid.pagedData.content;
    this.grid.pagedData.content = [];
    this.grid._loading = true;
    this.adminService.saveDonations().pipe(
      // tap(() => this.loading = false),
      tap(() => this.grid.pagedData.content = content),
      tap(() => this.grid._loading = false)
    ).subscribe({
      next: () => this.messageService.add({
        severity: 'success',
        summary: 'Успешно запазване',
        detail: `Успешно запазихте справката като файл.'}`
      }),
      error: () => this.messageService.add({
        severity: 'error',
        summary: 'Грешка при запазване',
        detail: `За съжаление възникна грешка при запазване на справката.`
      })
    })
  }
}
