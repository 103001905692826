import {WishDto} from "./wish.dto";

export class WishPackage {
  constructor(
    public bopSoftware: WishDto[] = [{id: 1, name: '', isDraft: false}, {id: 2, name: '', isDraft: false}, {id: 3, name: '', isDraft: false}, {id: 4, name: '', isDraft: false}, {id: 5, name: '', isDraft: false}, ],
    public wantToBuy: WishDto[] = [{id: 1, name: '', isDraft: false}, {id: 2, name: '', isDraft: false}, {id: 3, name: '', isDraft: false}, {id: 4, name: '', isDraft: false}, {id: 5, name: '', isDraft: false}, ],
    public wantToSell: WishDto[] = [{id: 1, name: '', isDraft: false}, {id: 2, name: '', isDraft: false}, {id: 3, name: '', isDraft: false}, {id: 4, name: '', isDraft: false}, {id: 5, name: '', isDraft: false}, ],
    public wantToExchange: WishDto[] = [{id: 1, name: '', isDraft: false}, {id: 2, name: '', isDraft: false}, {id: 3, name: '', isDraft: false}, {id: 4, name: '', isDraft: false}, {id: 5, name: '', isDraft: false}, ],
  ) {
  }
}
