import { Component } from '@angular/core';

@Component({
  selector: 'bop-market-create-used',
  standalone: true,
  imports: [],
  templateUrl: './market-create-used.component.html',
  styleUrl: './market-create-used.component.scss'
})
export class MarketCreateUsedComponent {

}
