import {Component, OnInit} from '@angular/core';
import {
  AbstractControl, FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {InputNumberModule} from "primeng/inputnumber";
import {
  DonationsCreateNumberControlComponent
} from "./donations-create-number-control/donations-create-number-control.component";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {
  FormRowWrapperComponent
} from "../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {DropdownModule} from "primeng/dropdown";
import {TooltipModule} from "primeng/tooltip";
import {CardModule} from "primeng/card";
import {PanelModule} from "primeng/panel";
import {FileUploadModule} from "primeng/fileupload";
import {DialogService} from "primeng/dynamicdialog";
import {AsyncPipe} from "@angular/common";
import {AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {CheckboxModule} from "primeng/checkbox";
import {DonationsService} from "../donations.service";
import {Store} from "@ngrx/store";
import {selectCurrentUser} from "../../profile/store/profile.reducer";
import {Filtration, InvokableFiltration} from "../../../shared/models/filtration/filtration";
import {PageableRequest} from "../../../shared/models/pageable/pageable.request";
import {filter, map, Observable} from "rxjs";
import {PageableResponse} from "../../../shared/models/pageable/pageable.response";
import {ProfileResponse} from "../../profile/profile.response";
import {DonationResponse} from "../donation.response";
import {createDonation} from "../store/donations.actions";
import {lat2cyr} from "../../../shared/services/transliteration.service";
import {paymentMethods} from "../../../shared/config/payment-method.config";
import {DonationTermsComponent} from "../../../shared/components/donation-terms/donation-terms.component";
import {PhotoChoiceComponent} from "../../../shared/components/photo-choice/photo-choice.component";
import {TrusteeSelectionComponent} from "../../../shared/components/trustee-selection/trustee-selection.component";
import {DonationCreateRequest} from "../donation-create.request";
import {DonationIdResponse} from "../donation-id.response";
import {MultiSelectModule} from "primeng/multiselect";
import {selectPendingDonationLoading, selectPendingInvitationLoading} from "../store/donations.reducer";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {donationOtherTypes} from "../donation-other.types";

@Component({
  selector: 'bop-donations-create',
  standalone: true,
  imports: [
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    InputNumberModule,
    DonationsCreateNumberControlComponent,
    FormWrapperComponent,
    FormRowWrapperComponent,
    DropdownModule,
    TooltipModule,
    CardModule,
    PanelModule,
    FileUploadModule,
    AsyncPipe,
    AutoCompleteModule,
    CheckboxModule,
    PhotoChoiceComponent,
    TrusteeSelectionComponent,
    MultiSelectModule,
    SpinnerOverlay
  ],
  providers: [DialogService],
  templateUrl: './donations-create.component.html',
  styleUrl: './donations-create.component.scss'
})
export class DonationsCreateComponent {
  public hasBopObligations = false;
  public otherDonationTypes = [
    {id: 1, type: 'ЛЕВ'},
    {id: 2, type: 'ЧАС'},
    {id: 3, type: 'ДАР'},
    {id: 4, type: 'БУНАР'},
    {id: 5, type: 'ВСН'},
    {id: 6, type: 'CHF'},
    {id: 7, type: 'CNY'},
    {id: 8, type: 'EUR'},
    {id: 9, type: 'HUF'},
    {id: 10, type: 'RUB'},
    {id: 11, type: 'USD'},
  ];

  public otherInfoTypes = donationOtherTypes;

  public paymentImageFile: File | null = null;
  public otherInfoImageFile: File | null = null;

  public paymentMethods = paymentMethods;

  public userFullName: string = '';
  public userIsAdmin = false;
  public userStatusId = 0;

  public pendingDonation: DonationResponse | undefined;

  public donationIdResponse: DonationIdResponse | undefined;

  public donationForBop = false;
  public receiverSelected = false;

  public donorId: number | undefined = 0;

  public donationForm = this.fb.group({
    donorId: new FormControl(),
    membershipFee: new FormControl(0),
    originGuaranty: new FormControl(0),
    qualityGuaranty: new FormControl(0),
    investment: new FormControl(0),
    recipientId: new FormControl<number | null>(null),
    buyBop: new FormControl(0),
    other: new FormControl(0),
    otherInfo: new FormControl(0),
    otherDonationType: new FormControl(1),
    otherDonationInfoType: new FormControl(1),
    otherInfoType: new FormControl<{id: number, type: string}[]>([]),
    donationInfo: new FormControl(''),
    sumAll: new FormControl(this.calculateSumAll()),
    paymentMethod: new FormControl(null, [Validators.required]),
    paymentImageId: new FormControl(null),
    otherInfoImageId: new FormControl(null),
    sumAllConfirm: new FormControl(0),
    token: new FormControl(''),
    obligationId: new FormControl<number | null>(null)
  }, {validators: [this.sumAllValidator(), this.recipientValidator()]});

  public checkboxTracker: boolean = false;

  public loading: boolean = false;

  constructor(private store: Store,
              private fb: FormBuilder,
              private donationsService: DonationsService,
              private dialogService: DialogService) {
  }

  ngOnInit() {
    this.store.select(selectCurrentUser).subscribe(user => {
      this.userFullName = `${user?.firstName} ${user?.lastName}`;

      this.userIsAdmin = user?.admin || false;
      this.userStatusId = user?.status || 0;

      this.donorId = user?.id;

      this.setDonorId();
    });

    this.donationsService.getPending().subscribe(pendingDonation => {
      this.pendingDonation = pendingDonation;
      this.hasBopObligations = !!this.pendingDonation;

      if (this.userStatusId !== 1 && !this.userIsAdmin) {
        this.donationForBop = true;
        this.toggleDonationsForBop();
      }

    });

    this.donationsService.getReservedDonationId().subscribe(donationIdResponse => {
      this.donationIdResponse = donationIdResponse;
    });

    this.store.select(selectPendingDonationLoading).subscribe(loading => this.loading = loading);
  }

  openDonationTermsDialog() {
    this.dialogService.open(DonationTermsComponent, {
      header: `Здравейте, ${this.userFullName}`,
      closable: false,
      width: '90vw'
    })
  }

  toggleDonationsForBop() {
    this.donationForBop = !this.donationForBop;

    if (!this.pendingDonation) {
      return;
    }

    if (this.donationForBop) {
      this.donationForm.patchValue({
        membershipFee: this.pendingDonation.membershipFee || 0,
        originGuaranty: this.pendingDonation.originGuaranty || 0,
        qualityGuaranty: this.pendingDonation.qualityGuaranty || 0,
        other: this.pendingDonation.other || 0,
        buyBop: this.pendingDonation.buyBop || 0,
        investment: this.pendingDonation.investment || 0,
        recipientId: null,
        obligationId: this.pendingDonation.obligationId,
        otherInfo: null
      });

      this.donationForm.controls.membershipFee.disable();
      this.donationForm.controls.originGuaranty.disable();
      this.donationForm.controls.qualityGuaranty.disable();
      this.donationForm.controls.other.disable();
      this.donationForm.controls.otherDonationType.disable();
      this.donationForm.controls.buyBop.disable();
      this.donationForm.controls.investment.disable();
      this.donationForm.controls.otherInfo.disable();
      this.donationForm.controls.otherInfoType.disable();
    } else {
      this.donationForm.patchValue({
        membershipFee: 0,
        originGuaranty: 0,
        qualityGuaranty: 0,
        other: 0,
        buyBop: 0,
        investment: 0,
        recipientId: null
      })

      this.donationForm.controls.membershipFee.enable();
      this.donationForm.controls.originGuaranty.enable();
      this.donationForm.controls.qualityGuaranty.enable();
      this.donationForm.controls.other.enable();
      this.donationForm.controls.otherDonationType.enable();
      this.donationForm.controls.buyBop.enable();
      this.donationForm.controls.investment.enable();
      this.donationForm.controls.otherInfo.enable();
      this.donationForm.controls.otherInfoType.enable();
    }
  }

  onReceiverSelect($event: ProfileResponse) {
    this.donationForm.reset();

    this.setDonorId();

    this.donationForm.patchValue({
      recipientId: $event.id
    })

    this.receiverSelected = true;
    this.donationForm.updateValueAndValidity();
  }

  public saveDonation() {
    let donationCreateRequest: DonationCreateRequest = this.donationForm.getRawValue();
    donationCreateRequest.sumAll = this.donationForm.value.sumAllConfirm;
    this.store.dispatch(createDonation({request: donationCreateRequest, otherInfoImageFile: this.otherInfoImageFile!, paymentImageFile: this.paymentImageFile!}));
  }

  public calculateSumAll(): number {
    if (this.donationForm) {
      if (!this.donationForBop || (this.donationForBop && !this.pendingDonation)) {

        const values = this.donationForm.value;

        let result = values.membershipFee! + values.originGuaranty! + values.qualityGuaranty! +
          values.investment! + values.buyBop!

        if (this.donationForm.value.otherDonationType !== 2)
          result += values.other!

        if (this.donationForm.value.otherDonationInfoType !== 2)
          result += values.otherInfo!

        return result;
      } else {
        try {
          return DonationResponse.getFullSum(this.pendingDonation!);
        } catch {
          return 0;
        }
      }

    }
    return 0;
  }

  private sumAllValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const sumAllControl = formGroup.get('sumAll');
      const sumAllConfirmControl = formGroup.get('sumAllConfirm');
      if (!sumAllControl || !sumAllConfirmControl || !(formGroup instanceof FormGroup)) {
        return null;
      }

      const sumAllValue = this.calculateSumAll()
      const sumAllConfirmValue = sumAllConfirmControl.value;

      if (sumAllValue !== sumAllConfirmValue) {
        sumAllConfirmControl.setErrors({sumAllMismatch: true});
        return {sumAllMismatch: true};
      } else {
        sumAllConfirmControl.setErrors(null);
        return null;
      }
    };
  }

  private recipientValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const recipientId = formGroup.get('recipientId')?.value;

      if (!this.donationForBop && !recipientId) {
        return { recipientRequired: true };
      }
      return null;
    };
  }

  setPaymentImageFile($event: File) {
    this.paymentImageFile = $event;
  }

  setOtherInfoImageFile($event: File) {
    this.otherInfoImageFile = $event;
  }

  setDonorId() {
    this.donationForm.patchValue({
      donorId: this.donorId
    })
  }
}
