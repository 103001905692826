import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileUrlPipe} from "../../../../shared/pipes/file-url.pipe";
import {ImageModule} from "primeng/image";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'bop-admin-home-page-carousel-image-preview',
  standalone: true,
  imports: [
    FileUrlPipe,
    ImageModule,
    ButtonModule
  ],
  templateUrl: './admin-home-page-carousel-image-preview.component.html',
  styleUrl: './admin-home-page-carousel-image-preview.component.scss'
})
export class AdminHomePageCarouselImagePreviewComponent {
  @Input() public url: string = '';
  @Output() public onDelete = new EventEmitter();
}
