export class StatisticsResponse {
  constructor(
    public name: string = '',
    public rating: number = 0,
    public cooperativeShares: number = 0,
    public donations: number = 0.0,
    public pendingDonations: number = 0.0,
    public deactivateDate: Date = new Date(),
    public buyBop: number = 0.0,
    public investment: number = 0.0,
    public membershipFee: number = 0.0,
    public originGuaranty: number = 0.0,
    public other: number =  0.0,
    public qualityGuaranty: number = 0.0,
    public sumAll: number = 0.0,

  ) {}
}
