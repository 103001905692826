import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileUrl',
  standalone: true
})
export class FileUrlPipe implements PipeTransform {

  transform(value: string): string {
    return '/_content/' + value.split('/')[value.split('/').length - 1];
  }
}
