<bop-form-wrapper>
  <h1 class="text-2xl">Настройки за за мейл-покана</h1>
  <p class="font-italic">Моля, въведете текст за мейла.</p>
  <bop-rich-editor [model]="richTextModel"></bop-rich-editor>
  <p-button (onClick)="saveMailTemplate()" icon="pi pi-check" label="Запази мейл съобщение"></p-button>
  <p class="font-italic">Моля, прикачете файловете от дадените бутони. Всеки файл ще бъде качен веднага след прикачането
    му.</p>
  <div class="w-full text-left flex flex-column gap-2">
    <p-fileUpload mode="basic" chooseLabel="Причини за БОП и принципи" class="my-2" accept=".docx,.doc,.pdf" maxFileSize="1000000"
                  (onSelect)="onReasonsAndPrinciplesUpload($event)"></p-fileUpload>
    <p-fileUpload mode="basic" chooseLabel="Общи условия за членство в БОП" name="demo[]" class="my-2"
                  url="https://www.primefaces.org/cdn/api/upload.php" accept=".docx,.doc,.pdf" maxFileSize="1000000"
                  (onSelect)="onMembershipTermsUpload($event)"></p-fileUpload>
    <p-fileUpload mode="basic" chooseLabel="Процедура за членство в БОП" name="demo[]" class="my-2"
                  url="https://www.primefaces.org/cdn/api/upload.php" accept=".docx,.doc,.pdf" maxFileSize="1000000"
                  (onSelect)="onMembershipProcedureUpload($event)"></p-fileUpload>
    <p-fileUpload mode="basic" chooseLabel="Допълнителен файл 1" name="demo[]" class="my-2"
                  url="https://www.primefaces.org/cdn/api/upload.php" accept=".docx,.doc,.pdf" maxFileSize="1000000"
                  (onSelect)="onFirstAdditionalFileUpload($event)"></p-fileUpload>
    <p-fileUpload mode="basic" chooseLabel="Допълнителен файл 2" name="demo[]" class="my-2"
                  url="https://www.primefaces.org/cdn/api/upload.php" accept=".docx,.doc,.pdf" maxFileSize="1000000"
                  (onSelect)="onSecondAdditionalFileUpload($event)"></p-fileUpload>
  </div>
</bop-form-wrapper>
