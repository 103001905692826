import {Component, Input} from '@angular/core';
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";

@Component({
  selector: 'bop-donations-create-number-control',
  standalone: true,
  imports: [
    InputGroupModule,
    InputGroupAddonModule
  ],
  templateUrl: './donations-create-number-control.component.html',
  styleUrl: './donations-create-number-control.component.scss'
})
export class DonationsCreateNumberControlComponent {
  @Input() public label: string = '';
  @Input() public icon: string = '';
}
