import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {InputMaskModule} from "primeng/inputmask";
import {Store} from "@ngrx/store";
import {login} from "./store/authentication.actions";
import {PhoneNumberUtil} from 'google-libphonenumber';
import {normalizePhoneControl, PhoneNumberValidator} from "../../shared/validators/phone-number.validator";
import {
  DynamicBgImageWrapperComponent
} from "../../shared/components/dynamic-bg-image-wrapper/dynamic-bg-image-wrapper.component";
import {AdminService} from "../../private/admin/admin.service";
import {CooperatorsInfo} from "../../private/admin/cooperators-info";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'bop-authentication',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    ButtonModule,
    InputTextModule,
    InputMaskModule,
    DynamicBgImageWrapperComponent
  ],
  templateUrl: './authentication.component.html',
  styleUrl: './authentication.component.scss'
})
export class AuthenticationComponent implements OnInit {
  cooperatorsInfo: CooperatorsInfo = { phoneNumber: '', email: '' };
  readonly normalizePhoneControl = normalizePhoneControl;

  public loginForm!: FormGroup;

  constructor(private fb: FormBuilder,
              private store: Store,
              private router: Router,
              private adminService: AdminService) {
  }

  public ngOnInit() {
    this.loadCooperatorsInfo();

    let loginCredential = localStorage.getItem('loginCredential') || '+' + phoneNumberUtil.getCountryCodeForRegion('BG');
    this.loginForm = this.fb.group({
      loginCredential: [loginCredential, PhoneNumberValidator('BG')],
      password: ['', Validators.required]
    });

    this.loginForm.get('password')?.valueChanges.subscribe(() => this.clearPasswordSpaces());
  }

  public login() {
    normalizePhoneControl(this.loginForm.get('loginCredential')!);
    this.store.dispatch(login({request: this.loginForm.value}));
  }

  public call() {
    if (localStorage.getItem('isMobile')) {
      window.location.href = 'tel:' + this.cooperatorsInfo.phoneNumber;
      return;
    }

    window.location.href = 'mailto:' + this.cooperatorsInfo.email;
  }

  private clearPasswordSpaces() {
    setTimeout(() => this.loginForm.get('password')?.setValue(this.loginForm.get('password')?.value.trim()));
  }

  private loadCooperatorsInfo(): void {
    this.adminService.getCooperatorsInfo().subscribe({
      next: data => {
        this.cooperatorsInfo.phoneNumber = data.phoneNumber;
        this.cooperatorsInfo.email = data.email;
      },
      error: error => {
        console.error('Error loading cooperator info', error);
      }
    });
  }
}
