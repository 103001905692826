<div tabindex="0" class="flex flex-column align-items-center pt-10 pb-5">
  <div class="w-72 pt-5 pb-5">
    <p-checkbox [(ngModel)]="isChecked" [binary]="true" [disabled]="true"></p-checkbox>
    С регистрацията си в www.bopbg.org  заявявам желанието да бъда приет/а за кооперативен член в Народна кооперация БЪЛГАРСКИ ОБЩ ПАЗАР (или накратко НК БОП), за да разменям директно чрез информационната подкрепа на този вътрешно-кооперативен сайт, предлаганите продукти на другите кооперирани в БОП български производители, транспортьори и клиенти, включително всички обявени стоки, услуги и технологии според техните параметри и условия.

    <br>

    <p-checkbox [(ngModel)]="isChecked" [binary]="true" [disabled]="true"></p-checkbox>
    Заявявам съгласието си като ДАРител с доверие да отговарям за етиката на поканените от мен Довереници, като ги подпомагам да спазват конструктивно принципите на НК БОП, както и готовността си да прилагам правото да отстранявам Доверениците, които са допуснали 3 /три/ нарушения, установени от 2 /две/ независими комисии, в процедурите на една от които имам право да участвам пряко, дистанционно или през имейл по мой избор.
    <br>

    <p-checkbox [(ngModel)]="isChecked" [binary]="true" [disabled]="true"></p-checkbox>
    Желая на посочен от мен имейл да получавам известия за възникване на БОП доставки около моя район или заявен
    интерес, както и

    <p-checkbox [(ngModel)]="isChecked" [binary]="true" [disabled]="true"></p-checkbox>
    да получавам справки за заявените от мен услуги и доставки.

    <br>
    <p-checkbox [(ngModel)]="isChecked" [binary]="true" [disabled]="true"></p-checkbox>
    Заявявам съгласието си БОП да обработва личните ми данни само за заявените от мен услуги през сайта на БЪЛГАРСКИ
    ОБЩ ПАЗАР, кратко изписван в следващият текст като БОП, като декларирам съгласие партньорите на БОП и БОП, да събира
    и обработва доброволно предоставените от мен адрес на електронна поща, телефонен номер и лични данни свързани с
    регистрацията на Народна кооперация Български общ Пазар БОП и при регистрация в този уебсайт, за да предоставя целево
    поисканата от мен информация за договаряне на предлаганите от БОП стоки, услуги и технологии, включително, но не само
    предоставяне на електронни услуги, водене на кореспонденция със служители на Кооперацията за нужди, възникнали по
    изпълнение на моите запитвания, заявки и съответните пред-договорни и/или договорни взаимоотношения.

    <p>
      Неотменимо декларирам, че използвайки електронните услуги, предлагани чрез уебсайта на „БЪЛГАРСКИ ОБЩ ПАЗАР“, няма
      да разпространявам лични данни на трети лица, включително чрез текстовото изложение на поставяни от мен въпроси или
      чрез изпращане на прикачени файлове към тях минимум 3 (три) години след прекъсване на членството ми в БОП, освен в
      случаите, предвидени в Закон за защита на личните данни, тъй като съм запознат съм с правата ми, уредени с този закон и
      начините, по които бих могъл да ги упражня, съгласно Информация за поверителност и защита на личните данни на клиентите
      на „БЪЛГАРСКИ ОБЩ ПАЗАР“.
    </p>

    <p class="mt-5">
      ===
    </p>
    <p>
      Информация за поверителност и защита на личните данни на клиентите на „БЪЛГАРСКИ ОБЩ ПАЗАР“.
    </p>

    <p class="mt-5">
      Управлението на личните данни, тяхното събиране, обработване и защита, е регламентирано от Европейския съюз с
      Регламент 2016/679 относно защитата на физическите лица във връзка с обработването на данните. Той има директно
      приложение за всички държави-членки, включително и България, считано от 25.05.2018 г.
    </p>

    <p class="mt-5">
      „БЪЛГАРСКИ ОБЩ ПАЗАР“ (БОП) обработва личните данни при спазване на високи стандарти за осигуряване на
      поверителността, сигурността и защитата им. БОП е въвел и прилага необходимите технически и организационни мерки, за да
      не допуска нерегламентиран достъп, загуба или неоторизирано ползване на лични данни, в пълно съответствие с изискванията
      на българското и европейското законодателство.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        УВАЖАЕМИ КООПЕРАТОРИ,
      </strong>
    </p>

    <p>
      Опазването на Вашите права, свободи и доверие е изключително важно за БОП.
      В случай, че желаете да упражните своите права в качеството Ви на субект на лични данни, за Ваше удобство сме
      разработили заявление за упражняване на права по „Закон за защита на личните данни“.
      Може да подадете своето заявление в центровете за обслужване на клиенти на „БОП “, или като изпратите
      попълненото заявление на и-мейл адрес: gdpr_dpo&#64;bopbg.org
      За да обработваме Вашите заявленията подадени по електронен път е необходимо Вие да ги подадете подписани с
      квалифициран електронен подпис (КЕП), удостоверяващ Вашата самоличност на подателя съгласно разпоредбите
      на чл.37б., ал.2 от ЗЗЛД:
      <i>
        Заявление може да се подаде и по електронен път при условията на Закона за
        електронния документ и електронните удостоверителни услуги, Закона за електронното управление и Закона за
        електронната идентификация.
      </i>
    </p>

    <p>
      Входящ номер ще Ви бъде изпратен на посочения в заявлението Ви и-мейл адрес.
    </p>

    <p class="mt-5">
      Румен Спасов,
    </p>
    <p>
      Длъжностно лице за защита на данните на „БЪЛГАРСКИ ОБЩ ПАЗАР“
    </p>
    <p>
      имейл: gdpr_dpo&#64;bopbg.org
    </p>
    <p>
      телефон: 02/897 76 28
    </p>
    <p>
      <strong>
        Информация за Кооперацията
      </strong>
    </p>
    <p>
      Народна кооперация „БЪЛГАРСКИ ОБЩ ПАЗАР“ („НК БОП” и/или „Кооперацията“ и/или „Администраторът“), е регистрирана в Търговския регистър към Агенцията по вписванията с ЕИК 207689817, със седалище и адрес на управление: гр. София 1000, бул. „Витоша“, No18, ет.4;
    </p>
    <p>
      <strong>
        Информация за Длъжностно лице по защита на данни
      </strong>
    </p>
    <p>
      Име: Румен Иванов Спасов
    </p>
    <p>
      Адрeс: Бул. „Витоша“ No18, ет.4
    </p>
    <p>
      Имейл: gdpr_dpo&#64;bopbg.org
    </p>
    <p>
      Телефон: 02/897 76 28
    </p>
    <p>
      БОП обработва Вашите лични данни при спазване на високи стандарти за осигуряване поверителността, сигурността и защитата
      им. Кооперацията е въвела и прилага необходимите технически и организационни мерки, за да не допуска нерегламентиран
      достъп, загуба или неоторизирано ползване на Вашите данни. За нас е особено важно да осигурим събирането и обработването
      на Вашите лични данни да става в пълно съответствие с изискванията на българското и европейското законодателства.
    </p>

    <p>
      <strong>1. Какви категории лични данни събираме:</strong>
    </p>
    <p>
      • Три имена;
    </p>
    <p>
      • Посочените от Вас адреси за вдигане, обработка и доставка на доставки организирани през БОП СОФТ,
    </p>
    <p>
      • Единен граждански номер, който се изисква за вписване в Книгата на кооператорите от Закона за кооперациите,
    </p>
    <p>
      • Данни от профила Ви за достъп до уебсайта www.bopbg.org : Телефон, парола и адрес на електронна поща;
    </p>
    <p>
      • Клиентски номер;
    </p>
    <p>
      • Данни за банкова сметка, по която желаете да се превеждат дължимите Ви финансови средства: Незадължително
    </p>

    <p class="mt-5 mb-5">
      <strong>2. За какви цели обработваме Вашите лични данни и какво е основанието за предоставянето им</strong>
    </p>

    <p class="mb-5">
      <strong>
        2.1. Ние обработваме личните Ви данни за сключване и изпълнение на договор или при
        преддоговорни отношения– чл.6, т.1, буква „б“ от РЕГЛАМЕНТ (ЕС) 2016/679 (Общ регламент относно защитата
        на данни)
      </strong>
    </p>

    <p>
      Кооперацията обработва личните Ви данни, посочени по-горе, самостоятелно или в комбинация помежду им:
    </p>
    <p class="mb-3">
      a) За да Ви предоставяме услуги по организация на доставки на стоки, услуги и технологии по неформални договори при Общи
      условия, които Общи условия са одобрени от Учредителното събрание на Кооперацията;
    </p>
    <p class="mb-3">
      b) За издаване на изходни данни, а също и за сключване на предварителни покани и окончателна регистрация за
      присъединяване към логистичната мрежа на БОП членовете.
    </p>
    <p class="mb-3">
      c) За обработка на личните Ви данни от Кооперацията при изпълнение логистични услуги, предоставяни от Кооперацията;
    </p>
    <p class="mb-3">
      d) За изпълнение на пред-договорните и договорните си задължения, а също така и за защита на правата ни, произтичащи
      както от Общите условия, така и от други договори, които сключваме с Вас.
    </p>
    <p>
      Предоставените от Вас лични данни за извършване на горепосочените услуги няма да послужат за автоматизирано вземане на
      решения, включително профилиране.
    </p>
    <p>
      Молим да имате предвид, че непредоставянето на Вашите лични данни ще доведе до невъзможност Кооперацията да създаде,
      измени или закрие партида за ползване на БОП услуги за Вас като потребител на тези услуги, да издаде изходни данни, сключи
      предварителен и/или окончателен договор с Вас за присъединяване към БОП мрежата, или да Ви предостави технически услуги
      или други услуги, които сте заявили, свързани с комплексна услуга за доставка на индивидуални заявки.
      Обработката се извършва с цел:
    </p>

    <p class="mt-5">
      • установяване на самоличността на съкооператора при запитване, възлагане и получаване на БОП услуги;
    </p>
    <p>
      • установяване на самоличността на съкооператора при подаване на входящи данни;
    </p>
    <p>
      • установяване на самоличността на съкооператора при издаване на изходни данни;
    </p>
    <p>
      • установяване на самоличността на съкооператора при сключване на договор/и за доставки;
    </p>
    <p>
      • управление и изпълнение на Вашите заявки за услуги, предоставяни от Кооперацията;
    </p>
    <p>
      • изпълнение на договори за присъединяване към БОП мрежата;
    </p>
    <p>
      • предоставяне на необходимото Ви цялостно обслужване, с оглед предоставянето на качествени услуги;
    </p>
    <p>
      • за осигуряване на всякакво техническо съдействие и поддръжка на предоставяните от нас услуги;
    </p>
    <p>
      • за да установим и/или предотвратим действия в противоречия с Общите условия за предоставяне на БОП услуги и/или
      действащата нормативна уредба;
    </p>
    <p>
      • за да отговорим на подадени от Вас молби, жалби, предложения;
    </p>

    <p class="mb-2 mt-5">
      <strong>
        2.2. Кооперацията обработва личните Ви данни за изпълнение на легитимни интереси - чл.6, т.1, буква „е“ от
        РЕГЛАМЕНТ (ЕС) 2016/679 (Общ регламент относно защитата на данни)
      </strong>
    </p>
    <p>
      Ние обработваме личните Ви данни с оглед на нашия легитимен интерес в следните случаи:
    </p>
    <p>
      • предприемане на законосъобразни действия, за да събираме дължимите суми за предоставените Ви БОП услуги;
    </p>
    <p>
      • идентификация на субектите на данни при подаване на входящи данни към Кооперацията от лица различни от тези с
      които сме в договорни взаимоотношения;
    </p>
    <p>
      • използваме видеонаблюдение на територията и около превозните средства на Кооперацията, за да опазваме имуществото и
      да осигуряваме безопасността на нашите производители, домакини, транспортьори, клиенти, посетители и служители.
    </p>

    <p class="mb-2 mt-5">
      <strong>
        2.3. Кооперацията обработва личните Ви данни и за изпълнение на нормативни задължения или за легитимни
        интереси на трета страна) – чл.6, т.1, букви „в“ и „е“ от РЕГЛАМЕНТ (ЕС) 2016/679 (Общ регламент относно
        защитата на данни)
      </strong>
    </p>
    <p>
      Ние обработваме личните Ви данни, включително и данни за изготвяне на сметките Ви за предоставени БОП услуги, за да
      спазим нормативно установени задължения. Такива задължения, например са:
    </p>
    <p>
      • задължения за предоставяне на информация на държавните органи при осъществяване на законните им правомощия;
    </p>
    <p>
      • задължения за предоставяне на информация на Комисията за защита на потребителите при осъществяване на правомощията й;
    </p>
    <p>
      • задължения за предоставяне на информация на Комисията за защита на личните данни при осъществяване на правомощията й;
    </p>
    <p>
      • задължения, предвидени в Закона за счетоводството и Данъчно-осигурителния процесуален кодекс, във връзка с воденето на
      правилно и законосъобразно счетоводство и предоставянето на информация на данъчните органи, съобразно изискванията на
      Данъчно-осигурителния процесуален кодекс;
    </p>
    <p>
      • задължения, предвидени в Закона за независимия финансов одит при извършване на одит на Кооперацията;
    </p>
    <p>
      • предоставяне на информация на съда и трети лица, в рамките на производство пред съд, по дела, производства и преписки,
      по които Кооперацията не е страна, съобразно изискванията на приложимите към съответното производство процесуални норми
      в Гражданския процесуален кодекс, Наказателно-процесуалния кодекс и Закона за съдебната власт.
    </p>

    <p class="mb-2 mt-5">
      <strong>
        2.4. Кооперацията обработва Ваши данни и въз основа на Вашето съгласие – чл.6, т.1, буква „а“ от РЕГЛАМЕНТ
        (ЕС) 2016/679 (Общ регламент относно защитата на данни)
      </strong>
    </p>
    <p>
      За да Ви уведомяваме за събития, свързани с предоставяните от нас услуги, нови възможности или услуги и за изпращане на
      различни известия и/или уведомления Кооперацията използва предоставен с Ваше съгласие адрес на електронна поща. Той
      може да бъде използван за:
    </p>
    <p>
      • изпращане на съпътстващи документи за ползваните БОП услуги, в случай че сте заявили получаването на такива;
    </p>
    <p>
      • получаване на информация за планирани и аварийни спирания на доставките от определн производител, в случай че сте се абонирали;
    </p>
    <p>
      • кореспонденция с Вас през уебсайта, в случай че сте ни задали въпрос;
    </p>
    <p>
      • получаване на друга информация за извършени услуги, доставки и информации за качество.
    </p>
    <p class="mt-5 mb-5">
      Кооперацията има корпоративна Фейсбук страница, на която публикува интересни и полезни за Вас информация и съвети, а
      също така организира игри и конкурси. Във връзка с по-добрата функционалност на тези страници може да се изисква да
      използваме т.нар. „бисквитки“ на социалните мрежи, което става само с Ваше съгласие и за което Кооперацията има отделна
      Политика относно „бисквитките“.
    </p>

    <p class="mb-5">
      <strong>
        3. Категориите на получатели на лични данни извън Кооперацията
      </strong>
    </p>
    <p>
      Кооперацията не предоставя Ваши лични данни на трети
      лица, преди да бъдем сигурни, че необходимите
      технически и организационни мерки за защита на тези
      данни за защита са налице и са на необходимото ниво,
      така че да гарантират сигурността на данните Ви.
      Предоставените от Вас лични данни няма да се предават
      при никакви обстоятелства в трети страни извън
      Европейския съюз.
    </p>
    <p class="bold">
      Във връзка с посочените по-горе основания за
      обработка, Кооперацията предоставя лични данни
      на следните категории получатели:
    </p>
    <p>
      • Държавни и общински институции;
    </p>
    <p>
      • Управители на етажна собственост;
    </p>
    <p>
      • Наши контрагенти, които извършват отпечатване и доставка на фактури;
    </p>
    <p>
      • Наши контрагенти, които извършват услуги по събиране на вземания;
    </p>
    <p>
      • Наши контрагенти, които извършват услуги по отчитане на водомери;
    </p>
    <p>
      • ИТ доставчици на услуги за създаване и поддържане на системите;
    </p>
    <p>
      • Органи на съдебната власт и/или административни органи и/или органи на съдебното изпълнение;
    </p>
    <p>
      • Дружество доставчик на топлофикационни услуги;
    </p>
    <p>
      • Адвокати;
    </p>
    <p>
      • Одитори;
    </p>
    <p>
      • Лицензирани пощенски оператори и лица, предоставящи куриерски услуги;
    </p>
    <p>
      • Банки за обслужване на плащанията, извършени от Вас;
    </p>
    <p>
      • Застрахователи.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        4. Срок за съхранение на данните
      </strong>
    </p>
    <p class="mb-5">
      a) Кооперацията осъществява дейността си въз основа на
      Договор за концесия, сключен със Столична община.
      Съгласно този договор, при изтичането му ние следва да
      предадем получените преди и създадените през времето
      на концесията бази данни и информация. Ето защо, ние
      ще съхраняваме Вашите данни до изтичане на срока на
      Договора за концесия – 06.10.2025г., след което ще ги
      предадем на новия БОП оператор, или на Концедента
      Столична община.
    </p>
    <p class="mb-5">
      b) Записи на телефонни разговори се съхраняват до 6
      месеца от създаване на записа.
    </p>
    <p class="mb-5">
      c) Видеозапис, извършван в Центровете за обслужване
      на клиенти на Кооперацията - до 30 дни от създаване на
      записа.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5. Какви са Вашите права и задължения, какъв е редът за упражняването им
      </strong>
    </p>
    <p class="mb-5">
      <strong>
        5.1. Право на информация и право на достъп:
      </strong>
    </p>
    <p>
      Вие имате право да поискате от нас:
    </p>
    <p>
      • информация за това дали отнасящи се до вас данни се обработват,
    </p>
    <p>
      • информация за целите на това обработване, за категориите данни и за получателите или категориите
      получатели, на които данните се разкриват;
    </p>
    <p>
      • предвидения срок, за който ще се съхраняват личните Ви данни;
    </p>
    <p>
      • информация за правото Ви в предвидените от закона случаи да изискате коригиране или изтриване на лични
      данни или ограничаване на обработването на лични данни, свързани с Вас или да направите възражение
      срещу такова обработване;
    </p>
    <p>
      • информация за правото Ви на жалба до надзорен орган;
    </p>
    <p>
      • информация дали Вашите лични данни са обект на автоматизирано обработване.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.2. Право да оттеглите съгласието си:
      </strong>
    </p>
    <p>
      Вие имате право в случая, когато Ваши данни се обработват въз основа на Вашето съгласие по т. 2.3. от
      настоящия документ , да оттеглите съгласието си по всяко време, като това оттегляне не засяга
      законосъобразността на обработването въз основа на съгласието Ви, преди то да бъде оттеглено
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.3. Право на коригиране и право на изтриване:
      </strong>
    </p>
    <p class="mb-5">
      a) Вие имате право, в случай че оттеглите своето
      съгласие по предходната т. 5.2 от настоящия документ,
      или в случай че Вашите данни са били обработвани
      незаконосъобразно или повече не са необходими за
      целите, за които са били събирани и обработвани, да
      поискате Вашите данни да бъдат заличени без ненужно
      забавяне.
    </p>

    <p class="mb-5">
      b) Вие имате право, в случай че данните, които
      обработваме, са непълни и/или грешни, по всяко време
      да поискате да заличим или коригираме Ваши лични
      данни, обработването на които не отговаря на
      изискванията на закона.
    </p>

    <p class="mb-5">
      В горните случаи, Вие имате право да поискате да
      уведомим третите лица, на които са били разкрити
      личните Ви данни, за всяко заличаване или коригиране, с
      изключение на случаите, когато това е невъзможно или е
      свързано с прекомерни усилия.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.4. Право на възражение:
      </strong>
    </p>
    <p>
      При наличие на законово основание за това, Вие имате право по всяко време да възразите срещу обработването
      на личните Ви данни. В случай, че възражението Ви е основателно, Вашите личните данни няма повече да бъдат обработвани.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.5. Право на ограничаване на обработването:
      </strong>
    </p>
    <p>
      Вие можете да поискате ограничаване на обработването на Вашите лични данни, в случай че:
    </p>
    <p>
      • оспорвате верността на данните, за срок, в който ни позволява да проверим точността им;
    </p>
    <p>
      • обработването на данните е неправомерно, но Вие не желаете данните Ви да бъдат изтрити, а вместо това
      изисквате ограничаване на използването им;
    </p>
    <p>
      • повече нямаме нужда от тези данни (за целта, за която са събрани), но Вие ги изисквате за установяването,
      упражняването или защитата на правни претенции;
    </p>
    <p>
      • сте подали възражение за обработването да данните, в очакване на проверка дали законните ни основания
      имат преимущество пред Вашите интереси.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.6. Право на преносимост на данни:
      </strong>
    </p>
    <p>
      Вие имате право да поискате от нас да предоставим личните данни, които сте ни предоставили в структуриран,
      широко използван и пригоден за машинно четене формат.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.7. Право на жалба:
      </strong>
    </p>
    <p>
      Ние се стремим да спазваме стриктно действащото законодателство при предоставянето на нашите услуги. В
      случай, че имате причина да считате, че е налице някакво нарушение от наша страна, Ви молим да се обърнете
      към Длъжностното лице по защита на данните, чиито контакти са посочени в началото. Независимо от това, при
      всяко положение Вие имате право да подадете жалба пред Комисията за защита на личните данни (www.kzld.bg),
      пред регулаторен орган в рамките на Европейския съюз, или да се обърнете към съда.
      В случай, че подадете жалба или искане до Кооперацията и/или до Длъжностното лице по защита на данните,
      ние ще разгледаме и ще се произнесем с мотивирано решение в рамките на 14 дни от подаване на Вашата жалба
      или искане. В случай, че събирането на данни по Вашето искане налага ангажирането на по-сериозни ресурси,
      този срок може да бъде удължен до 30 дни, за което ние ще Ви информираме своевременно.
    </p>

    <p class="mt-5 mb-5">
      <strong>
        5.8. Вашите задължения:
      </strong>
    </p>
    <p>
      • Използвайки електронните услуги, предлагани чрез уебсайта на НК БОП, за извършване на Ваши действия като доброжелателен съкооператор, не трябва да разпространявате лични данни на трети лица, вкл. чрез текстовото изложение на поставяните от Вас въпроси или чрез изпращане на прикачени файлове към тях, освен в случаите, предвидени в Закон за защита на личните данни. В случай на констатирани нарушения от Ваша страна сме длъжни да уведомим компетентните органи.
    </p>

  </div>
  <p-button icon="pi pi-check" label="Приемам" (click)="closeModal()"></p-button>
</div>
