import {Component, OnInit} from '@angular/core';
import {SystemMessage} from "../../../shared/models/system-message";
import {SystemService} from "../../../shared/services/system.service";
import {EditorModule} from "primeng/editor";
import {FormsModule} from "@angular/forms";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {ButtonModule} from "primeng/button";
import {MessageService} from "primeng/api";
import {RichEditorComponent} from "../../../shared/components/rich-editor/rich-editor.component";
import {RichEditorModel} from "../../../shared/components/rich-editor/rich-editor-model";

@Component({
  selector: 'bop-admin-home-page',
  standalone: true,
  imports: [
    EditorModule,
    FormsModule,
    FormWrapperComponent,
    ButtonModule,
    RichEditorComponent
  ],
  templateUrl: './admin-home-page-system-message.component.html',
  styleUrl: './admin-home-page-system-message.component.scss'
})
export class AdminHomePageSystemMessageComponent implements OnInit {
  public richTextModel = new RichEditorModel();
  constructor(private systemService: SystemService, private messageService: MessageService) {
  }

  ngOnInit() {
    this.systemService.getSystemMessage()
      .subscribe({
        next: message => this.richTextModel.text = message.message
      })
  }

  saveSystemMessage() {
    this.systemService.setSystemMessage(new SystemMessage(this.richTextModel.text))
      .subscribe({
        next: () => this.messageService.add({
          severity: 'success',
          summary: 'Успешно добавяне на системно съобщение!',
          detail: 'Можете да го видите в началната страница.'
        }),
        error: () => this.messageService.add({
          severity: 'error',
          summary: 'Възникна грешка!',
          detail: 'За съжаление възникна грешка. Моля, опитайте отново.'
        })
      })
  }
}
