export enum DONATIONS_STATUS {
  'одобрена' = 1,
  'отказана',
  'изчакваща',
  'платена'
}

export const donationStatusConfig: {[id: number]: DonationStatusConfigField} = {
  1: {name: 'Одобрено', severity: 'success'},
  2: {name: 'Отказано', severity: 'danger'},
  3: {name: 'Изчакващо', severity: 'primary'},
  4: {name: 'Платено', severity: 'info'},
}

export interface DonationStatusConfigField {
  name: string;
  severity: string;
}
