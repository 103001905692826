<bop-dynamic-bg-image-wrapper>
  <form class="h-full flex align-items-center justify-content-center" [formGroup]="loginForm"
        (submit)="login()">

    <div
      class="w-full md:w-5 m-auto flex flex-column align-items-center custom-layout-background justify-content-center gap-1 border-round-md">
      <h1 class="text-xl m-0 text-yellow-400">Добре дошли!</h1>
      <img class="w-6" src="../../../assets/img/logo/logo-colorful.png">

      <p class="font-italic font-bold text-primary-500 mt-0 text-center">БОП е директна магистрала до пълноценните български
        продукти (храни, стоки, услуги, технологии). Доставя без посредници създаваното от златните български ръце,
        умове и фамилии в ниви, чифлици и фирми, за да възкресим България. БОП е клуб на родолюбци, в който се влиза
        след покана от наш член</p>

      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-phone"></i>
        </p-inputGroupAddon>
        <input pInputText formControlName="loginCredential" placeholder="Телефонен номер"
               (focusout)="normalizePhoneControl(loginForm.get('loginCredential')!)"/>

      </p-inputGroup>

      @if (loginForm.controls['loginCredential'].invalid && loginForm.controls['loginCredential'].touched) {
        <small class="text-red-400 font-bold">Моля, въведете валиден телефонен номер</small>
      }

      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-lock"></i>
        </p-inputGroupAddon>
        <p-password placeholder="Парола"
                    formControlName="password"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [toggleMask]="true"
                    [feedback]="false"></p-password>
      </p-inputGroup>

      @if (loginForm.controls['password'].invalid && loginForm.controls['password'].touched) {
        <small class="text-red-600">Моля, въведете вашата парола</small>
      }

      <p-button [disabled]="loginForm.invalid" (click)="login()" type="submit" label="Вход" icon="pi pi-user"
                styleClass="w-15rem mx-auto"></p-button>

      <p-button (click)="call()" label="Връзка с кооператор" icon="pi pi-phone" severity="warning"
                styleClass="w-15rem mx-auto"></p-button>
    </div>
  </form>
</bop-dynamic-bg-image-wrapper>
