import {Component, Input} from '@angular/core';
import {DonationStatusConfigField} from "../donation-status-label/donation-status.config";
import {TrusteeStatusConfig, trusteeStatusConfigs} from "./trustee-status.config";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'bop-trustee-status',
  standalone: true,
  imports: [
    TagModule
  ],
  templateUrl: './trustee-status.component.html',
  styleUrl: './trustee-status.component.scss'
})
export class TrusteeStatusComponent {
  @Input() public id!: number;

  public trusteeStatusConfig: {[id: number]: TrusteeStatusConfig} = trusteeStatusConfigs;
}
