import {Component, Input} from '@angular/core';
import {ChipModule} from "primeng/chip";
import {availableRoles} from "../../../private/profile/profile.config";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'bop-roles-preview',
  standalone: true,
  imports: [
    ChipModule,
    TooltipModule
  ],
  templateUrl: './roles-preview.component.html',
  styleUrl: './roles-preview.component.scss'
})
export class RolesPreviewComponent {
  @Input() roleIds: number[] = [];

  getRoleAlias(roleId: any) {
    return availableRoles.find(r => r.id === roleId)?.alias || '';
  }

  getRoleName(roleId: any) {
    return availableRoles.find(r => r.id === roleId)?.name || '';
  }


}
