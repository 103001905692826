import {PhoneNumberRequest} from "../profile/profile.request";
import {LocationRequest} from "../../shared/models/location/location.request";

export class InviteTrusteeRequest {
  constructor(
    public firstName: string = '',
    public lastName: string = '',
    public phoneNumber: PhoneNumberRequest = new PhoneNumberRequest(),
    public email: string = '',
    public manufacturerBDS: boolean = false,
    public carrier: boolean = false,
    public cargoBop: boolean = false,
    public properCustomer: boolean = false,
    public other: boolean = false,
    public otherInfo: string = '',
    public notifyBySMS: boolean = false,
    public notifyByEmail: boolean = false,
    public locations: LocationRequest[] = []
  ) {

  }
}
