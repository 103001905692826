<bop-form-wrapper>
  <h1 class="text-xl">Добавяне на изображения за началната страница</h1>
  <bop-photo-choice (fileSelectedEmitter)="saveImage($event)" [showCamera]="false"
                    [showPreview]="false"></bop-photo-choice>

  <section class="grid gap-4">
    @for (image of carouselImages.carouselImages; track image) {
      <bop-admin-home-page-carousel-image-preview [url]="image.url"
                                                  (onDelete)="deleteImage(image.id)"></bop-admin-home-page-carousel-image-preview>
    }
  </section>

</bop-form-wrapper>
