import {RoleRequest} from "../../../private/profile/profile.request";

export class LocationRequest {
  constructor(
    public id: number | null = 0,
    public address: string = '',
    public comment: string = '',
    public coordinates: string = '',
    public settlementId: number = 0,
    public locationRoles: RoleRequest[] = [],
    public locationImageId: number | null = null
  ) {

  }
}

