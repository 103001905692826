<div class="w-full flex flex-column md:flex-row justify-content-between gap-3">
  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <p-autoComplete placeholder="Община"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [formControl]="municipalitySearchControl"
                    [suggestions]="(municipalities$ | async)!"
                    #municipality
                    (onSelect)="onMunicipalitySelect($event); municipality.hide(); assignAutocompleteFields(municipality, settlement)"
                    (onFocus)="municipality.show()"
                    (onKeyUp)="municipality.show()"
                    optionLabel="name"
                    appendTo="body">
    </p-autoComplete>
  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <p-autoComplete placeholder="Населено място"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [formControl]="settlementSearchControl"
                    [suggestions]="(settlements$ | async)!"
                    #settlement
                    (onSelect)="onSettlementSelect($event); settlement.hide()"
                    (onFocus)="settlement.show()"
                    (onKeyUp)="settlement.show()"
                    optionLabel="name"
                    appendTo="body">
    </p-autoComplete>
  </p-inputGroup>
</div>
