import {FilterOperation} from "./filter.operation";

export class FilterRequest {
  constructor(
    public field: string,
    public value: any,
    public operation: FilterOperation
  ) {
  }
}
