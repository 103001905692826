export const donationOtherTypes = [
  {id: 1, type: 'Храна'},
  {id: 2, type: 'Напитка'},
  {id: 3, type: 'Стока'},
  {id: 4, type: 'Услуга'},
  {id: 5, type: 'Труд'},
  {id: 6, type: 'Консултация'},
  {id: 7, type: 'Технология'},
  {id: 8, type: 'Апорт'},
  {id: 9, type: 'Ползвано'},
  {id: 10, type: 'Дар с цел'},
  {id: 11, type: 'Вересия'},
  {id: 12, type: 'Здружно'},
  {id: 13, type: '100% Българско'},
  {id: 14, type: '100% Натурално'},
  {id: 15, type: '100% Чисто'}
];
