import {MenuItem} from "primeng/api";
import {StatisticsResponse} from "../../models/statistics.response";
import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";

type LayoutState = {
  headerItems: MenuItem[];
  sidebarItems: MenuItem[];
  profileItems: MenuItem[];
  profileMeta: StatisticsResponse;
  profileName: string;
  sidebarExpanded: boolean;
}

const initialState: LayoutState = {
  headerItems: [],
  sidebarItems: [],
  profileItems: [],
  profileMeta: new StatisticsResponse(),
  profileName: '',
  sidebarExpanded: false
}

export const LayoutStore = signalStore(
  {providedIn: 'root'},
  withState(initialState),
  withMethods(store => ({
    setHeaderItems(items: MenuItem[]): void {
      patchState(store, (state) => ({headerItems: items}))
    },
    setSidebarItems(items: MenuItem[]): void {
      patchState(store, (state) => ({sidebarItems: items}))
    },
    setProfileItems(items: MenuItem[]): void {
      patchState(store, (state) => ({profileItems: items}))
    },
    setProfileMeta(profileMeta: StatisticsResponse): void {
      patchState(store, (state) => ({profileMeta: profileMeta}))
    },
    setProfileName(name: string): void {
      patchState(store, (state) => ({profileName: name}))
    },
    setSidebarExpanded(expanded: boolean): void {
      patchState(store, (state) => ({sidebarExpanded: expanded}))
    }
  }))
)
