export class ThemeType {
  static readonly LIGHT: () => Theme = () => ({
    file: 'theme-light',
    icon: 'pi pi-sun',
    key: 'LIGHT',
    switchTo: ThemeType.DARK
  });
  static readonly DARK: () => Theme = () => ({
    file: 'theme-dark',
    icon: 'pi pi-moon',
    key: 'DARK',
    switchTo: ThemeType.LIGHT
  });
}

export interface Theme {
  file: string;
  icon: string;
  key: string;
  switchTo: () => Theme;
}
