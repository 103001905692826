<div class="flex justify-content-between gap-2">
  @if (showEdit) {
    @if (!editing) {
      <p-button icon="pi pi-pencil" pInitEditableRow pTooltip="Редакция" [rounded]="true"
                severity="help" size="small"></p-button>
    } @else {
      <p-button icon="pi pi-save" pSaveEditableRow pTooltip="Запиши" (onClick)="onEdit.emit();"
                [rounded]="true"
                severity="info" size="small"></p-button>
      <p-button icon="pi pi-exclamation-circle" pCancelEditableRow pTooltip="Откажи"
                [rounded]="true"
                severity="warning" size="small"></p-button>
    }
  }

  @if (showReinvite && !editing) {
    <p-button icon="pi pi-refresh" pTooltip="Повторна покана" (onClick)="onReinvite.emit()" [rounded]="true"
              severity="info" size="small"></p-button>
  }

  @if (showDelete && !editing) {
    <p-button icon="pi pi-times" pTooltip="Изтриване" (onClick)="onDelete.emit()" [rounded]="true"
              severity="danger" size="small"></p-button>
  }

  @if (showChangeTrustee && !editing) {
    <p-button icon="pi pi-user" pTooltip="Даряване към друг довереник" (onClick)="onChangeTrustee.emit()" [rounded]="true"
              severity="warning" size="small"></p-button>
  }
</div>

