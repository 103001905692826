@if (loading) {
  <div class="loading-overlay flex flex-column absolute top-0 left-0 w-full h-full justify-content-center align-items-center z-5">
    <div class="spinner-container">
      <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
}
