import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {PhotoChoiceCameraComponent} from "../photo-choice-camera/photo-choice-camera.component";

@Component({
  selector: 'bop-photo-choice-gallery-preview',
  standalone: true,
    imports: [
        ButtonModule
    ],
  templateUrl: './photo-choice-gallery-preview.component.html',
  styleUrl: './photo-choice-gallery-preview.component.scss'
})
export class PhotoChoiceGalleryPreviewComponent {
  public previewSrc = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef<PhotoChoiceCameraComponent>
  ) {
  }

  ngOnInit() {
    if (this.dialogConfig.data.photo) {
      let reader = new FileReader();
      reader.readAsDataURL(this.dialogConfig.data.photo);

      reader.onload = () => {
        this.previewSrc = reader.result as string;
      };

    }

  }

  saveImage() {
    this.dialogRef.close(true);
  }

  cancelImage() {
    this.dialogRef.close(false);
  }
}
