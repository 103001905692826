import {DistrictResponse} from "./district.response";

export class MunicipalityResponse {
  constructor(
    public id: number = 0,
    public name: string = '',
    public district: DistrictResponse = new DistrictResponse()
  ) {

  }
}
