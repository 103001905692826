import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {MessageRequest} from "../models/message/message.request";

@Injectable({providedIn: 'root'})
export class NotificationService {
  constructor(private http: HttpClient) {
  }

  sendMessage(message: MessageRequest): Observable<any> {
    return this.http.post('api/messages', message);
  }
}
