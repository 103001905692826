<h2>Адреси</h2>

<div class="flex justify-content-end mb-7">
  <p-button icon="pi pi-plus" label="Добавяне на адрес" (click)="addressAdd.emit()"></p-button>
</div>

<p-table [value]="locations">
  <ng-template pTemplate="header">
    <tr>
      <th>Община</th>
      <th>Населено място</th>
      <th>Адрес</th>
      <th>Уточнение към адреса</th>
      <th>За роля</th>
      <th>Действия</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-address>
    <tr [class.text-red-400]="address.isDraft" [pTooltip]="address.isDraft ? 'Промяната е все още скица. Моля запишете промените по профила си от бутона за запазване в края на страницата.' : undefined">
      <td>{{ address.settlement.municipality.name }}</td>
      <td>{{ address.settlement.name }}</td>
      <td>{{ address.address }}</td>
      <td>{{ address.comment }}</td>
      <td><bop-roles-preview [roleIds]="getRolesIds(address.locationRoles)"></bop-roles-preview></td>
      <td class="flex justify-content-center align-items-center"><p-button icon="pi pi-pencil" (onClick)="addressEdit.emit(address)"></p-button></td>
    </tr>
  </ng-template>
</p-table>
