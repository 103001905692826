import {Component} from '@angular/core';
import {DataGridTrait} from "../../../shared/services/grid.service";
import {ProfileResponse, RoleResponse} from "../../profile/profile.response";
import {DonationsService} from "../../donations/donations.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {RolesPreviewComponent} from "../../../shared/components/roles-preview/roles-preview.component";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {AdminService} from "../admin.service";
import {ManageUserActionsComponent} from "../../../shared/components/manage-user-actions/manage-user-actions.component";
import {TrusteeStatusComponent} from "../../../shared/components/trustee-status/trustee-status.component";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {ButtonModule} from "primeng/button";
import {Store} from "@ngrx/store";
import {
  trusteeDeleteError,
  trusteeDeleteSuccess,
  trusteeEditError,
  trusteeEditSuccess,
  trusteeReinviteError,
  trusteeReinviteSuccess
} from "../../donations/store/donations.actions";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {filter, mergeMap, tap} from "rxjs";
import {exportCooperatorsBookError, exportCooperatorsBookSuccess} from "../store/admin.actions";
import {CalendarModule} from "primeng/calendar";
import {UpdateAdminTrusteeRequest} from "../update-admin-trustee.request";
import {
  DonationTrusteesNotifyComponent
} from "../../donations/donations-trustess/donation-trustees-notify/donation-trustees-notify.component";
import {MessageRequest} from "../../../shared/models/message/message.request";
import {DialogService} from "primeng/dynamicdialog";
import {NotificationService} from "../../../shared/services/notification.service";

@Component({
  selector: 'bop-admin-users',
  standalone: true,
  imports: [
    DatePipe,
    RolesPreviewComponent,
    SharedModule,
    TableModule,
    ManageUserActionsComponent,
    TrusteeStatusComponent,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    SpinnerOverlay,
    CalendarModule
  ],
  templateUrl: './admin-users.component.html',
  styleUrl: './admin-users.component.scss'
})
export class AdminUsersComponent {
  grid: DataGridTrait<ProfileResponse> = new DataGridTrait<ProfileResponse>(
    this.adminService.getAllTrustees.bind(this.adminService),
    this.route,
    this.router
  );
  loading = false;
  selectedTrustees: ProfileResponse[] = [];

  constructor(
    private adminService: AdminService,
    private donationsService: DonationsService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  getRolesIds(userRoles: RoleResponse[]): number[] {
    return userRoles.map(r => r.roleId);
  }

  openNotifyTrusteesModal() {
    this.dialogService.open(DonationTrusteesNotifyComponent, {
      header: 'Изпращане на съобщение',
      data: {
        trustees: this.selectedTrustees
      }
    }).onClose.pipe(
      filter(x => x),
      tap(() => this.loading = true),
      mergeMap((msg: MessageRequest) => this.notificationService.sendMessage(msg)),
      tap(() => this.loading = false)
    ).subscribe(() => this.messageService.add({
      severity: 'success',
      summary: 'Уведомяване на довереници',
      detail: 'Доверениците бяха успешно уведомени.'
    }))
  }

  onEdit(trustee: ProfileResponse) {
    this.grid._loading = true;
    this.adminService.updateTrustee(trustee.id, new UpdateAdminTrusteeRequest(
      trustee.rating,
      trustee.memberUntil
    )).pipe(
      tap(() => this.grid._loading = false),
    ).subscribe({
      next: () => this.store.dispatch(trusteeEditSuccess()),
      error: () => this.store.dispatch(trusteeEditError())
    })
  }

  onReinvite(id: number) {
    this.donationsService.reinviteTrustee(id)
      .subscribe(response => {
        this.grid.refetch();
        this.store.dispatch(trusteeReinviteSuccess({response}))
      }, error => this.store.dispatch(trusteeReinviteError({error})))
  }

  onDelete(id: number) {
    this.donationsService.deleteTrustee(id)
      .subscribe(response => {
        this.grid.refetch();
        this.store.dispatch(trusteeDeleteSuccess({response}))
      }, error => this.store.dispatch(trusteeDeleteError({error})))
  }

  saveAs() {
    this.loading = true;
    const content = this.grid.pagedData.content;
    this.grid.pagedData.content = [];
    this.adminService.saveCooperators().pipe(
      tap(() => this.grid.pagedData.content = content),
      tap(() => this.grid._loading = false),
      tap(() => this.loading = false)
    ).subscribe({
      next: () => this.store.dispatch(exportCooperatorsBookSuccess()),
      error: () => this.store.dispatch(exportCooperatorsBookError())
    });
  }
}
